import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { VehiculoConductor } from "../../models/vehiculos_conductores.model";
import { VehiculosConductoresService } from "../../services/vehiculos-conductores.service";
import { MatDialog } from "@angular/material";
import { DlgConductoresComponent } from "../../busquedas/dlg-conductores.component";
import { PropietariosService } from "src/app/services/propietarios.service";
import { ConductoresService } from "src/app/services/conductores.service";
import { DlgPropietariosComponent } from "src/app/busquedas/dlg-propietarios.component";
import { DlgVehiculosComponent } from "src/app/busquedas/dlg-vehiculos.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-vehiculos-conductores-new",
  templateUrl: "./vehiculos-conductores-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class VehiculosConductoresNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 380;
  isLoading: boolean = false;
  frm: FormGroup;
  conductores = [];
  data_search_conductores = [];
  data_search_vehiculos = [];
  _model: VehiculoConductor;
  isEdit: boolean = false;
  _condid: number = 0;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceConductores: ConductoresService,
    private _serviceVehiculosConductores: VehiculosConductoresService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.frm = this.fb.group({
      vehiculo_id: [0],
      conductor_id: [0, Validators.required],
      numero: [""],
      _conductor_nombre: ["", Validators.required],
      _numero: ["", Validators.required],
    });

    this._serviceConductores.get_listar("1", "10").subscribe((resp: any) => {
      this.conductores = resp.data;
      this.blockUI.stop();
    });

    if (this.isEdit) {
      const id = this._model.vehiculoconductor_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceVehiculosConductores
      .getbyid(id.toString())
      .subscribe((resp: any) => {
        this._model = resp.data[0];
        console.log("Datos=====> ", resp.data[0]);
        this.frm = this.fb.group({
          vehiculo_id: [this._model.vehiculo_id],
          conductor_id: [resp.data[0].conductor_id, Validators.required],
          _numero: [this._model.numero],
          _conductor_nombre: [resp.data[0].nombres, Validators.required],
        });
        this.blockUI.stop();
      });
  }

  openDialog_Vehiculos(): void {
    const dialogRef = this.dialog.open(DlgVehiculosComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_vehiculos = result;
        this.frm.controls.vehiculo_id.patchValue(result[0].vehiculo_id);
        this.frm.controls._numero.patchValue(result[0].numero);
        dialogSubmitSubscription.unsubscribe();
      });
  }

  openDialog_Conductores(): void {
    const dialogRef = this.dialog.open(DlgConductoresComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
        _vehiculo_id: -1,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_conductores = result;
        this.frm.controls.conductor_id.patchValue(result[0].conductor_id);
        this.frm.controls._conductor_nombre.patchValue(
          result[0].conductor_nombres
        );
        this._condid = result[0].conductor_id;
        dialogSubmitSubscription.unsubscribe();
      });
  }
}
