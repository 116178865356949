import { createReducer, on } from "@ngrx/store";
import { cargarUsers, cargarUsersError, cargarUsersSuccess } from "../actions";
import { User } from "../../models/auth/users.model";

export interface UsersState {
  users: User[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const usersInitialState: UsersState = {
  users: [],
  loaded: false,
  loading: false,
  error: null,
};

const _usersReducer = createReducer(
  usersInitialState,

  on(cargarUsers, (state) => ({ ...state, loading: true })),

  on(cargarUsersSuccess, (state, { users }) => ({
    ...state,
    loading: false,
    loaded: true,
    users: [...users],
  })),

  on(cargarUsersError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function usersReducer(state, action) {
  return _usersReducer(state, action);
}
