import { Component, OnInit } from "@angular/core";
import { GraphService } from "src/app/services/graph.service";
import * as global from "../../config/globals";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styles: [],
})
export class DashboardComponent implements OnInit {
  global = global;
  data_galones = [];
  data_buses = [];
  data_recaudo = [];
  data_recaudo_bus = [];
  data_recaudo_mes = [];
  data_recaudo_bymes = [];

  data_g = [];
  data_recaudog = [];
  data_chart = [];
  data_chart_recaudobus = [];

  mes_nombre = "";
  mes_recaudo = 0;
  mes_ahorro = 0;
  mes_cooperativa = 0;
  mes_planilla = 0;
  mes_acpm = 0;
  mes_pago_conductor = 0;
  year = 0;
  saldo_acumulado = 0;

  constructor(private _serviceGraph: GraphService) {}

  lineChartColor = {
    domain: [
      global.COLOR_BLUE,
      global.COLOR_GREEN,
      global.COLOR_PURPLE,
      global.COLOR_BLACK,
    ],
  };

  ChartGalonesDia = [];
  ChartRecaudosDia = [];
  ChartRecaudosByMes = [];
  pieChartDataBuses = [];
  pieChartRecaudoBuses = [];

  pieChartColor = {
    domain: [global.COLOR_RED, global.COLOR_ORANGE, global.COLOR_BLACK],
  };

  ngOnInit() {
    this._serviceGraph.get_recaudo_bymes().subscribe((resp: any) => {
      this.data_recaudo_bymes = resp.data;
      this.data_recaudo_bymes.forEach((x: any) => {
        this.data_g.push({
          name: x.mes_nombre,
          value: Math.round(x.producido),
        });
      });
      this.ChartRecaudosByMes = [
        {
          name: "Producido",
          series: this.data_g,
        },
      ];
    });

    this._serviceGraph.get_recaudobybus().subscribe((resp: any) => {
      this.data_recaudo_bus = resp.data;
      this.data_recaudo_bus.forEach((x: any) => {
        this.data_chart_recaudobus.push({
          name: x.numero,
          value: Math.round(x.producido),
        });
      });
      this.pieChartRecaudoBuses = this.data_chart_recaudobus;
    });

    this._serviceGraph.get_recaudobybusultimosdias().subscribe((resp: any) => {
      this.data_buses = resp.data;
      this.data_buses.forEach((x: any) => {
        this.data_chart.push({
          name: x.numero,
          value: Math.round(x.producido),
        });
      });
      this.pieChartDataBuses = this.data_chart;
    });

    this._serviceGraph.get_recaudo_bydia().subscribe((resp: any) => {
      this.data_recaudo = resp.data;
      this.data_recaudo.forEach((x: any) => {
        this.data_recaudog.push({
          name: x.fecha_recaudo,
          value: Math.round(x.producido),
        });
      });

      this.ChartRecaudosDia = [
        {
          name: "Recaudo",
          series: this.data_recaudog,
        },
      ];
    });

    this._serviceGraph.get_recaudo_mes_actual().subscribe((resp: any) => {
      console.log(resp.data);
      this.data_recaudo_mes = resp.data;
      this.mes_nombre = this.data_recaudo_mes[0].mes_nombre;
      this.mes_recaudo = this.data_recaudo_mes[0].producido;
      this.mes_ahorro = this.data_recaudo_mes[0].ahorro;
      this.mes_cooperativa = this.data_recaudo_mes[0].cooperativa;
      this.mes_planilla = this.data_recaudo_mes[0].planilla;
      this.mes_acpm = this.data_recaudo_mes[0].acpm;
      this.mes_pago_conductor = this.data_recaudo_mes[0].pago_conductor;
      this.year = this.data_recaudo_mes[0].year;
    });

    this._serviceGraph.get_graph_saldo_recaudo().subscribe((resp: any) => {
      this.saldo_acumulado = resp.data[0].saldo;
    });

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  numberFormatting(value: number) {
    return `${Math.round(value).toLocaleString()}`;
  }

  formatPercent(val) {
    console.log("Valllll => ", val + "%");

    return val + "%";
  }
}
