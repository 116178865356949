import { Component, OnInit } from "@angular/core";
import { ReportesService } from "../../services/reportes.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import Swal from "sweetalert2";
import { name_mes } from "../../config/globals";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-retiros-list",
  templateUrl: "./retiros-list.component.html",
  styles: [],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RetirosListComponent implements OnInit {
  data_rpt_retiros = [];
  data_totales = [];
  form: FormGroup;
  desde: string = "";
  hasta: string = "";
  tipos_rpt = [];
  cargando: boolean = false;
  btndisabled: boolean = false;
  xbus: string = "";
  xmes: string = "";

  constructor(
    private _serviceReportes: ReportesService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      fecha_desde: [new Date(), Validators.required],
      fecha_hasta: [new Date(), Validators.required],
      numero: ["115"],
    });
  }

  ngOnInit() {}

  get fdata() {
    return this.form.controls;
  }

  consultar() {
    this.desde = moment(this.form.controls.fecha_desde.value).format(
      "YYYY-MM-DD 00:00"
    );
    this.hasta = moment(this.form.controls.fecha_hasta.value).format(
      "YYYY-MM-DD 23:59"
    );

    this.xmes =
      name_mes[
        parseInt(moment(this.form.controls.fecha_desde.value).format("MM")) - 1
      ];

    this.data_rpt_retiros = [];
    this.data_totales = [];
    this._serviceReportes.FechaDesde$.emit(this.desde);
    this._serviceReportes.FechaHasta$.emit(this.hasta);

    if (this.form.controls.numero.value === "") {
      Swal.fire("Precaución", "Número de Vehículo no válido...", "warning");
      return false;
    } else {
      this.xbus = this.form.controls.numero.value;
      this._serviceReportes.mes$.emit(this.xmes);
      this._serviceReportes.bus$.emit(this.xbus);
      this._serviceReportes
        .getrecaudosByBus(
          "1",
          "10",
          this.desde,
          this.hasta,
          this.form.controls.numero.value
        )
        .subscribe((resp: any) => {
          this.data_rpt_retiros = resp.data;
          if (resp.data.length === 0) {
            Swal.fire(
              "Atención",
              "No se encontraron datos para esta consulta...",
              "info"
            );
          } else {
            this._serviceReportes
              .get_totalesbyvehiculo(resp.data[0].vehiculo_id)
              .subscribe((resp: any) => {
                this.data_totales = resp.data;
              });
          }
        });
    }
  }

  change_bus(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.form.controls["numero"].setValue(value);
    }
  }

  calcularTotales() {
    let t_retiros = 0;
    let t_cooperativa = 0;
    let t_aportes = 0;
    let t_saldo = 0;

    this.data_rpt_retiros.forEach(async (item) => {
      let parsedDate = moment(item.fecha_recaudo, "YYYY-MM-DD");
      let outputDate = parsedDate.format("D");
      item.fecha_recaudo = parseInt(outputDate);
      item.numero = parseInt(item.numero);
      item.valor_retiro = parseFloat(item.valor_retiro);
      item.cooperativa = parseFloat(item.cooperativa);
      item.aportes = parseFloat(item.aportes);
      item.saldo = parseFloat(item.saldo);

      t_retiros += parseFloat(item.valor_retiro);
      t_cooperativa += parseFloat(item.cooperativa);
      t_aportes += parseFloat(item.aportes);
      t_saldo += parseFloat(item.saldo);
    });

    this.data_rpt_retiros.push({
      fecha_recaudo: "",
      ruta_nombre: "",
      numero: "TOTALES",
      valor_retiro: t_retiros,
      cooperativa: t_cooperativa,
      aportes: t_aportes,
      saldo: t_saldo,
    });
  }
}
