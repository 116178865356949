import { createAction, props } from "@ngrx/store";
import { PlanillaLiquidacion } from "../../../../../models/planillas-liquidacion.model";

export const cargarPlanillaLiquidacion = createAction(
  "[Planilla Liquidacion] Cargar Liquidacion",
  props<{ id: string }>()
);

export const cargarListaLiquidacion = createAction(
  "[Lista Planilla Liquidacion] Cargar Lista Liquidacion"
);

export const cargarPlanillaLiquidacionSuccess = createAction(
  "[Planilla Liquidacion] Cargar Liquidacion Success",
  props<{ planillaliquidacion: PlanillaLiquidacion }>()
);

export const cargarPlanillaLiquidacionError = createAction(
  "[Planilla Liquidacion] Cargar Liquidacion Error",
  props<{ payload: any }>()
);
export const addPlanillaLiquidacion = createAction(
  "[Planilla Liquidacion] Nuevo Planilla Liquidacion",
  props<{ planillaliquidacion: PlanillaLiquidacion }>()
);

export const addPlanillaLiquidacionSuccess = createAction(
  "[Planilla Liquidacion] Nuevo Planilla Liquidacion Success",
  props<{ planillaliquidacion: any }>()
);

export const addPlanillaLiquidacionError = createAction(
  "[Planilla Liquidacion] Nuevo Planilla Liquidacion Error",
  props<{ payload: any }>()
);

export const updPlanillaLiquidacion = createAction(
  "[Planilla Liquidacion] Update Planilla Liquidacion",
  props<{ planillaliquidacion: any }>()
);

export const updPlanillaLiquidacionSuccess = createAction(
  "[Planilla Liquidacion] Update Planilla Liquidacion Success",
  props<{ planillaliquidacion: any }>()
);

export const updPlanillaLiquidacionError = createAction(
  "[Planilla Liquidacion] Update Planilla Liquidacion Error",
  props<{ payload: any }>()
);
