import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { TiposDocumentoService } from "../../services/tipos-documento.service";
import { PropietariosService } from "../../services/propietarios.service";
import { TiposPersonaService } from "../../services/tipos-persona.service";
import { Propietario } from "../../models/propietarios.model";
import { PropietariosValidatorComponent } from "./propietarios-validator.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-propietarios-new",
  templateUrl: "./propietarios-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PropietariosNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 875;
  isLoading: boolean = false;
  frm: FormGroup;
  tipos_documento = [];
  tipos_persona = [];
  _model: Propietario;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceTiposDocumento: TiposDocumentoService,
    private _servicePropietarios: PropietariosService,
    private _serviceTiposPersona: TiposPersonaService
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.frm = this.fb.group({
      propietario_id: [0],
      tipodocumento_id: [3, Validators.required],
      tipopersona_id: [1, Validators.required],
      documento: [
        "",
        {
          validators: [Validators.required],
          asyncValidators: [
            PropietariosValidatorComponent.existingDocumentoValidator(
              this._servicePropietarios,
              true
            ),
          ],
          updateOn: "blur",
        },
      ],
      nombres: ["", Validators.required],
      direccion: ["", Validators.required],
      celular: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
    });

    this._serviceTiposDocumento.getLista().subscribe((resp: any) => {
      this.tipos_documento = resp.data;
    });

    this._serviceTiposPersona.getLista().subscribe((resp: any) => {
      this.tipos_persona = resp.data;
      this.blockUI.stop();
    });

    if (this.isEdit) {
      const id = this._model.propietario_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._servicePropietarios.getbyid(id.toString()).subscribe((resp: any) => {
      this._model = resp.data[0];
      this.frm = this.fb.group({
        propietario_id: [this._model.propietario_id],
        tipopersona_id: [this._model.tipopersona_id, Validators.required],
        tipodocumento_id: [this._model.tipodocumento_id, Validators.required],
        documento: [
          this._model.documento,
          {
            validators: [Validators.required],
            asyncValidators: [
              PropietariosValidatorComponent.existingDocumentoValidator(
                this._servicePropietarios,
                false
              ),
            ],
            updateOn: "blur",
          },
        ],
        nombres: [this._model.nombres, Validators.required],
        direccion: [this._model.direccion, Validators.required],
        celular: [this._model.celular, Validators.required],
        email: [this._model.email, [Validators.required, Validators.email]],
      });
      this.blockUI.stop();
    });
  }
}
