import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillasconsecutivosActions from "../../store/actions/planillas-consecutivos.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillasConsecutivosService } from "../../../../pages/planillas-consecutivos/services/planillas-consecutivos.service";
import { of } from "rxjs";

@Injectable()
export class PlanillasConsecutivosEffects {
  constructor(
    private actions$: Actions,
    private planillasconsecutivosService: PlanillasConsecutivosService
  ) {}

  cargarPlanillasConsecutivos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasconsecutivosActions.cargarPlanillasConsecutivos),
      mergeMap(() =>
        this.planillasconsecutivosService.getListar(1, 10).pipe(
          map((resp) =>
            planillasconsecutivosActions.cargarPlanillasConsecutivosSuccess({
              planillasconsecutivos: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              planillasconsecutivosActions.cargarPlanillasConsecutivosError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  cargarPlanillasConsecutivosByQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasconsecutivosActions.cargarPlanillasConsecutivosByQuery),
      mergeMap((action) =>
        this.planillasconsecutivosService
          .get_buscar(action.pagina, action.limit, action.query)
          .pipe(
            map((resp) =>
              planillasconsecutivosActions.cargarPlanillasConsecutivosByQuerySuccess(
                {
                  planillasconsecutivos: resp["data"],
                  registros: resp["registros"],
                  pagecount: resp["pageCount"],
                }
              )
            ),
            catchError((err) =>
              of(
                planillasconsecutivosActions.cargarPlanillasConsecutivosByQueryError(
                  {
                    payload: err,
                  }
                )
              )
            )
          )
      )
    )
  );
}
