import { createReducer, on } from "@ngrx/store";
import * as actions from "../actions";
import { Planilla } from "../../../../../models/planillas.model";
import { AppState } from "../../../../../app.reducers";

/* export interface State {
  id: string;
  planilla: Planilla;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export interface AppStateWithPlanilla extends AppState {
  planilla: State;
}
export const PlanillaStateInitialState: State = {
  id: null,
  planilla: null,
  loaded: false,
  loading: false,
  error: null,
}; */

export interface PlanillaState {
  id: string;
  planilla: Planilla;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const PlanillaStateInitialState: PlanillaState = {
  id: null,
  planilla: null,
  loaded: false,
  loading: false,
  error: null,
};

const _planillaReducer = createReducer(
  PlanillaStateInitialState,

  on(actions.cargarListaPlanilla, (state) => ({ ...state, loading: true })),

  on(actions.cargarPlanilla, (state, { id }) => ({
    ...state,
    loading: true,
    id: id,
    error: null,
  })),

  on(actions.cargarPlanillaSuccess, (state, { planilla }) => ({
    ...state,
    loading: false,
    loaded: true,
    planilla: { ...planilla },
  })),

  on(actions.cargarPlanillaError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: payload.message,
  })),

  on(actions.addPlanilla, (state, { planilla }) => ({
    ...state,
    planilla: { ...planilla },
    loaded: false,
    loading: true,
  })),
  on(actions.addPlanillaSuccess, (state, { planilla }) => ({
    ...state,
    planilla: { ...planilla },
    loaded: true,
    loading: false,
    error: planilla.message,
  })),
  on(actions.addPlanillaError, (state, { payload }) => ({
    ...state,
    loaded: false,
    loading: false,
    error: payload.message,
  })),
  on(actions.updPlanilla, (state, { planilla }) => ({
    ...state,
    planilla: { ...planilla },
    loaded: false,
    loading: true,
  })),
  on(actions.updPlanillaSuccess, (state, { planilla }) => ({
    ...state,
    planilla: { ...planilla },
    loaded: true,
    loading: false,
    error: planilla.mmessage,
  })),
  on(actions.updPlanillaError, (state, { payload }) => ({
    ...state,
    loaded: true,
    loading: false,
    error: payload.message,
  })),
  on(actions.cargarPlanillasMasterByPlanilla_id, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    actions.cargarPlanillasMasterByPlanilla_idSuccess,
    (state, { planilla }) => ({
      ...state,
      loading: false,
      loaded: true,
      item: planilla,
    })
  ),
  on(actions.cargarPlanillasMasterByPlanilla_idError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function planillaReducer(state, action) {
  return _planillaReducer(state, action);
}
