import { createAction, props } from "@ngrx/store";
import { Anio } from "../../../../../models/anios.model";

export const cargarAnios = createAction(
  "[Anios] Cargar Anios",
  props<{ pagina: string; limit: string }>()
);

export const cargarAniosSuccess = createAction(
  "[Anios] Cargar Anios Success",
  props<{
    anios: Anio[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarAniosError = createAction(
  "[Anios] Cargar Anios Error",
  props<{ payload: any }>()
);

export const cargarAniosByQuery = createAction(
  "[Anios] Cargar Anios Query",
  props<{ pagina: string; limit: string; query: string }>()
);

export const cargarAniosByQuerySuccess = createAction(
  "[Anios] Cargar Anios Query Success",
  props<{
    anios: Anio[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarAniosByQueryError = createAction(
  "[Anios] Cargar Anios Query Error",
  props<{ payload: any }>()
);
