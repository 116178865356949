import { Injectable } from "@angular/core";
import { URL_API } from "../config/config";
import { HttpHeaders, HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UsersRolesService {
  httpOptions = {};

  constructor(private http: HttpClient) {}

  create(model: any) {
    this.loadAuth();
    const url = URL_API + "/users_roles/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    this.loadAuth();
    const url = URL_API + "/users_roles/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
