import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ReportesService } from "../../../services/reportes.service";
import * as moment from "moment";
@Component({
  selector: "app-rpt-movimientos",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./rpt-movimientos.component.html",
  styleUrls: ["./rpt-movimientos.component.css"],
})
export class RptConsignacionesComponent implements OnInit {
  @Input() datax = [];
  @Input() dataTotales = [];
  @Input() consultando = false;
  // data_rpt_movimientos = [];
  tituloReporte = "MOVIMIENTO CUENTAS";
  fileName = "";
  FechaDesde = "";
  FechaHasta = "";
  Empresa = "";
  loading: boolean = false;
  saldo_viene: number = 0;
  saldoquepasa: number = 0;
  fechaHoy = new Date();

  constructor(private _serviceReportes: ReportesService) {}

  ngOnInit() {
    this._serviceReportes.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportes.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });

    this._serviceReportes.saldoviene$.subscribe((resp: any) => {
      this.saldo_viene = resp;
    });
    this._serviceReportes.saldoquepasa$.subscribe((resp: any) => {
      this.saldoquepasa = resp;
    });
  }

  exportAsXLSX(): void {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `MOVIMIENTOS_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY");

    this._serviceReportes.downloadFile_Movimientos(
      this.datax,
      filename,
      periodo,
      this.datax[0].cuenta_banco
    );
  }

  get_Total_Debitos() {
    let total = this.datax
      .map((d) => d.debitos)
      .reduce((sum, d) => sum + parseFloat(d), 0);
    return total;
  }
  get_Total_Creditos() {
    let total = this.datax
      .map((d) => d.creditos)
      .reduce((sum, d) => sum + parseFloat(d), 0);
    return total;
  }
}
