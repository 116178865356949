import { AppState } from "src/app/app.reducers";
import { LugaresControlState } from "../reducers/lugares-control.reducer";
import { createSelector } from "@ngrx/store";

export const selectLugaresControl = (state: AppState) => state.lugarescontrol;

export const selectListLugaresControl = createSelector(
  selectLugaresControl,
  (state: LugaresControlState) => state.items
);
export const selectLoadingLugaresControl = createSelector(
  selectLugaresControl,
  (state: LugaresControlState) => state.loading
);
export const selectFailLugaresControl = createSelector(
  selectLugaresControl,
  (state: LugaresControlState) => state.error
);
