import { createReducer, on } from "@ngrx/store";
import {
  cargarPlanillasRutasDespachos,
  cargarPlanillasRutasDespachosError,
  cargarPlanillasRutasDespachosSuccess,
} from "../actions";
import { PlanillaRutaDespacho } from "../../../../../models/planillas_rutasdespachos.model";

export interface PlanillasRutasDespachosState {
  items: PlanillaRutaDespacho[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const planillasrutasdespachosInitialState: PlanillasRutasDespachosState =
  {
    items: [],
    loaded: false,
    loading: false,
    error: null,
    registros: 0,
    pagecount: 0,
  };

const _planillasrutasdespachosReducer = createReducer(
  planillasrutasdespachosInitialState,

  on(cargarPlanillasRutasDespachos, (state) => ({ ...state, loading: true })),

  on(
    cargarPlanillasRutasDespachosSuccess,
    (state, { planillasrutasdespachos, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillasrutasdespachos],
      registros: registros,
      pagecount: pagecount,
    })
  ),

  on(cargarPlanillasRutasDespachosError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function planillasrutasdespachosReducer(state, action) {
  return _planillasrutasdespachosReducer(state, action);
}
