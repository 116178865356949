export class User {
  user_id: number;
  email: string;
  user_nombres: string;
  nombre: string;
  password: string;
  activo: number;
  despacho_nombres: string;
  despacho_id: number;
}
