import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { AppState } from "../../../../app.reducers";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Sancion } from "src/app/models/sanciones.model";
import { SancionesService } from "../services/sanciones.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SancionesNewEditComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;

  frm: FormGroup;
  loading: boolean = false;
  _userSession: any = "0";
  sanciones: Sancion[] = [];
  isEdit: boolean = false;
  _model: Sancion;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private store: Store<AppState>,
    private _serviceModel: SancionesService
  ) {}

  ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      sancion_id: [0],
      descripcion: ["", Validators.required],
      valor_sancion: [0, Validators.required],
      isupdate: [false],
    });
    this.getSanciones().subscribe((datos) => {
      this.sanciones = datos.items;
    });

    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.sancion_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceModel.getById(id.toString()).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp;
      this.frm = this.fb.group({
        sancion_id: [this._model.sancion_id],
        descripcion: [this._model.descripcion, Validators.required],
        valor_sancion: [this._model.valor_sancion, Validators.required],
        isupdate: [this._model.isupdate],
      });
      this.blockUI.stop();
    });
  }

  getSanciones(): Observable<any> {
    return this.store.select((appState) => appState.sanciones);
    // .pipe(filter(Boolean))
  }
}
