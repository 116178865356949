import { createAction, props } from "@ngrx/store";
import { PlanillaParametro } from "../../../../../models/planilla-parametro.model";

export const cargarParametroAnioActual = createAction(
  "[Planilla Parametros] Cargar Parametros Año Actual",
  props<{ id: string }>()
);

export const cargarParametroAnioActualSuccess = createAction(
  "[Planilla Parametros] Cargar Parametros Success Año Actual",
  props<{ planillaparametro: PlanillaParametro }>()
);

export const cargarParametroAnioActualError = createAction(
  "[Planilla Parametros] Cargar Parametros Error",
  props<{ payload: any }>()
);
export const addPlanillaParametro = createAction(
  "[Planilla Parametros] Nuevo Planilla Parametros",
  props<{ planillaparametro: PlanillaParametro }>()
);

export const addPlanillaParametroSuccess = createAction(
  "[Planilla Parametros] Nuevo Planilla Parametros Success",
  props<{ planillaparametro: any }>()
);

export const addPlanillaParametroError = createAction(
  "[Planilla Parametros] Nuevo Planilla Parametros Error",
  props<{ payload: any }>()
);

export const updPlanillaParametro = createAction(
  "[Planilla Parametros] Update Planilla Parametro",
  props<{ planillaparametro: any }>()
);

export const updPlanillaParametroSuccess = createAction(
  "[Planilla Parametros] Update Planilla Parametros Success",
  props<{ planillaparametro: any }>()
);

export const updPlanillaParametroError = createAction(
  "[Planilla Parametros] Update Planilla Parametros Error",
  props<{ payload: any }>()
);
