import { Component, OnInit } from "@angular/core";
import { RutasDespachosLugaresService } from "../../rutasdespachos-lugares/services/rutasdespachos-lugares.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducers";
import { RutaDespachoLugar } from "src/app/models/rutasdespachos-lugares.model";
import { filter, map, tap } from "rxjs/operators";
import { selectListPlanillaParametro } from "../../planilla-parametros/store/selectors/planilla-parametro.selector";
import { Observable, Subscription } from "rxjs";
import { PlanillaParametro } from "src/app/models/planilla-parametro.model";
import { selectEmpresa } from "src/app/pages/empresa/store/selectors/empresa.selector";
import { Empresa } from "src/app/models/empresa.model";

@Component({
  selector: "app-cards-rutas",
  templateUrl: "./cards-rutas.component.html",
  styleUrls: ["./cards-rutas.component.css"],
})
export class CardsRutasComponent implements OnInit {
  data_rutas: RutaDespachoLugar[] = [];
  groupedData: any[];
  data$: Observable<any>;
  datas$: Observable<any>;
  loaded$: Observable<boolean> = new Observable();
  loading$: Observable<boolean> = new Observable();
  registradoraSubcription: Subscription;
  pp: PlanillaParametro;

  datax: PlanillaParametro;
  data_empresa: Empresa;

  constructor(
    private _serviceRutas: RutasDespachosLugaresService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    /* this.loaded$ = this.store.select(selectLoadedPlanillaParametro);
    this.loading$ = this.store.select(selectLoadingPlanillaParametro);
    this.datas$ = this.store.select(selectListPlanillasParametros); */

    this.store
      .select(selectListPlanillaParametro)
      .pipe(filter((x) => x !== null))
      .subscribe((resp) => {
        this.datax = resp[0];
      });

    this.store
      .select(selectEmpresa)
      .pipe(filter((x) => x.item !== null))
      .subscribe((resp) => {
        this.data_empresa = resp.item[0];
      });

    this._serviceRutas
      .getTarjetasRutas()
      .subscribe((resp: RutaDespachoLugar[]) => {
        this.data_rutas = resp["data"];
        this.groupData();
      });
  }

  groupData(): void {
    const groups = this.data_rutas.reduce((result, ruta) => {
      const group = result.find(
        (item) => item.ruta === ruta.rutadespacho_nombre
      );
      if (group) {
        group.lugares.push(ruta);
      } else {
        result.push({ ruta: ruta.rutadespacho_nombre, lugares: [ruta] });
      }
      return result;
    }, []);
    this.groupedData = groups;
    let x = 0;
    this.groupedData.forEach((item) => {
      let acum = 0;
      item.lugares.forEach((element) => {
        acum = acum + element.minuto;
        element.minutos_acumulado = acum;
      });
      x++;
    });
  }
}
