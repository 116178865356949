import { createAction, props } from "@ngrx/store";
import { PlanillaParametro } from "../../../../../models/planilla-parametro.model";

export const cargarPlanillaParametros = createAction(
  "[Planilla Parametros] Cargar Parametros",
  props<{ pagina: string; limit: string }>()
);

export const cargarPlanillaParametrosSuccess = createAction(
  "[Planilla Parametros] Cargar Parametros Success",
  props<{
    planillaparametros: PlanillaParametro[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarPlanillaParametrosError = createAction(
  "[Planilla Parametros] Cargar Parametros Error",
  props<{ payload: any }>()
);

export const cargarPlanillaParametrosByQuery = createAction(
  "[Parametros] Cargar Parametros Query",
  props<{ pagina: string; limit: string; query: string }>()
);

export const cargarPlanillaParametrosByQuerySuccess = createAction(
  "[Parametros] Cargar Parametros Query Success",
  props<{
    planillaparametros: PlanillaParametro[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarPlanillaParametrosByQueryError = createAction(
  "[Parametros] Cargar Parametros Query Error",
  props<{ payload: any }>()
);
