import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillaliquidacionActions from "../../store/actions/planilla-liquidacion.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillasLiquidacionService } from "../../../../pages/planillas-liquidacion/services/planillas-liquidacion.service";
import { of } from "rxjs";

@Injectable()
export class PlanillaLiquidacionEffects {
  constructor(
    private actions$: Actions,
    private planillaliquidacionsService: PlanillasLiquidacionService
  ) {}

  cargarPlanillaLiquidacion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaliquidacionActions.cargarPlanillaLiquidacion),
      mergeMap((action) =>
        this.planillaliquidacionsService.getById(action.id).pipe(
          map((data) =>
            planillaliquidacionActions.cargarPlanillaLiquidacionSuccess({
              planillaliquidacion: data,
            })
          ),
          catchError((err) =>
            of(
              planillaliquidacionActions.cargarPlanillaLiquidacionError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  addPlanillaLiquidacion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaliquidacionActions.addPlanillaLiquidacion),
      mergeMap((action) =>
        this.planillaliquidacionsService
          .create(action.planillaliquidacion)
          .pipe(
            map((data) =>
              planillaliquidacionActions.addPlanillaLiquidacionSuccess({
                planillaliquidacion: data,
              })
            ),
            catchError((err) =>
              of(
                planillaliquidacionActions.addPlanillaLiquidacionError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );
}
