import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDatepickerInputEvent,
  MatDialog,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { AppState } from "../../../../app.reducers";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Anio } from "src/app/models/anios.model";
import { Planilla } from "src/app/models/planillas.model";
import { PlanillasService } from "../services/planillas.service";
import { DlgVehiculosComponent } from "src/app/busquedas/dlg-vehiculos.component";
import { DlgConductoresComponent } from "src/app/busquedas/dlg-conductores.component";
import { PlanillasConsecutivosService } from "../../planillas-consecutivos/services/planillas-consecutivos.service";
import Swal from "sweetalert2";
import {
  checkConsecPlanillaValidator,
  greaterThanZeroValidator,
} from "src/app/validators/form-validators";
import { DlgRelevantesComponent } from "src/app/busquedas/dlg-relevantes.component";
import * as moment from "moment";
import { ConductoresService } from "src/app/services/conductores.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PlanillaNewEditComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  @ViewChild("btnGuardarPlanilla", { static: false })
  btnGuardarPlanilla: ElementRef;

  frm: FormGroup;
  loading: boolean = false;
  _userSession: any = "0";
  _numero = "";
  _rutadespacho = "";
  _condid = 0;
  _relevid = 0;
  anios: Anio[] = [];
  isEdit: boolean = false;
  _model: Planilla;
  data_search_vehiculos = [];
  data_search_rutasdespachos = [];
  data_search_conductores = [];
  data_planilla_dia = [];
  data_search_relevantes = [];
  date: Date[] | undefined;
  data_conductores = [];
  _consecutivonuevo: number = 0;
  _vehiculo_id: number = 0;
  consec: number;
  isConsecAvailable: boolean = false;
  fplanilla: string = "";
  planilladia_existe: boolean = false;
  vh_id: number;

  @HostListener("window:keydown", ["$event"])
  handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case "F1":
        event.preventDefault();
        break;
      case "F10":
        event.preventDefault();
        this.openDialog_Vehiculos();
        break;

      case "F11":
        event.preventDefault();
        this.openDialog_Relevantes();
        break;

      default:
        break;
    }
    if (event.ctrlKey && event.key === "Enter") {
      console.log("Ctrl Enter");
      this.triggerButtonGurdarClick();
    }
  }

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private store: Store<AppState>,
    private _serviceModel: PlanillasService,
    private _serviceConductores: ConductoresService,
    private _servicePlanillasConsecutivos: PlanillasConsecutivosService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      planilla_id: [0],
      planillaparametro_id: [4],
      vehiculo_id: [6, Validators.required],
      conductor_id: [0, Validators.required],
      relevante_id: [-1],
      propietario_id: [0, Validators.required],
      socio_id: [0, Validators.required],
      despacho_id: [this._userSession.despacho_id, Validators.required],
      user_id: [this._userSession.user_id, Validators.required],
      fecha_planilla: ["", Validators.required],
      /* consecutivo: [
        0,
        {
          validators: greaterThanZeroValidator(),
          asyncValidators: checkConsecPlanillaValidator(this._serviceModel),
          updateOn: "blur",
        },
      ], */
      consecutivo: [0],
      _numero: ["", Validators.required],
      placa: ["", Validators.required],
      _consecutivo_anterior: [""],
      _conductor_nombre: ["", Validators.required],
      _relevante_nombre: [""],
      _despacho_nombre: [this._userSession.despacho_nombre],
    });
    this.getAnios().subscribe((datos) => {
      this.anios = datos.items;
    });

    // await this.get_consecutivo_nuevo();

    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.planilla_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceModel
      .getRegistradaById(id.toString())
      .subscribe((resp: any) => {
        this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
        this._model = resp["data"];
        this._vehiculo_id = this._model.vehiculo_id;

        this.frm = this.fb.group({
          planilla_id: [this._model.planilla_id],
          vehiculo_id: [this._model.vehiculo_id, Validators.required],
          conductor_id: [this._model.conductor_id, Validators.required],
          relevante_id: [this._model.relevante_id, Validators.required],
          propietario_id: [this._model.propietario_id, Validators.required],
          socio_id: [this._model.socio_id, Validators.required],
          despacho_id: [this._model.despacho_id, Validators.required],
          user_id: [this._model.user_id, Validators.required],
          fecha_planilla: [
            moment(this._model.fecha_planilla),
            Validators.required,
          ],
          consecutivo: [this._model.consecutivo, Validators.required],
          _consecutivo_anterior: [""],
          observacion: [this._model.observacion],
          placa: [this._model.placa, Validators.required],
          _numero: [this._model.numero, Validators.required],
          _conductor_nombre: [this._model.conductor_nombre],
          _relevante_nombre: [this._model.relevante_nombre],
          _despacho_nombre: [this._model.despacho_nombre],
        });

        this.fplanilla = moment(this._model.fecha_planilla).format(
          "YYYY-MM-DD"
        );
        if (this._model.planilla_id) {
          this.get_Consecutivo_ultimo(this._vehiculo_id.toString());
        }
        this.blockUI.stop();
      });
  }

  getAnios(): Observable<any> {
    return this.store.select((appState) => appState.anios);
  }

  get_Consecutivo_ultimo(vehiculo_id: string) {
    this._servicePlanillasConsecutivos
      .get_consecutivo_maxbyvehiculo(vehiculo_id)
      .subscribe((resp) => {
        if (resp) {
          this.frm.controls["_consecutivo_anterior"].patchValue(
            resp.consecutivo_ultimo
          );
        } else {
          this.frm.controls["_consecutivo_anterior"].patchValue(
            "No registra..."
          );
        }
      });
  }

  solo_numeros(value: string, ctrl: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls[ctrl].setValue(value);
    }
  }

  openDialog_Vehiculos(): void {
    if (this.fplanilla.length === 0) {
      Swal.fire(
        "Atención",
        "Seleccione primero la Fecha de la planilla",
        "warning"
      );
      return;
    }
    const dialogRef = this.dialog.open(DlgVehiculosComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.frm.controls._conductor_nombre.patchValue("");
        this.frm.controls.conductor_id.patchValue("");
        this.frm.controls._relevante_nombre.patchValue("");
        this.frm.controls.relevante_id.patchValue(-1);

        this.data_search_vehiculos = result;
        /* if (result[0].count_abiertas >= 2) {
          this.frm.controls.vehiculo_id.patchValue("");
          this.frm.controls._numero.patchValue("");
          Swal.fire(
            "Atención",
            "El Vehículo tiene " +
              result[0].count_abiertas +
              " Planillas sin cerrar...",
            "error"
          );
          return false;
        } */
        this.frm.controls.vehiculo_id.patchValue(result[0].vehiculo_id);
        this._vehiculo_id = result[0].vehiculo_id;
        this.frm.controls.propietario_id.patchValue(result[0].propietario_id);
        this.frm.controls.socio_id.patchValue(result[0].socio_id);
        this.frm.controls._numero.patchValue(result[0].numero);
        this.frm.controls.placa.patchValue(result[0].placa);

        if (result[0].vehiculo_id > 0) {
          this.get_conductor_unq_byVehiculoId(result[0].vehiculo_id);
          this.get_planilladia_byVehiculoId(
            result[0].vehiculo_id,
            moment(this.fplanilla).format("YYYY-MM-DD")
          );
        }

        dialogSubmitSubscription.unsubscribe();
        this.get_Consecutivo_ultimo(result[0].vehiculo_id);
      });
  }

  openDialog_Conductores(): void {
    if (this._vehiculo_id === 0) {
      Swal.fire("Atención", "Debe seleccionar el Vehículo...", "error");
    } else {
      const dialogRef = this.dialog.open(DlgConductoresComponent, {
        width: "800px",
        data: {
          isEdit: this.isEdit,
          _vehiculo_id: this._vehiculo_id,
        },
      });

      const dialogSubmitSubscription =
        dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
          this.data_search_conductores = result;
          this.frm.controls.conductor_id.patchValue(result[0].conductor_id);
          this.frm.controls._conductor_nombre.patchValue(
            result[0].conductor_nombres
          );
          this._condid = result[0].conductor_id;
          dialogSubmitSubscription.unsubscribe();
        });
    }
  }

  openDialog_Relevantes(): void {
    if (this._vehiculo_id === 0) {
      Swal.fire("Atención", "Debe seleccionar el Vehículo...", "error");
    } else {
      const dialogRef = this.dialog.open(DlgRelevantesComponent, {
        width: "800px",
        data: {
          isEdit: this.isEdit,
          _vehiculo_id: this._vehiculo_id,
        },
      });

      const dialogSubmitSubscription =
        dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
          this.data_search_relevantes = result;
          this.frm.controls.relevante_id.patchValue(result[0].relevante_id);
          this.frm.controls._relevante_nombre.patchValue(
            result[0].relevante_nombres
          );
          this._relevid = result[0].relevante_id;
          dialogSubmitSubscription.unsubscribe();
        });
    }
  }

  checkConsecutivo(value: number) {
    if (value > 0) {
      this._serviceModel.get_consecutivoexiste(value).subscribe((resp: any) => {
        console.log("checkConsecutivo", resp.count);
        if (resp.count > 0) {
          this.isConsecAvailable = true;
        } else {
          this.isConsecAvailable = false;
        }
      });
    }
  }

  get_conductor_unq_byVehiculoId(vehiculo_id: number) {
    this._serviceConductores
      .get_unq_byvehiculoid(vehiculo_id)
      .subscribe((resp: any) => {
        this.data_search_conductores = resp.data;
        this.frm.controls.conductor_id.patchValue(
          this.data_search_conductores[0].conductor_id
        );
        this.frm.controls._conductor_nombre.patchValue(
          this.data_search_conductores[0].nombres
        );
        this._condid = this.data_search_conductores[0].conductor_id;
        if (this.data_search_conductores.length === 0) {
          Swal.fire(
            "Atención",
            "No se registra Conductor para este vehículo...",
            "warning"
          );
        }
      });
  }

  get_planilladia_byVehiculoId(vehiculo_id: number, fplanilla: string) {
    this._serviceModel
      .get_planilladia_byVehiculoId(vehiculo_id, fplanilla)
      .subscribe((resp: any) => {
        this.data_planilla_dia = resp.data;
        if (this.data_planilla_dia.length > 0) {
          this.planilladia_existe = true;
          Swal.fire(
            "Atención",
            "El Vehículo <strong>" +
              this.data_planilla_dia[0].numero +
              "</strong> ya registra la Planilla <strong>" +
              this.data_planilla_dia[0].planilla_id +
              "</strong> para la fecha seleccionada...",
            "warning"
          );
        } else {
          this.planilladia_existe = false;
        }
      });
  }

  triggerButtonGurdarClick() {
    if (this.frm.valid) {
      this.btnGuardarPlanilla.nativeElement.click();
    }
  }

  public validar_planilla_dia(event: MatDatepickerInputEvent<Date>): void {
    console.log("_vehiculo_id", this._vehiculo_id);
    if (this._vehiculo_id > 0) {
      this.get_planilladia_byVehiculoId(
        this._vehiculo_id,
        moment(event.value).format("YYYY-MM-DD")
      );
    }
  }
}
