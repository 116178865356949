import { Component, OnInit } from "@angular/core";
import { EmpresaService } from "../../services/empresa.service";

@Component({
  selector: "app-rpt-header",
  templateUrl: "./rpt-header.component.html",
  styles: [],
})
export class RptHeaderComponent implements OnInit {
  data_empresa = [];
  empresa = "";

  constructor(private _serviceEmpresa: EmpresaService) {}

  ngOnInit() {
    this._serviceEmpresa.get_listar("1", "10").subscribe((resp: any) => {
      this.data_empresa = resp.data;
    });
  }
}
