import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MensajesToastrService } from "src/app/mensajes/toastr-service";
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private _serviceMensajes: MensajesToastrService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem("token");
    if (token) {
      const decoded: { exp: number } = jwtDecode(token);
      const expirationDate = new Date(decoded.exp * 1000);
      const now = new Date();

      if (expirationDate <= now) {
        sessionStorage.removeItem("token");
        this.router.navigate(["/login"]);
        return throwError({ error: "La Sesion ha expirado" });
      }

      const headers = new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      });

      const reqclone = req.clone({
        headers,
      });

      return next.handle(reqclone).pipe(catchError(this.manejarError));
    }
    return next.handle(req);
  }

  manejarError(error: HttpErrorResponse) {
    return throwError(error.message);
  }
}
