import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { URL_API_NESTJS } from "src/app/config/config";

@Injectable({
  providedIn: "root",
})
export class LineasCreditoService {
  private url = URL_API_NESTJS;

  constructor(private http: HttpClient) {}

  get_lista() {
    return this.http.get(`${this.url}/cop-lineas-credito`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
}
