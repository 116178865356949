import { createAction, props } from "@ngrx/store";
import { Anio } from "../../../../../models/anios.model";

export const cargarAnio = createAction(
  "[Anio] Cargar Anio",
  props<{ id: string }>()
);

export const cargarListaAnios = createAction(
  "[Lista Anios] Cargar Lista Anios"
);

export const cargarAnioSuccess = createAction(
  "[Anio] Cargar Anio Success",
  props<{ anio: Anio }>()
);

export const cargarAnioError = createAction(
  "[Anio] Cargar Anio Error",
  props<{ payload: any }>()
);
export const addAnio = createAction(
  "[Anio] Nuevo Anio",
  props<{ anio: Anio }>()
);

export const addAnioSuccess = createAction(
  "[Anio] Nuevo Anio Success",
  props<{ anio: any }>()
);

export const addAnioError = createAction(
  "[Anio] Nuevo Anio Error",
  props<{ payload: any }>()
);

export const updAnio = createAction(
  "[Anio] Update Anio",
  props<{ anio: any }>()
);

export const updAnioSuccess = createAction(
  "[Anio] Update Anio Success",
  props<{ anio: any }>()
);

export const updAnioError = createAction(
  "[Anio] Update Anio Error",
  props<{ payload: any }>()
);
