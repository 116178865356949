import { createReducer, on } from "@ngrx/store";
import * as actions from "../actions";
import { Empresa } from "src/app/models/empresa.model";

export interface EmpresaState {
  id: string;
  item: Empresa;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const EmpresaStateInitialState: EmpresaState = {
  id: null,
  item: null,
  loaded: false,
  loading: false,
  error: null,
};

const _empresaReducer = createReducer(
  EmpresaStateInitialState,

  on(actions.cargarEmpresa, (state, { id }) => ({
    ...state,
    loading: true,
    id: id,
    error: null,
  })),

  on(actions.cargarEmpresaSuccess, (state, { empresa }) => ({
    ...state,
    loading: false,
    loaded: true,
    item: { ...empresa },
  })),

  on(actions.cargarEmpresaError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: payload.message,
  })),

  on(actions.addEmpresa, (state, { empresa }) => ({
    ...state,
    item: { ...empresa },
    loaded: false,
    loading: true,
  })),
  on(actions.addEmpresaSuccess, (state, { empresa }) => ({
    ...state,
    item: { ...empresa },
    loaded: true,
    loading: false,
    error: empresa.message,
  })),
  on(actions.addEmpresaError, (state, { payload }) => ({
    ...state,
    loaded: false,
    loading: false,
    error: payload.message,
  })),
  on(actions.updEmpresa, (state, { empresa }) => ({
    ...state,
    item: { ...empresa },
    loaded: false,
    loading: true,
  })),
  on(actions.updEmpresaSuccess, (state, { empresa }) => ({
    ...state,
    item: { ...empresa },
    loaded: true,
    loading: false,
    error: empresa.mmessage,
  })),
  on(actions.updEmpresaError, (state, { payload }) => ({
    ...state,
    loaded: true,
    loading: false,
    error: payload.message,
  }))
);

export function empresaReducer(state, action) {
  return _empresaReducer(state, action);
}
