import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ReportesService } from "../../../services/reportes.service";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
@Component({
  selector: "app-rpt-retiros",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./rpt-retiros.component.html",
  styleUrls: ["./rpt-retiros.component.css"],
})
export class RptRetirosComponent implements OnInit {
  @Input() datax = [];
  @Input() dataTotales = [];
  @Input() chkBus = false;
  @Input() consultando = false;
  data_rpt_retiros = [];
  tituloReporte = "RELACION DE RETIROS";
  fileName = "";
  FechaDesde = "";
  FechaHasta = "";
  Empresa = "";
  loading: boolean = false;
  _mes = "";
  _bus = "";

  constructor(private _serviceReportes: ReportesService) {}

  ngOnInit() {
    this._serviceReportes.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportes.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });

    this._serviceReportes.mes$.subscribe((resp: any) => {
      this._mes = resp;
    });

    this._serviceReportes.bus$.subscribe((resp: any) => {
      this._bus = resp;
    });
  }

  exportAsXLSX(): void {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `RETIROS_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY");

    /* this._serviceReportes.downloadFile_Retiros(
      this.data_rpt_retiros,
      filename,
      this._mes,
      this._bus,
      periodo
    ); */

    this._serviceReportes.downloadFile_Retiros(this.datax, filename, periodo);
  }

  get_Total_Retiro() {
    let total = this.datax
      .map((d) => d.valor_retiro)
      .reduce((sum, d) => sum + parseFloat(d), 0);
    return total;
  }

  get_Total_Administracion() {
    let total = this.datax
      .map((d) => d.administracion)
      .reduce((sum, d) => sum + parseFloat(d), 0);
    return total;
  }
  get_Total_Cooperativa() {
    let total = this.datax
      .map((d) => d.cooperativa)
      .reduce((sum, d) => sum + parseFloat(d), 0);
    return total;
  }

  get_Total_Aportes() {
    let total = this.datax
      .map((d) => d.aportes)
      .reduce((sum, d) => sum + parseFloat(d), 0);
    return total;
  }

  get_Total_Otros() {
    let total = this.datax
      .map((d) => d.otros)
      .reduce((sum, d) => sum + parseFloat(d), 0);
    return total;
  }

  get_Total_Neto() {
    let total = this.datax
      .map((d) => d.valor_neto)
      .reduce((sum, d) => sum + parseFloat(d), 0);
    return total;
  }

  /* exportAsXLSX(): void {
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let titulos = {
      A: "Fecha",
      B: "Ruta",
      C: "Bus",
      D: "Valor",
    };

    if (this.data_rpt_retiros.length === 0) {
      Swal.fire("Advertencia", "No hay datos para exportar...", "warning");
    } else {
      let itemsDisplay: any[] = [];
      itemsDisplay.push(titulos);
      this.data_rpt_retiros.map((c) => {
        itemsDisplay.push({
          A: this.datePipe.transform(c.fecha_retiro, "dd/MM/yyyy"),
          B: c.ruta_nombre,
          C: c.numero,
          D: parseFloat(c.valor_retiro),
        });
      });

      let Heading = [["COLECTIVOS CIUDAD DE IPIALES"]];
      let subtitulo = [["RELACION DE RETIROS"]];
      let fecha = [{ a: "MES:", b: strDate }];

      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, Heading);

      XLSX.utils.sheet_add_json(ws, subtitulo, {
        origin: "A2",
        skipHeader: true,
      });

      XLSX.utils.sheet_add_json(ws, fecha, {
        origin: "A3",
        skipHeader: true,
      });
      XLSX.utils.sheet_add_json(ws, itemsDisplay, {
        origin: "A5",
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(wb, ws, "Retiros");
      this.fileName = `Retiros${this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd"
      )}.xlsx`;
      XLSX.writeFile(wb, this.fileName);
    }
  } */
}
