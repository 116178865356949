import { createReducer, on } from "@ngrx/store";
import {
  cargarPlanillasConsecutivos,
  cargarPlanillasConsecutivosByQuery,
  cargarPlanillasConsecutivosByQueryError,
  cargarPlanillasConsecutivosByQuerySuccess,
  cargarPlanillasConsecutivosError,
  cargarPlanillasConsecutivosSuccess,
} from "../actions";
import { PlanillaConsecutivo } from "../../../../../models/planillas-consecutivos.model";

export interface PlanillasConsecutivosState {
  items: PlanillaConsecutivo[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const planillasconsecutivosInitialState: PlanillasConsecutivosState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _planillasconsecutivosReducer = createReducer(
  planillasconsecutivosInitialState,

  on(cargarPlanillasConsecutivos, (state) => ({ ...state, loading: true })),

  on(
    cargarPlanillasConsecutivosSuccess,
    (state, { planillasconsecutivos, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillasconsecutivos],
      registros: registros,
      pagecount: pagecount,
    })
  ),

  on(cargarPlanillasConsecutivosError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(cargarPlanillasConsecutivosByQuery, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    cargarPlanillasConsecutivosByQuerySuccess,
    (state, { planillasconsecutivos, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillasconsecutivos],
      registros: registros,
      pagecount: pagecount,
    })
  ),
  on(cargarPlanillasConsecutivosByQueryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function planillasconsecutivosReducer(state, action) {
  return _planillasconsecutivosReducer(state, action);
}
