import { createAction, props } from "@ngrx/store";
import { PlanillaLiquidacion } from "../../../../../models/planillas-liquidacion.model";

export const cargarPlanillasLiquidacion = createAction(
  "[Planillas Liquidacion] Cargar Liquidaciones"
);

export const cargarPlanillasLiquidacionSuccess = createAction(
  "[Planillas Liquidacion] Cargar Liquidaciones Success",
  props<{
    planillasliquidacion: PlanillaLiquidacion[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarPlanillasLiquidacionError = createAction(
  "[Planillas Liquidacion] Cargar Liquidaciones Error",
  props<{ payload: any }>()
);
