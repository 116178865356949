import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class MensajesToastrService {
  constructor(private serviceToastr: ToastrService) {}

  showSuccess(msg: string) {
    this.serviceToastr.success(msg, "Atención");
  }

  showError(msg: string) {
    this.serviceToastr.error(msg, "Atención");
  }

  showInfo(msg: string) {
    this.serviceToastr.info(msg, "Atención");
  }

  showWarning(msg: string) {
    this.serviceToastr.warning(msg, "Atención");
  }
}
