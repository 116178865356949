import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillasActions from "../../store/actions/planillas.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillasService } from "../../../../pages/planillas/services/planillas.service";
import { of } from "rxjs";

@Injectable()
export class PlanillasEffects {
  constructor(
    private actions$: Actions,
    private planillasService: PlanillasService
  ) {}

  cargarPlanillas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasActions.cargarPlanillas),
      mergeMap((action) =>
        this.planillasService.getListar("1", "10").pipe(
          map((resp) =>
            planillasActions.cargarPlanillasSuccess({
              planillas: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              planillasActions.cargarPlanillasError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  cargarPlanillasRegistradas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasActions.cargarPlanillasRegistradas),
      mergeMap((action) =>
        this.planillasService
          .getListar_Registradas(action.pagina, action.limit)
          .pipe(
            map((resp) =>
              planillasActions.cargarPlanillasRegistradasSuccess({
                planillas: resp["data"],
                registros: resp["registros"],
                pagecount: resp["pageCount"],
              })
            ),
            catchError((err) =>
              of(
                planillasActions.cargarPlanillasRegistradasError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );

  cargarPlanillasRegistradasByPeriodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasActions.cargarPlanillasRegistradasByPeriodo),
      mergeMap((action) =>
        this.planillasService
          .getListar_Registradasbyperiodo(
            action.desde,
            action.hasta,
            action.limit
          )
          .pipe(
            map((resp) =>
              planillasActions.cargarPlanillasRegistradasByPeriodoSuccess({
                planillas: resp["data"],
                registros: resp["registros"],
              })
            ),
            catchError((err) =>
              of(
                planillasActions.cargarPlanillasRegistradasByPeriodoError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );

  cargarPlanillasRegistradasByUser_id$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasActions.cargarPlanillasRegistradasByUser_id),
      mergeMap((action) =>
        this.planillasService
          .getListar_RegistradasByUser_id(
            action.pagina,
            action.limit,
            action.user_id
          )
          .pipe(
            map((resp) =>
              planillasActions.cargarPlanillasRegistradasByUser_idSuccess({
                planillas: resp["data"],
                registros: resp["registros"],
                pagecount: resp["pageCount"],
              })
            ),
            catchError((err) =>
              of(
                planillasActions.cargarPlanillasRegistradasByUser_idError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );

  cargarPlanillasRegistradasByUserPeriodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasActions.cargarPlanillasRegistradasByUserPeriodo),
      mergeMap((action) =>
        this.planillasService
          .getListar_RegistradasByUserPeriodo(
            action.desde,
            action.hasta,
            action.limit,
            action.user_id
          )
          .pipe(
            map((resp) =>
              planillasActions.cargarPlanillasRegistradasByUserPeriodoSuccess({
                planillas: resp["data"],
                registros: resp["registros"],
              })
            ),
            catchError((err) =>
              of(
                planillasActions.cargarPlanillasRegistradasByUserPeriodoError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );

  cargarPlanillasByQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasActions.cargarPlanillasByQuery),
      mergeMap((action) =>
        this.planillasService
          .get_buscar(action.pagina, action.limit, action.query)
          .pipe(
            map((resp) =>
              planillasActions.cargarPlanillasByQuerySuccess({
                planillas: resp["data"],
                registros: resp["registros"],
                pagecount: resp["pageCount"],
              })
            ),
            catchError((err) =>
              of(
                planillasActions.cargarPlanillasByQueryError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );

  cargarPlanillasByQueryPeriodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasActions.cargarPlanillasByQueryPeriodo),
      mergeMap((action) =>
        this.planillasService
          .get_buscarbyperiodo(
            action.desde,
            action.hasta,
            action.limit,
            action.termino
          )
          .pipe(
            map((resp) =>
              planillasActions.cargarPlanillasByQueryPeriodoSuccess({
                planillas: resp["data"],
                registros: resp["registros"],
              })
            ),
            catchError((err) =>
              of(
                planillasActions.cargarPlanillasByQueryPeriodoError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );
}
