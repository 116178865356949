import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { Recaudo } from "../../models/recaudos.model";
import { RutasService } from "../../services/rutas.service";
import { MatDialog } from "@angular/material";
import { DlgRutasComponent } from "../../busquedas/dlg-rutas.component";
import { DlgVehiculosComponent } from "../../busquedas/dlg-vehiculos.component";
import { RecaudosService } from "src/app/services/recaudos.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-recaudos-new",
  templateUrl: "./recaudos-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RecaudosNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  isLoading: boolean = false;
  frm: FormGroup;
  rutas = [];
  propietarios = [];
  data_search_vehiculos = [];
  data_search_rutas = [];
  _model: Recaudo;
  _numero = "";
  isEdit: boolean = false;
  _proid: number = 0;
  _userSession: any = "0";

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceRutas: RutasService,
    private _serviceRecaudos: RecaudosService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      recaudo_id: [0],
      vehiculo_id: [0, Validators.required],
      propietario_id: [0, Validators.required],
      ruta_id: [0, Validators.required],
      user_id: [this._userSession.user_id, Validators.required],
      fecha_recaudo: ["", Validators.required],
      producido: [0, Validators.required],
      ahorro: [0, Validators.required],
      cooperativa: [0, Validators.required],
      planilla: [0, Validators.required],
      acpm: [0, Validators.required],
      pago_conductor: [0, Validators.required],
      observacion: [""],
      _ruta_nombre: ["", Validators.required],
      _numero: ["", Validators.required],
    });

    this._serviceRutas.get_listar("1", "10").subscribe((resp: any) => {
      this.rutas = resp.data;
      this.blockUI.stop();
    });

    if (this.isEdit) {
      const id = this._model.recaudo_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceRecaudos.getbyid(id.toString()).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp.data[0];
      this.frm = this.fb.group({
        vehiculo_id: [this._model.vehiculo_id],
        propietario_id: [resp.data[0].propietario_id, Validators.required],
        ruta_id: [this._model.ruta_id, Validators.required],
        user_id: [this._userSession.user_id, Validators.required],
        fecha_recaudo: [this._model.fecha_recaudo, Validators.required],
        producido: [this._model.producido, Validators.required],
        ahorro: [this._model.ahorro, Validators.required],
        cooperativa: [this._model.cooperativa, Validators.required],
        planilla: [this._model.planilla, Validators.required],
        acpm: [this._model.acpm, Validators.required],
        pago_conductor: [this._model.pago_conductor, Validators.required],
        observacion: [this._model.observacion],
        _ruta_nombre: [resp.data[0].ruta_nombre, Validators.required],
        _numero: [resp.data[0].numero, Validators.required],
      });
      this.blockUI.stop();
    });
  }

  openDialog_Rutas(): void {
    const dialogRef = this.dialog.open(DlgRutasComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_rutas = result;
        this.frm.controls.ruta_id.patchValue(result[0].ruta_id);
        this.frm.controls._ruta_nombre.patchValue(result[0].ruta_nombre);
        dialogSubmitSubscription.unsubscribe();
      });
  }

  openDialog_Vehiculos(): void {
    const dialogRef = this.dialog.open(DlgVehiculosComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
        permitir_recaudo: 1,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_vehiculos = result;
        this.frm.controls.vehiculo_id.patchValue(result[0].vehiculo_id);
        this.frm.controls.propietario_id.patchValue(result[0].propietario_id);
        this.frm.controls._numero.patchValue(result[0].numero);
        dialogSubmitSubscription.unsubscribe();
      });
  }
}
