import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlanillaParametrosComponent } from "./pages/planilla-parametros/planilla-parametros.component";
import { PlanillasComponent } from "./pages/planillas/planillas.component";
import { NewEditComponent } from "./pages/planilla-parametros/new-edit/new-edit.component";
import { PlanillasConsecutivosComponent } from "./pages/planillas-consecutivos/planillas-consecutivos.component";
import { PcNewEditComponent } from "./pages/planillas-consecutivos/new-edit/new-edit.component";
import { RutasDespachosComponent } from "./pages/rutas-despachos/rutas-despachos.component";
import { RutasDespachosNewEditComponent } from "./pages/rutas-despachos/new-edit/new-edit.component";
import { LugaresControlComponent } from "./pages/lugares-control/lugares-control.component";
import { SancionesComponent } from "./pages/sanciones/sanciones.component";
import { GeolocalizacionComponent } from "./pages/geolocalizacion/geolocalizacion/geolocalizacion.component";
import { AuthorizatedGuard } from "../auth/authorizated-guard";
import { ReportesDespachosComponent } from "./pages/reportes/reportes-despachos.component";
import { PlanillasAbonosSSComponent } from "./pages/planillas-abonos-ss/planillas-abonos-ss.component";
import { PlanillasAbonosSSListComponent } from "./pages/planillas-abonos-ss/planillas-abonos-ss-list.component";
import { PermissionGuard } from "../auth/permission.guard";
import { PageNotFoundComponent } from "../components/page-not-found/page-not-found.component";
import { RecorridoComponent } from "./pages/planillas/recorrido/recorrido.component";
import { PlNewEditComponent } from "./pages/planillas-liquidacion/new-edit/new-edit.component";
import { PrintPageComponent } from "./pages/print-page/print-page.component";
import { CardsRutasComponent } from "./pages/utils/cards-rutas/cards-rutas.component";
import { MonitorPlanillasComponent } from "./pages/monitor-planillas/monitor-planillas.component";
import { ResumenLiquidacionComponent } from "./pages/planillas/resumen-liquidacion/resumen-liquidacion.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "planilla-parametros",
        component: PlanillaParametrosComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "planilla-parametros-new-edit",
        component: NewEditComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "planillas-consecutivos",
        component: PlanillasConsecutivosComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "monitor-planillas",
        component: MonitorPlanillasComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["admin"],
        },
      },
      {
        path: "resumen-liquidacion-planilla",
        component: ResumenLiquidacionComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["admin"],
        },
      },
      {
        path: "planillas-consecutivos-new-edit",
        component: PcNewEditComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "planillas",
        // canLoad: [AuthorizatedGuard],
        canActivate: [AuthorizatedGuard],
        component: PlanillasComponent,
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "rutas-despachos",
        component: RutasDespachosComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "sanciones",
        component: SancionesComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "geolocalizacion",
        component: GeolocalizacionComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "lugares-control",
        component: LugaresControlComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "rutas-despachos-new-edit",
        component: RutasDespachosNewEditComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "reportes-despachos",
        component: ReportesDespachosComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "abonos-ss",
        component: PlanillasAbonosSSComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "consulta-abonos",
        component: PlanillasAbonosSSListComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "generar-ruta/:planilla_id/:planillarutadespacho_id",
        component: RecorridoComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
      {
        path: "liquidar-planilla/:planilla_id",
        component: PlNewEditComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },

      {
        path: "impresion",
        component: PrintPageComponent,
      },
      {
        path: "cards-rutas",
        component: CardsRutasComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["superusuario", "admin"],
        },
      },
      {
        path: "**",
        component: PageNotFoundComponent,
        canActivate: [AuthorizatedGuard],
        data: {
          role: ["despachador", "admin"],
        },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
})
export class DespachosRoutingModule {}
