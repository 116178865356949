import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parametros-cooperativa',
  templateUrl: './parametros-cooperativa.component.html',
  styleUrls: ['./parametros-cooperativa.component.css']
})
export class ParametrosCooperativaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
