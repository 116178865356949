import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDialog,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { AppState } from "../../../../app.reducers";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { RutaDespachoLugar } from "src/app/models/rutasdespachos-lugares.model";
import { RutasDespachosLugaresService } from "../services/rutasdespachos-lugares.service";
import { LugaresControlService } from "../../lugares-control/services/lugares-control.service";
import { DlgLugaresControlComponent } from "src/app/busquedas/dlg-lugares-control.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RutasDespachosLugaresNewEditComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;

  frm: FormGroup;
  loading: boolean = false;
  _userSession: any = "0";
  rutasdespachos: RutaDespachoLugar[] = [];
  isEdit: boolean = false;
  _model: RutaDespachoLugar;
  data_search_lugarescontrol: [] = [];

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private store: Store<AppState>,
    private _serviceModel: RutasDespachosLugaresService,
    private _serviceLugaresControl: LugaresControlService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      rutadespacho_id: [this._model.rutadespacho_id],
      lugarcontrol_id: [0],
      minuto: [0, Validators.required],
      orden: [0, Validators.required],
      _rutadespacho_nombre: [this._model.nombre],
      _lugarcontrol_nombre: ["", Validators.required],
    });

    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.rutadespacho_lugar_id;
      this.setForm(id);
    }
    this.getLugaresControl();
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    console.log("this._model", id);
    this._serviceModel.getById(id.toString()).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp;
      this.frm = this.fb.group({
        rutadespacho_lugar_id: [this._model.rutadespacho_lugar_id],
        rutadespacho_id: [this._model.rutadespacho_id, Validators.required],
        lugarcontrol_id: [this._model.lugarcontrol_id, Validators.required],
        minuto: [this._model.minuto, Validators.required],
        orden: [this._model.lugarcontrol_orden, Validators.required],
        _rutadespacho_nombre: [this._model.rutadespacho_nombre],
        _lugarcontrol_nombre: [this._model.lugarcontrol_nombre],
      });
      this.blockUI.stop();
    });
  }

  /*   getRutasDespachosLugares(): Observable<any> {
    return this.store.select((appState) => appState.rutasdespachos);
  }
 */
  getLugaresControl() {
    this._serviceLugaresControl.get_listar("1", "1000").subscribe((resp) => {});
  }

  openDialog_LugaresControl(): void {
    const dialogRef = this.dialog.open(DlgLugaresControlComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_lugarescontrol = result;
        this.frm.controls.lugarcontrol_id.patchValue(result[0].lugarcontrol_id);
        this.frm.controls._lugarcontrol_nombre.patchValue(result[0].nombre);
        dialogSubmitSubscription.unsubscribe();
      });
  }
}
