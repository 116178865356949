import { createAction, props } from "@ngrx/store";
import { PlanillaRutaDespacho } from "../../../../../models/planillas_rutasdespachos.model";

export const cargarPlanillaRutaDespacho = createAction(
  "[Planilla Ruta Despacho] Cargar Planilla Ruta Despacho",
  props<{ id: string }>()
);

export const cargarListaPlanillaRutaDespacho = createAction(
  "[Lista Planilla Ruta Despacho] Cargar Lista Planilla Ruta Despacho"
);

export const cargarPlanillaRutaDespachoSuccess = createAction(
  "[Planilla Ruta Despacho] Cargar Planilla Ruta Despacho Success",
  props<{ planillarutadespacho: PlanillaRutaDespacho }>()
);

export const cargarPlanillaRutaDespachoError = createAction(
  "[Planilla Ruta Despacho] Cargar Planilla Ruta Despacho Error",
  props<{ payload: any }>()
);
export const addPlanillaRutaDespacho = createAction(
  "[Planilla Ruta Despacho] Nuevo Planilla Ruta Despacho",
  props<{ planillarutadespacho: PlanillaRutaDespacho }>()
);

export const addPlanillaRutaDespachoSuccess = createAction(
  "[Planilla Ruta Despacho] Nuevo Planilla Ruta Despacho Success",
  props<{ planillarutadespacho: any }>()
);

export const addPlanillaRutaDespachoError = createAction(
  "[Planilla Ruta Despacho] Nuevo Planilla Ruta Despacho Error",
  props<{ payload: any }>()
);

export const updPlanillaRutaDespacho = createAction(
  "[Planilla Ruta Despacho] Update Planilla Ruta Despacho",
  props<{ planillarutadespacho: any }>()
);

export const updPlanillaRutaDespachoSuccess = createAction(
  "[Planilla Ruta Despacho] Update Planilla Ruta Despacho Success",
  props<{ planillarutadespacho: any }>()
);

export const updPlanillaRutaDespachoError = createAction(
  "[Planilla Ruta Despacho] Update Planilla Ruta Despacho Error",
  props<{ payload: any }>()
);
