import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BancosService } from "../../services/bancos.service";
import { Banco } from "../../models/bancos.model";

@Component({
  selector: "app-bancos-new",
  templateUrl: "./bancos-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [],
})
export class BancosNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  isLoading: boolean = false;
  frm: FormGroup;
  _model: Banco;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceBancos: BancosService
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.frm = this.fb.group({
      bancos_id: [0],
      razon_social: ["", Validators.required],
      nit: ["", Validators.required],
    });

    if (this.isEdit) {
      const id = this._model.banco_id;
      this.setForm(id);
    }
    this.blockUI.stop();
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceBancos.getbyid(id.toString()).subscribe((resp: any) => {
      this._model = resp.data[0];
      this.frm = this.fb.group({
        bancos_id: [this._model.banco_id],
        razon_social: [this._model.razon_social, Validators.required],
        nit: [this._model.nit, Validators.required],
      });
      this.blockUI.stop();
    });
  }

  change_nit(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls["nit"].setValue(value);
    }
  }
}
