import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersService } from './../services/users.service';

@Component({
  selector: 'app-auth-validator',
  templateUrl: './auth-validator.component.html',
  styles: []
})
export class AuthValidatorComponent {

  constructor() { }

  static existingEmailValidator(_serviceUsers: UsersService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        return _serviceUsers.emailExiste(control.value)
            .pipe(
                map((data: any) => {
                    return data.count ? { existingEmailValidator: true } : null;
                })
            );
    };
}

static gte(control: AbstractControl): 
         Observable<ValidationErrors> | null {
 
    const v: number = control.value;
 
    console.log(v);
    
    if (isNaN(v)) {
      return of({ 'gte': true, 'requiredValue': 10 });
    }      
 
    if (v <= 10) {
      return of({ 'gte': true, 'requiredValue': 10 });
    } 
 
    return of(null);
 
}

  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
     if (password !== confirmPassword) {
         AC.get('confirmPassword').setErrors( {MatchPassword: true} );
     } else {
         return null;
     }
 }

}
