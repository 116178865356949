import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillassancionesActions from "../../store/actions/planillas_sanciones.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillasSancionesService } from "../../services/planillas_sanciones.service";
import { of } from "rxjs";

@Injectable()
export class PlanillasSancionesEffects {
  constructor(
    private actions$: Actions,
    private planillassancionesService: PlanillasSancionesService
  ) {}

  cargarPlanillas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillassancionesActions.cargarPlanillasSanciones),
      mergeMap(() =>
        this.planillassancionesService.getListar(1, 10).pipe(
          map((resp) =>
            planillassancionesActions.cargarPlanillasSancionesSuccess({
              planillassanciones: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              planillassancionesActions.cargarPlanillasSancionesError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
