import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ReportesDespachosService } from "../services/reportes-despachos.service";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducers";
import { tap } from "rxjs/operators";
import { selectPlanillaParametro } from "../../planilla-parametros/store/selectors/planilla-parametro.selector";
import { Observable } from "rxjs";
@Component({
  selector: "app-rpt-abonos-ss",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "rpt-abonos-ss.component.html",
  styles: [],
})
export class RptAbonosSSComponent implements OnInit {
  @Input() data_rpt = [];
  @Input() chkBus = false;
  @Input() _bus = "";
  @Input() tituloReporte = "";
  @Input() tiporpt_id = 0;
  @Input() dias_calendario = 0;
  @Input() _valorSS: number = 0;
  fileName = "";
  FechaDesde = "";
  FechaHasta = "";
  Empresa = "";
  loading: boolean = false;
  _mes = "";
  _valorSSTotal_Esperado: number = 0;
  _totalAbonoSS: number = 0;

  constructor(
    private _serviceReportesDespachos: ReportesDespachosService,
    private store: Store<AppState>
  ) {
    this._serviceReportesDespachos.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportesDespachos.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });

    this._serviceReportesDespachos.mes$.subscribe((resp: any) => {
      this._mes = resp;
    });

    this._serviceReportesDespachos.bus$.subscribe((resp: any) => {
      this._bus = resp;
    });

    this._serviceReportesDespachos.diascalendario$.subscribe((resp: any) => {
      this.dias_calendario = resp;
      this._valorSSTotal_Esperado = this._valorSS * this.dias_calendario;
    });
    this._serviceReportesDespachos.totalabonosss$.subscribe((resp: any) => {
      this._totalAbonoSS = resp;
    });
  }

  ngOnInit() {}

  exportAsXLSX(): void {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `PRODUCIDOS_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY");

    this._serviceReportesDespachos.downloadFile_Producidos(
      this.data_rpt,
      filename,
      periodo
    );
  }

  get_Total_AbonoSS() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.abono_ss_conductor);
    });
    return total;
  }

  get_Total_PagadoSS() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.valor_ss_conductor);
    });
    // this._totalSSConductor = total;
    return total;
  }

  get_Total_AdicionalSS() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.adicional_aporte_ss);
    });
    return total;
  }

  getParametros() {
    // return this.store.select((appState) => appState.planillaparametros);
    console.log("getParametros");
    this.store.select(selectPlanillaParametro).pipe(
      tap((e: any) => {
        console.log("Parametros:", e.items);
        this.loading = e.loading;
      })
    );
  }
}
