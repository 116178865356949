import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";

import { map } from "rxjs/operators";
import { URL_API } from "../config/config";
import { LoginModel } from "../models/auth/login.model";
import { User } from "../models/auth/users.model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User> =
    new BehaviorSubject<User | null>(null);
  currentUser: Observable<User>;
  isAuthenticated: boolean = false;
  isLogueado$ = new EventEmitter<boolean>();
  empresa$ = new EventEmitter<any>();
  empresa2$ = new EventEmitter<any>();

  isLogin = false;
  roleAs: string;
  userRole: string;
  list_roles = [];

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; chrset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserObject(): User {
    return this.currentUserSubject.value;
  }

  login(loginModel: LoginModel) {
    sessionStorage.clear();
    let url = URL_API + "/users/login";
    return this.http.post<any>(url, loginModel, this.httpOptions).pipe(
      map((user) => {
        sessionStorage.setItem("currentUser", JSON.stringify(user.usuario));
        sessionStorage.setItem("token", user.token);
        sessionStorage.setItem("roles", JSON.stringify(user.roles));
        this.currentUserSubject.next(user.usuario);
        this.isAuthenticated = true;

        this.isLogin = true;
        return user;
      })
    );
  }

  logout() {
    sessionStorage.clear();
    this.isAuthenticated = false;
    this.currentUserSubject.next(null);
  }

  /* getExpiratoken() {
    const { DateTime } = require("luxon");
    const token: string | null = sessionStorage.getItem("token");
    if (token) {
      const expirationTimeInSecondsSinceEpoch = JSON.parse(
        atob(token!.split(".")[1])
      ).exp;
      let expira = new Date(0);

      expira.setUTCSeconds(expirationTimeInSecondsSinceEpoch);
      const hoy = new Date();
      hoy.setUTCSeconds;

      if (expira < hoy) {
        sessionStorage.clear();
        this.router.navigateByUrl("/login");
      }
    }
  } */

  refreshToken(token: string) {
    return this.http.post(
      URL_API + "/login/renuevatoken",
      {
        refreshToken: token,
      },
      this.httpOptions
    );
  }

  isLoggedIn() {
    //Ok
    const isUser = sessionStorage.getItem("currentUser") != null;
    const isToken = sessionStorage.getItem("token") != null;
    return isUser && isToken;
  }

  get userPermissions(): string[] {
    return JSON.parse(localStorage.getItem("userPermissions") || "[]");
  }

  hasPermission(requiredPermission: string): boolean {
    return this.userPermissions.includes(requiredPermission);
  }

  getPermisos(roles: any): boolean {
    const expectedRoles = roles;
    const session_roles = sessionStorage.getItem("roles");
    let arrayRoles = JSON.parse(session_roles);

    arrayRoles.map((r) => {
      this.list_roles.push(r.role_name);
    });

    if (this.list_roles.some((r) => expectedRoles.includes(r))) {
      return true;
    } else {
      return false;
    }
  }
}
