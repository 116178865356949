import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import Swal from "sweetalert2";
import { name_mes } from "../../../config/globals";
import { ReportesDespachosService } from "../reportes/services/reportes-despachos.service";
import { PlanillasAbonosSSService } from "./services/planillas-abonos-ss.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-planillas-abonos-ss-list",
  templateUrl: "./planillas-abonos-ss-list.component.html",
  styles: [],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PlanillasAbonosSSListComponent implements OnInit {
  data_rpt_abonos = [];
  data_totales = [];
  form: FormGroup;
  desde: string = "";
  hasta: string = "";
  tipos_rpt = [];
  cargando: boolean = false;
  btndisabled: boolean = false;
  xbus: string = "";
  xmes: string = "";

  constructor(
    private _servicePlanillasAbonosSS: PlanillasAbonosSSService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      fecha_desde: [new Date(), Validators.required],
      fecha_hasta: [new Date(), Validators.required],
      numero: ["152"],
    });
  }

  ngOnInit() {}

  get fdata() {
    return this.form.controls;
  }

  consultar() {
    this.desde = moment(this.form.controls.fecha_desde.value).format(
      "YYYY-MM-DD 00:00"
    );
    this.hasta = moment(this.form.controls.fecha_hasta.value).format(
      "YYYY-MM-DD 23:59"
    );

    this.xmes =
      name_mes[
        parseInt(moment(this.form.controls.fecha_desde.value).format("MM")) - 1
      ];

    this.data_rpt_abonos = [];
    this._servicePlanillasAbonosSS.FechaDesde$.emit(this.desde);
    this._servicePlanillasAbonosSS.FechaHasta$.emit(this.hasta);

    if (this.form.controls.numero.value === "") {
      Swal.fire("Precaución", "Número de Vehículo no válido...", "warning");
      return false;
    } else {
      this.xbus = this.form.controls.numero.value;
      this._servicePlanillasAbonosSS.mes$.emit(this.xmes);
      this._servicePlanillasAbonosSS.bus$.emit(this.xbus);
      this._servicePlanillasAbonosSS
        .get_list_abonosbybus(
          "1",
          "10",
          this.desde,
          this.hasta,
          this.form.controls.numero.value
        )
        .subscribe((resp: any) => {
          this.data_rpt_abonos = resp.data;
          if (resp.data.length === 0) {
            Swal.fire(
              "Atención",
              "No se encontraron datos para esta consulta...",
              "info"
            );
          }
        });
    }
  }

  change_bus(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.form.controls["numero"].setValue(value);
    }
  }
}
