import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { Sancion } from "src/app/models/sanciones.model";
import { AppState } from "../../../app.reducers";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  cargarSanciones,
  cargarSancionesByQuery,
} from "../sanciones/store/actions";
import { FormBuilder, FormGroup } from "@angular/forms";
import pageSettings from "src/app/config/page-settings";
import { SancionesNewEditComponent } from "./new-edit/new-edit.component";
import { SancionesService } from "./services/sanciones.service";
import Swal from "sweetalert2";
import { PaginadorxService } from "../paginadorx/paginadorx.service";
import { selectSanciones } from "./store/selectors/sanciones.selectors";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-sanciones",
  templateUrl: "./sanciones.component.html",
  styleUrls: ["./sanciones.component.css"],
})
export class SancionesComponent implements OnInit, AfterViewChecked {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 375;
  pageSettings = pageSettings;
  buscando: boolean = false;

  arrayModel: Sancion[] = [];
  loading: boolean = false;
  error: any;
  ppSubs: Subscription;
  frmBuscar: FormGroup;
  _sancion_id: number;
  data: Observable<any> = new Observable();

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _serviceModel: SancionesService,
    private _servicePaginador: PaginadorxService,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });

    this.blockUI.start("Consultando datos. Espere un momento...");
    await this.listar("1", "10");
  }

  async listar(pagina: string, limit: string) {
    this.store.dispatch(cargarSanciones({ pagina, limit }));
    this.store
      .select(selectSanciones)
      .pipe(
        tap((e: any) => {
          this.arrayModel = e.items;
          this.loading = e.loading;
          this._servicePaginador.pagex$.emit(pagina);
          this._servicePaginador.registrosx$.emit(e.registros);
          this.blockUI.stop();
        })
      )
      .subscribe();
  }

  new(model: Sancion) {
    const ref = this.modalService.open(SancionesNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceModel.create(data.frm).subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.listar("1", "10");
            } else {
              Swal.fire("Atención", resp.msg, "error");
            }
          });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  receiveMessage($event: any) {
    if (this.frmBuscar.controls._txtBuscar.value.length === 0) {
      this.listar($event.pagina, $event.recxpag);
    } else {
      const value = this.frmBuscar.controls._txtBuscar.value;
      this.getBuscar($event.pagina, $event.recxpag, value);
    }
  }

  async getBuscar(pagina: string, limit: string, buscar: string) {
    if (buscar.trim().length > 0) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      this.buscando = true;

      this.store.dispatch(
        cargarSancionesByQuery({
          pagina,
          limit,
          query: this.frmBuscar.controls["_txtBuscar"].value,
        })
      );
    } else {
      this.listar("1", "10");
    }
  }

  edit(model: Sancion) {
    const ref = this.modalService.open(SancionesNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._sancion_id = model.sancion_id;
    ref.result.then(
      (data) => {
        console.log(data.frm);
        if (data.valido) {
          this._serviceModel
            .update(model.sancion_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.listar("1", "10");
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: any) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: "<p><b>Sanción:</b> " + model.descripcion + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceModel
          .getdelete(model.sancion_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.listar("1", "10");
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
