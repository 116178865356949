import { Component } from "@angular/core";

@Component({
  selector: "app-block-temp",
  styles: [
    `
      :host {
        text-align: center;
        color: #ffffff;
        background: #ffc300;
      }
    `,
  ],
  template: `
    <div class="block-ui-template">
      <i class="fa fa-spin fa-spinner fa-2x" aria-hidden="true"></i>
      <div>
        <strong>{{ message }}</strong>
      </div>
    </div>
  `,
})
export class BlockTemplateComponent {
  message: any;
}
