import { createAction, props } from "@ngrx/store";
import { RutaDespacho } from "../../../../../models/rutas-despachos.model";

export const cargarRutasDespachos = createAction(
  "[Rutas Despachos] Cargar Rutas",
  props<{ pagina: string; limit: string }>()
);

export const cargarRutasDespachosSuccess = createAction(
  "[Rutas Despachos] Cargar Rutas Success",
  props<{
    rutasdespachos: RutaDespacho[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarRutasDespachosError = createAction(
  "[Rutas Despachos] Cargar Rutas Error",
  props<{ payload: any }>()
);

export const cargarRutasDespachosByQuery = createAction(
  "[RutasDespachos] Cargar RutasDespachos Query",
  props<{ pagina: string; limit: string; query: string }>()
);

export const cargarRutasDespachosByQuerySuccess = createAction(
  "[RutasDespachos] Cargar RutasDespachos Query Success",
  props<{
    rutasdespachos: RutaDespacho[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarRutasDespachosByQueryError = createAction(
  "[RutasDespachos] Cargar RutasDespachos Query Error",
  props<{ payload: any }>()
);
