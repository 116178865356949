import { Component, OnInit } from "@angular/core";
import { GeoLocationService } from "../service/geolocalizacion.service";
import { SocketService } from "src/app/services/socket.service";
import { MapsAPILoader } from "@agm/core";
import { GoogleMap } from "@angular/google-maps";

@Component({
  selector: "app-geolocalizacion",
  templateUrl: "./geolocalizacion.component.html",
  styleUrls: ["./geolocalizacion.component.css"],
})
export class GeolocalizacionComponent implements OnInit {
  latitude: number;
  longitude: number;

  lat: number;
  lng: number;
  zoom: number = 10;
  origin: any;
  destination: any;

  constructor(
    private geoLocationService: GeoLocationService,
    private socketService: SocketService,
    private mapsAPILoader: MapsAPILoader
  ) {
    /* this.socketService.getMessage().subscribe((message: any) => {
      console.log(message);
    }); */
  }

  ngOnInit() {
    this.geoLocationService.getLocation().subscribe(
      (position: any) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
  }

  sendMessage(): void {
    // this.socketService.sendMessage("Hola desde Angular");
  }

  getRoute() {
    this.mapsAPILoader.load().then(() => {
      let directionsService = new google.maps.DirectionsService();
      let request = {
        origin: { lat: 35.6594945, lng: 139.6999859 },
        destination: { lat: 35.6784876, lng: 139.7390168 },
        travelMode: google.maps.TravelMode.DRIVING,
      };
      directionsService.route(request, function (result, status) {
        if (status === "OK") {
          console.log(request);
        }
      });
    });
  }
}
