import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { RolesService } from "../../services/roles.service";
import pageSettings from "../../config/page-settings";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Role } from "../../models/auth/roles.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RolesNewComponent } from "./roles-new.component";
import Swal from "sweetalert2";
import { UsersRolesService } from "src/app/services/users-roles.service";

@Component({
  selector: "app-list-roles",
  templateUrl: "./list-roles.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .modal-xl {
        max-width: 460px;
      }
      #panelAsignados > .panel.panel-inverse > .panel-heading {
        background: #395aa7;
        color: #fff;
      }
      #panelSinAsignar > .panel.panel-inverse > .panel-heading {
        background: #af2626;
        color: #fff;
      }
    `,
  ],
})
export class ListRolesComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  @Input() datax = [];
  @Input() data_sinasignar = [];
  @Input() userName = "";
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  pageSettings = pageSettings;
  items = [1, 2, 3, 4];
  buscando: boolean = false;
  frmBuscar: FormGroup;

  constructor(
    private _serviceRoles: RolesService,
    private _serviceRolesUsers: UsersRolesService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {
    this.pageSettings.pageWithoutSidebar = false;
    this.pageSettings.pageTopMenu = false;
  }

  ngOnInit() {
    this.buscando = false;
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
  }

  new(model: Role) {
    const ref = this.modalService.open(RolesNewComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceRoles.create(data.frm).subscribe((resp: any) => {
            if (resp.status === "ok") {
              // this.getLista("1", "10");
            } else {
              Swal.fire("Atención", resp.msg, "error");
            }
          });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  quitar_rol(row: any) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: "<p><b>Rol:</b> " + row.role_description + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceRolesUsers
          .getdelete(row.userrole_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.get_roles(row);
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  asignar_rol(row: any) {
    console.log("asignar_rol", row);
    Swal.fire({
      title: '<span style="font-size: 20px;"> Seguro de agregar el Rol?</span>',
      html: "<p><b>Rol:</b> " + row.role_description + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceRolesUsers
          .create({ user_id: row.user_id, role_id: row.role_id })
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.get_roles(row);
              Swal.fire("Información", "El Rol ha sido agregado...", "success");
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }
  Buscar() {}

  get_roles(row: any) {
    console.log("row", row);
    this._serviceRoles.getRolesByUserId(row.user_id).subscribe((resp: any) => {
      this.datax = resp["data"];
    });

    this._serviceRoles
      .getRolesSinAsignarByUserId(row.user_id)
      .subscribe((resp: any) => {
        this.data_sinasignar = resp["data"];
      });
  }
}
