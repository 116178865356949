import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GruposService } from "../../services/grupos.service";
import { Grupo } from "../../models/grupos.model";

@Component({
  selector: "app-grupos-new",
  templateUrl: "./grupos-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
})
export class GruposNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  isLoading: boolean = false;
  frm: FormGroup;
  _model: Grupo;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceGrupos: GruposService
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.frm = this.fb.group({
      grupo_id: [0],
      nombre: ["", Validators.required],
    });

    if (this.isEdit) {
      const id = this._model.grupo_id;
      this.setForm(id);
    }
    this.blockUI.stop();
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceGrupos.getbyid(id.toString()).subscribe((resp: any) => {
      this._model = resp.data[0];
      this.frm = this.fb.group({
        grupo_id: [this._model.grupo_id],
        nombre: [this._model.nombre, Validators.required],
      });
      this.blockUI.stop();
    });
  }
}
