import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-print-page",
  templateUrl: "./print-page.component.html",
  styleUrls: ["./print-page.component.css"],
})
export class PrintPageComponent implements OnInit {
  data = [
    { id: 1, name: "Item 1" },
    { id: 2, name: "Item 2" },
  ]; // Tus datos aquí

  print(): void {
    window.print();
  }

  constructor() {}

  ngOnInit() {}
}
