import { createReducer, on } from "@ngrx/store";
import { cargarUser, cargarUserError, cargarUserSuccess } from "../actions";
import { User } from "../../models/auth/users.model";

export interface UserState {
  id: string;
  user: User;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const userInitialState: UserState = {
  id: null,
  user: null,
  loaded: false,
  loading: false,
  error: null,
};

const _userReducer = createReducer(
  userInitialState,

  on(cargarUser, (state, { id }) => ({
    ...state,
    loading: true,
    id: id,
  })),

  on(cargarUserSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    loaded: true,
    user: { ...user },
  })),

  on(cargarUserError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function userReducer(state, action) {
  return _userReducer(state, action);
}
