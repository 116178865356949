import { AppState } from "src/app/app.reducers";
import { AniosState } from "../reducers/anios.reducer";
import { createSelector } from "@ngrx/store";

export const selectAnios = (state: AppState) => state.anios;

export const selectListAnios = createSelector(
  selectAnios,
  (state: AniosState) => state.items
);
export const selectLoadingAnios = createSelector(
  selectAnios,
  (state: AniosState) => state.loading
);
export const selectFailAnios = createSelector(
  selectAnios,
  (state: AniosState) => state.error
);
