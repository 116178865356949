import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "../services/users.service";
import { User } from "../models/auth/users.model";
import { ConfirmedValidator } from "../validators/form-validators";

@Component({
  selector: "app-users-change-password",
  templateUrl: "./users-change-password.component.html",
  styles: [],
})
export class UsersChangePasswordComponent implements OnInit {
  isLoading: boolean = false;
  frm: FormGroup;
  _model: User;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceUsers: UsersService
  ) {}

  ngOnInit() {
    this.frm = this.fb.group({
      user_id: [0],
      nombre: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      activo: [true],
      password: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]],
    });

    if (this.isEdit) {
      const id = this._model.user_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceUsers.getbyid(id.toString()).subscribe((resp: any) => {
      this._model = resp.data[0];
      this.frm = this.fb.group(
        {
          user_id: [this._model.user_id],
          nombre: [this._model.nombre, Validators.required],
          password: [""],
          confirmPassword: ["", Validators.required],
          email: [this._model.email, [Validators.required, Validators.email]],
          activo: [this._model.activo],
        },
        {
          validator: ConfirmedValidator("password", "confirmPassword"),
        }
      );
    });
  }

  change_chkactivo() {}
}
