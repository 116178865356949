import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { AppState } from "../../../../app.reducers";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Anio } from "src/app/models/anios.model";
import { PlanillaParametro } from "src/app/models/planilla-parametro.model";
import { PlanillaParametrosService } from "../services/planilla-parametros.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class NewEditComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  frm: FormGroup;
  loading: boolean = false;
  _userSession: any = "0";
  _numero = "";
  anios: Anio[] = [];
  isEdit: boolean = false;
  _model: PlanillaParametro;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private store: Store<AppState>,
    private _serviceModel: PlanillaParametrosService
  ) {}

  ngOnInit() {
    this.getAnios().subscribe((datos) => {
      this.anios = datos.items;
    });

    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      planillaparametro_id: [0],
      anio_id: [6, Validators.required],
      valor_ss_conductor: [0, Validators.required],
      valor_ss_relevante: [0, Validators.required],
      valor_planilla: [0, Validators.required],
      valor_gps: [0, Validators.required],
      valor_fondo_contingencia: [0, Validators.required],
      valor_vias: [0, Validators.required],
      valor_minuto_retardo: [0, Validators.required],
      valor_bioseguridad: [0, Validators.required],
      valor_aporte_cooperativa: [0, Validators.required],
      observacion: [""],
    });
    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.planillaparametro_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceModel.getById(id.toString()).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp;

      this.frm = this.fb.group({
        planillaparametro_id: [this._model.planillaparametro_id],
        anio_id: [this._model.anio_id],
        valor_ss_conductor: new FormControl(this._model.valor_ss_conductor, [
          Validators.required,
        ]),
        valor_ss_relevante: [
          this._model.valor_ss_relevante,
          Validators.required,
        ],
        valor_planilla: [this._model.valor_planilla, Validators.required],
        valor_gps: [this._model.valor_gps, Validators.required],
        valor_fondo_contingencia: [
          this._model.valor_fondo_contingencia,
          Validators.required,
        ],
        valor_vias: [this._model.valor_vias, Validators.required],
        valor_minuto_retardo: [
          this._model.valor_minuto_retardo,
          Validators.required,
        ],
        valor_bioseguridad: [
          this._model.valor_bioseguridad,
          Validators.required,
        ],
        valor_aporte_cooperativa: [
          this._model.valor_aporte_cooperativa,
          Validators.required,
        ],
        observacion: [this._model.observacion],
      });
      this.blockUI.stop();
    });
  }

  getAnios(): Observable<any> {
    return this.store.select((appState) => appState.anios);
    // .pipe(filter(Boolean))
  }

  solo_numeros(value: string, ctrl: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls[ctrl].setValue(value);
    }
  }
}
