import { createReducer, on } from "@ngrx/store";
import {
  cargarPlanillas,
  cargarPlanillasByQuery,
  cargarPlanillasByQueryError,
  cargarPlanillasByQueryPeriodo,
  cargarPlanillasByQueryPeriodoError,
  cargarPlanillasByQueryPeriodoSuccess,
  cargarPlanillasByQuerySuccess,
  cargarPlanillasError,
  cargarPlanillasMasterByPlanilla_id,
  cargarPlanillasMasterByPlanilla_idError,
  cargarPlanillasMasterByPlanilla_idSuccess,
  cargarPlanillasRegistradas,
  cargarPlanillasRegistradasByPeriodo,
  cargarPlanillasRegistradasByPeriodoError,
  cargarPlanillasRegistradasByPeriodoSuccess,
  cargarPlanillasRegistradasByUser_id,
  cargarPlanillasRegistradasByUser_idError,
  cargarPlanillasRegistradasByUser_idSuccess,
  cargarPlanillasRegistradasByUserPeriodo,
  cargarPlanillasRegistradasByUserPeriodoError,
  cargarPlanillasRegistradasByUserPeriodoSuccess,
  cargarPlanillasRegistradasError,
  cargarPlanillasRegistradasSuccess,
  cargarPlanillasSuccess,
} from "../actions";
import { Planilla } from "../../../../../models/planillas.model";

export interface PlanillasState {
  items: Planilla[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const planillasInitialState: PlanillasState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _planillasReducer = createReducer(
  planillasInitialState,

  on(cargarPlanillasRegistradas, (state) => ({ ...state, loading: true })),
  on(
    cargarPlanillasRegistradasSuccess,
    (state, { planillas, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillas],
      registros: registros,
      pagecount: pagecount,
    })
  ),
  on(cargarPlanillasRegistradasError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(cargarPlanillasRegistradasByPeriodo, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    cargarPlanillasRegistradasByPeriodoSuccess,
    (state, { planillas, registros }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillas],
      registros: registros,
    })
  ),
  on(cargarPlanillasRegistradasByPeriodoError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(cargarPlanillas, (state) => ({ ...state, loading: true })),
  on(cargarPlanillasSuccess, (state, { planillas, registros, pagecount }) => ({
    ...state,
    loading: false,
    loaded: true,
    items: [...planillas],
    registros: registros,
    pagecount: pagecount,
  })),
  on(cargarPlanillasError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(cargarPlanillasByQuery, (state) => ({ ...state, loading: true })),
  on(
    cargarPlanillasByQuerySuccess,
    (state, { planillas, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillas],
      registros: registros,
      pagecount: pagecount,
    })
  ),
  on(cargarPlanillasByQueryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(cargarPlanillasByQueryPeriodo, (state) => ({ ...state, loading: true })),
  on(
    cargarPlanillasByQueryPeriodoSuccess,
    (state, { planillas, registros }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillas],
      registros: registros,
    })
  ),
  on(cargarPlanillasByQueryPeriodoError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(cargarPlanillasRegistradasByUser_id, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    cargarPlanillasRegistradasByUser_idSuccess,
    (state, { planillas, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillas],
      registros: registros,
      pagecount: pagecount,
    })
  ),
  on(cargarPlanillasRegistradasByUser_idError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(cargarPlanillasRegistradasByUserPeriodo, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    cargarPlanillasRegistradasByUserPeriodoSuccess,
    (state, { planillas, registros }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillas],
      registros: registros,
    })
  ),
  on(cargarPlanillasRegistradasByUserPeriodoError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function planillasReducer(state, action) {
  return _planillasReducer(state, action);
}
