import { createReducer, on } from "@ngrx/store";
import {
  cargarSanciones,
  cargarSancionesByQuery,
  cargarSancionesByQueryError,
  cargarSancionesByQuerySuccess,
  cargarSancionesError,
  cargarSancionesSuccess,
} from "../actions";
import { Sancion } from "../../../../../models/sanciones.model";

export interface SancionesState {
  items: Sancion[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const sancionesInitialState: SancionesState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _sancionesReducer = createReducer(
  sancionesInitialState,

  on(cargarSanciones, (state) => ({ ...state, loading: true })),

  on(cargarSancionesSuccess, (state, { sanciones, registros, pagecount }) => ({
    ...state,
    loading: false,
    loaded: true,
    items: [...sanciones],
    registros: registros,
    pagecount: pagecount,
  })),

  on(cargarSancionesError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(cargarSancionesByQuery, (state) => ({ ...state, loading: true })),
  on(
    cargarSancionesByQuerySuccess,
    (state, { sanciones, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...sanciones],
      registros: registros,
      pagecount: pagecount,
    })
  ),
  on(cargarSancionesByQueryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function sancionesReducer(state, action) {
  return _sancionesReducer(state, action);
}
