import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, CanActivate, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PlanillasService } from "../services/planillas.service";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RutasDespachosService } from "../../rutas-despachos/services/rutas-despachos.service";
import * as moment from "moment";
import pageSettings from "../../../../config/page-settings";
import { PlanillasLiquidacionService } from "../../planillas-liquidacion/services/planillas-liquidacion.service";
import { MensajesToastrService } from "src/app/mensajes/toastr-service";

@Component({
  selector: "app-recorrido",
  templateUrl: "./recorrido.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
      mat-form-field {
        width: 100%;
      }
      div.matfila {
        max-width: 70px !important;
      }
      .material-icons {
        vertical-align: middle;
      }
      table.tableliquidacion {
        font-size: 16px;
      }
      td.tdtitulo {
        font-weight: 600;
      }
      h4.panel-title.ng-star-inserted {
        font-size: 14px !important;
      }
      .panel.panel-heading.panel-title {
        font-size: 14px;
      }
      div.panel-body.ng-star-inserted {
        margin-bottom: 100px !important;
      }
    `,
  ],
})
export class RecorridoComponent implements OnInit {
  sub: Subscription;
  frmBuscar: FormGroup;
  datos: any = [];
  rutadespacho: any = [];
  fields: any;
  pageSettings = pageSettings;
  indexSelected = 0;
  _fila: number = 1;
  _planillarutadespacho_id: number;

  frmRuta = this.fb.group({
    frmArrayLugares: this.fb.array([]),
  });

  constructor(
    private route: ActivatedRoute,
    private _serviceModel: PlanillasService,
    private _serviceRutas: RutasDespachosService,
    private _serviceLiquidacion: PlanillasLiquidacionService,
    private _serviceToastr: MensajesToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.pageSettings.pageWithoutSidebar = false;
    this.pageSettings.pageTopMenu = false;
  }

  ngOnInit() {
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    this.sub = this.route.params.subscribe((params) => {
      console.log("params", params);
      this._planillarutadespacho_id = params.planillarutadespacho_id;
      this._serviceModel
        .getByplanillarutadespacho_id(params.planillarutadespacho_id)
        .subscribe((resp) => {
          this.datos = resp;
          console.log("/planillas/getByplanillarutadespacho_id/ ", resp);
        });
    });
  }

  generar_recorrido() {
    this.rutadespacho = [];
    this.eliminarItemsFormArray();
    this._fila = 1;
    this._serviceRutas
      .getRutaDespacho(
        this.datos.planilla_id,
        this._planillarutadespacho_id.toString()
      )
      .subscribe((resp) => {
        console.log(this.datos.fechahora_salida);
        this.rutadespacho = resp;
        let _tiempo: Date = new Date(this.datos.fechahora_salida);
        this.rutadespacho.forEach((x) => {
          _tiempo = new Date(_tiempo.getTime() + x.minuto * 60000);
          this.addLugar(
            x.lugarcontrol_nombre,
            x.minuto,
            x.minutos_retardo_sancion,
            x.valor_minuto_sancion,
            _tiempo,
            x.lugarcontrol_id,
            this._planillarutadespacho_id
          );
        });
      });
  }

  Buscar() {}

  submit(frm: any) {
    for (
      let index = 0;
      index < this.frmRuta.get("frmArrayLugares")["controls"].length;
      index++
    ) {
      this._serviceLiquidacion
        .create(
          (<FormArray>this.frmRuta.get("frmArrayLugares")).at(index).value
        )
        .subscribe((resp: any) => {
          if (resp.status === "ok") {
            this._serviceToastr.showSuccess(
              "Recorrido generado correctamente..."
            );
          } else {
            this._serviceToastr.showError(resp.msg);
          }
        });
    }
    console.log(this.frmRuta.value);
  }

  get lugarescontrol() {
    return this.frmRuta.controls["frmArrayLugares"] as FormArray;
  }

  addLugar(
    lugarcontrol: string,
    minuto: number,
    minutos_retardo_sancion: number,
    valor_minuto_sancion: number,
    tiempo_paso: Date,
    lugarcontrol_id: number,
    planillarutadespacho_id
  ) {
    const frm = this.fb.group({
      fila: [this._fila + "."],
      planillarutadespacho_id: [planillarutadespacho_id, Validators.required],
      lugarcontrol_id: [lugarcontrol_id, Validators.required],
      minuto: [minuto, Validators.required],
      minutos_retardo_sancion: [minutos_retardo_sancion, Validators.required],
      valor_minuto_sancion: [valor_minuto_sancion, Validators.required],
      tiempo_paso: [
        moment(tiempo_paso).format("YYYY-MM-DD HH:mm"),
        Validators.required,
      ],
      valor_ss_conductor: [this.datos.valor_ss_conductor, Validators.required],
      valor_ss_relevante: [this.datos.valor_ss_relevante, Validators.required],
      valor_planilla: [this.datos.valor_planilla, Validators.required],
      valor_gps: [this.datos.valor_gps, Validators.required],
      valor_fondo_contingencia: [
        this.datos.valor_fondo_contingencia,
        Validators.required,
      ],
      valor_vias: [this.datos.valor_vias, Validators.required],
      valor_minuto_retardo: [
        this.datos.valor_minuto_retardo,
        Validators.required,
      ],
      valor_bioseguridad: [this.datos.valor_bioseguridad, Validators.required],
      valor_aporte_cooperativa: [
        this.datos.valor_aporte_cooperativa,
        Validators.required,
      ],

      _lugar: [lugarcontrol, Validators.required],
      _tiempo_paso: [moment(tiempo_paso).format("HH:mm"), Validators.required],
    });
    this.lugarescontrol.push(frm);
    this._fila++;
  }

  eliminarItemsFormArray() {
    const itemsArray = this.frmRuta.controls["frmArrayLugares"] as FormArray;

    if (itemsArray.length > 0) {
      itemsArray.clear();
    }
  }

  go_planillas() {
    this.router.navigate(["/despachos/planillas"]);
  }
}
