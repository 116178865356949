import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Store } from "@ngrx/store";
import { forkJoin, of, Subscription, throwError } from "rxjs";
import { Planilla } from "src/app/models/planillas.model";
import { AppState } from "../../../app.reducers";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  cargarPlanillasRegistradasByPeriodo,
  cargarPlanillasRegistradasByUserPeriodo,
} from "../planillas/store/actions";
import { cargarAnios } from "../anios/store/actions";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import pageSettings from "src/app/config/page-settings";
import { PlanillaNewEditComponent } from "./new-edit/new-edit.component";
import { PlanillasService } from "./services/planillas.service";
import Swal from "sweetalert2";
import { Anio } from "src/app/models/anios.model";
import { ActivatedRoute, Router } from "@angular/router";
import { PlanillaRutaDespachoNewEditComponent } from "../planillas-rutasdespachos/new-edit/new-edit.component";
import { PlanillaRutasDespachosService } from "../planillas-rutasdespachos/services/planillas_rutasdespachos.service";
import { PlanillaRutaDespacho } from "src/app/models/planillas_rutasdespachos.model";
import { LugarControl } from "src/app/models/lugares-control.model";
import { RutasDespachosLugaresService } from "../rutasdespachos-lugares/services/rutasdespachos-lugares.service";
import { RutaDespachoLugar } from "src/app/models/rutasdespachos-lugares.model";
import { catchError, delay, switchMap, tap } from "rxjs/operators";
import { selectPlanillas } from "./store/selectors/planillas.selectors";
import { selectAnios } from "../anios/store/selectors/anios.selectors";
import * as moment from "moment";
import { PlanillaRecorridoComponent } from "./planilla-recorrido/planilla-recorrido.component";
import { PlanillasLiquidacionService } from "../planillas-liquidacion/services/planillas-liquidacion.service";
import { PlanillaConceptosPagarComponent } from "./planilla-conceptos-pagar/planilla-conceptos-pagar.component";
import { selectEmpresa } from "src/app/pages/empresa/store/selectors/empresa.selector";
import { Empresa } from "src/app/models/empresa.model";
import { AuthService } from "src/app/services/auth.service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatMenuTrigger,
} from "@angular/material";
import { DlgPlanillasComponent } from "src/app/busquedas/dlg-planillas.component";
import { QrcodeService } from "src/app/qrcode/qrcode.service";
import { FileuploadService } from "src/app/uploadfile/fileupload.service";
import { ItCloudSmsService } from "src/app/sms/services/it-cloud-sms.service";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MensajesToastrService } from "src/app/mensajes/toastr-service";
import { PlanillaLiquidacion } from "src/app/models/planillas-liquidacion.model";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-planillas",
  templateUrl: "./planillas.component.html",
  styleUrls: ["./planillas.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PlanillasComponent implements OnInit, AfterViewChecked, OnDestroy {
  // @BlockUI("data-section") blockUI!: NgBlockUI;
  // @HostListener("document:click", ["$event"])

  @ViewChild(MatMenuTrigger, { static: false }) contextMenu: MatMenuTrigger;

  @ViewChild("bus", { static: false }) numeroElement: ElementRef;

  blockTemplate!: BlockTemplateComponent;
  planillasSubscription: Subscription = new Subscription();
  heightSection: number = 575;
  pageSettings = pageSettings;
  buscando: boolean = false;
  buscaRutas: boolean = false;
  buscaLugares: boolean = false;

  arrayModel: Planilla[] = [];
  arrayRutasDespachos: PlanillaRutaDespacho[] = [];
  arrayLugaresControl: LugarControl[] = [];
  anios: Anio[] = [];
  loading: boolean = false;
  error: any;
  frmBuscar: FormGroup;
  frmSearch: FormGroup;

  _planilla_id: number;
  _rutadespacho_nombre: string = "";
  _numero: number = 0;
  _consecutivo: number = 0;
  _fecha_planilla: string;
  _userSession: any = "0";
  _despacho_nombre: string = "";
  data_empresa: Empresa;
  _user_id: number = 0;
  _userSessionRoles: any = "0";
  list_roles = [];
  isMostrarTodasPlanillas: boolean = false;
  desde: string = "";
  hasta: string = "";
  _gps: string;

  list_limit = [
    { value: 20, name: "20" },
    { value: 50, name: "50" },
    { value: 100, name: "100" },
    { value: 200, name: "200" },
    { value: 500, name: "500" },
  ];
  _limit: number = 20;

  frmContextual: FormGroup;
  contextMenuVisible = false;
  contextMenuPosition = { x: "0px", y: "0px" };
  items = [
    { id: 1, name: "Item 1" },
    { id: 2, name: "Item 2" },
    { id: 3, name: "Item 3" },
  ];

  urlQrCode: string;
  pid: string;
  _celphone: string;

  PlanillaLiquidacion: PlanillaLiquidacion;
  min: number = 0;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _serviceModel: PlanillasService,
    private _servicePlanillasRutasDespachos: PlanillaRutasDespachosService,
    private _servicePlanillaLiquidacion: PlanillasLiquidacionService,
    private _serviceRdl: RutasDespachosLugaresService,
    private _serviceAuth: AuthService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private qrCodeService: QrcodeService,
    private fileUploadService: FileuploadService,
    private icloudService: ItCloudSmsService,
    private route: ActivatedRoute,
    private _serviceToastr: MensajesToastrService
  ) {
    const fdesde = this.route.snapshot.queryParams["fdesde"];
    const fhasta = this.route.snapshot.queryParams["fhasta"];

    this.frmSearch = this.fb.group({
      fecha_desde: [moment(fdesde).format("YYYY-MM-DD"), Validators.required],
      fecha_hasta: [moment(fhasta).format("YYYY-MM-DD"), Validators.required],
      tiporeportedespacho_id: [1, Validators.required],
      limit: [20],
      numero: [""],
      chkvehiculo: [false],
      chkempresa: [false],
      vehiculo_id: [0],
      despacho_id: [1, Validators.required],
    });
  }

  async ngOnInit() {
    const fdesde = this.route.snapshot.queryParams["fdesde"];
    const fhasta = this.route.snapshot.queryParams["fhasta"];
    const term = this.route.snapshot.queryParams["term"];

    this.desde = moment(this.frmSearch.controls.fecha_desde.value).format(
      "YYYY-MM-DD 00:00"
    );
    this.hasta = moment(this.frmSearch.controls.fecha_hasta.value).format(
      "YYYY-MM-DD 23:59"
    );

    if (fdesde && fhasta) {
      this.desde = moment(fdesde).format("YYYY-MM-DD 00:00");
      this.hasta = moment(fhasta).format("YYYY-MM-DD 23:59");
    }
    this.frmContextual = this.fb.group({
      buscar: [""],
    });

    const expectedRoles = ["todas_planillas"];

    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));

    this.isMostrarTodasPlanillas = this._serviceAuth.getPermisos(expectedRoles);

    this._despacho_nombre = this._userSession.despacho_nombre;
    this._user_id = this._userSession.user_id;
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });

    await this.seleccion_query();
    await this.listar_anios();
  }

  //Refactorizado con promesa 04112024
  listar_registradasbyperiodo(desde: string, hasta: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._serviceModel
        .getListar_Registradasbyperiodo(desde, hasta, this._limit)
        .subscribe(
          (resp: any) => {
            this.arrayModel = resp["data"];
            resolve(); // Resuelve la promesa cuando se complete la respuesta
          },
          (error) => {
            reject(error); // Rechaza la promesa en caso de error
          }
        );
    });
  }

  /* listar_registradasbyperiodo(desde: string, hasta: string) {
    // this.blockUI.start("Consultando datos. Espere un momento...");
    this.arrayLugaresControl = [];
    this.buscaRutas = false;
    this.buscaLugares = false;

    this._serviceModel
      .getListar_Registradasbyperiodo(desde, hasta, this._limit)
      .subscribe((resp: any) => {
        this.arrayModel = resp["data"];
      });
  } */

  //Refactorizado con promesa 04112024
  listar_registradasbyuserperiodo(
    desde: string,
    hasta: string,
    limit: string,
    user_id: number
  ): Promise<void> {
    // this.blockUI.start("Consultando datos. Espere un momento...");
    this.arrayLugaresControl = [];
    this.buscaRutas = false;
    this.buscaLugares = false;
    return new Promise<void>((resolve, reject) => {
      this._serviceModel
        .getListar_RegistradasByUserPeriodo(desde, hasta, limit, user_id)
        .subscribe(
          (resp: any) => {
            this.arrayModel = resp["data"];
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  /* enProduccion 04112024  listar_registradasbyuserperiodo(
    desde: string,
    hasta: string,
    limit: string,
    user_id: number
  ) {
    // this.blockUI.start("Consultando datos. Espere un momento...");
    this.arrayLugaresControl = [];
    this.buscaRutas = false;
    this.buscaLugares = false;

    this._serviceModel
      .getListar_RegistradasByUserPeriodo(desde, hasta, limit, user_id)
      .subscribe((resp: any) => {
        this.arrayModel = resp["data"];
      });
  } */

  async listar_anios() {
    this.store.dispatch(cargarAnios({ pagina: "1", limit: "100" }));
    this.store.select(selectAnios).pipe(
      tap((e: any) => {
        this.anios = e.items;
        this.loading = e.loading;
      })
    );
  }

  new(model: Planilla) {
    const ref = this.modalService.open(PlanillaNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceModel.create(data.frm).subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.seleccion_query();
            } else {
              Swal.fire("Atención", resp.msg, "error");
            }
          });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  async getBuscar(pagina: string, limit: string, buscar: string) {
    // this.blockUI.start("Consultando datos. Espere un momento...");
    this.desde = moment(this.frmSearch.controls.fecha_desde.value).format(
      "YYYY-MM-DD 00:00"
    );
    this.hasta = moment(this.frmSearch.controls.fecha_hasta.value).format(
      "YYYY-MM-DD 23:59"
    );
    this.arrayLugaresControl = [];
    this.arrayRutasDespachos = [];
    this.arrayModel = [];
    this.buscaRutas = false;
    this.buscaLugares = false;
    if (buscar.trim().length > 0) {
      this.buscando = true;

      if (this.isMostrarTodasPlanillas) {
        this._serviceModel
          .get_buscarbyperiodo(
            this.desde,
            this.hasta,
            this._limit.toString(),
            this.frmBuscar.controls["_txtBuscar"].value
          )
          .subscribe((resp: any) => {
            this.arrayModel = resp["data"];
          });
      } else {
        this._serviceModel
          .get_buscarbyperiodo(
            this.desde,
            this.hasta,
            this._limit.toString(),
            this.frmBuscar.controls["_txtBuscar"].value
          )
          .subscribe((resp: any) => {
            this.arrayModel = resp["data"];
          });
      }
    } else {
      this.seleccion_query();

      // this.blockUI.stop();
    }
  }

  edit(model: Planilla) {
    const ref = this.modalService.open(PlanillaNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._planilla_id = model.planilla_id;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceModel
            .update(model.planilla_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.seleccion_query();
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: any) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: "<p><b>Planilla:</b> " + model.planilla_id + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceModel
          .getdelete(model.planilla_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.seleccion_query();
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  cerrar_planilla(model: any) {
    let _operacion = "";
    if (model.cerrada === 1) {
      _operacion = "Abrir";
    } else {
      _operacion = "Cerrar";
    }
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de ' +
        _operacion +
        " la Planilla?</span>",
      html: "<p><b>Consecutivo:</b> " + model.planilla_id + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        /*  this._serviceModel
          .update_cerrarplanilla(model.planilla_id.toString(), model)
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.seleccion_query();
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          }); */
      }
    });
  }

  generar_tarjeta(model: any) {
    const ref = this.modalService.open(PlanillaRecorridoComponent, {
      centered: true,
      size: <any>"md",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
  }

  get_total_pagar(model: any) {
    const ref = this.modalService.open(PlanillaConceptosPagarComponent, {
      centered: true,
      size: <any>"md",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
  }

  ver_rutas(model: PlanillaRutaDespacho) {
    this.buscaLugares = false;
    this.arrayLugaresControl = [];
    this._servicePlanillasRutasDespachos
      .getrutasByplanillaId(model.planilla_id.toString())
      .subscribe((resp) => {
        this.arrayRutasDespachos = resp;
        this._planilla_id = model.planilla_id;
        this._numero = model.numero;
        this._consecutivo = model.planilla_id;
        this._fecha_planilla = model.fecha_planilla;
        this.buscaRutas = true;
        if (this.arrayRutasDespachos.length === 0) {
          Swal.fire(
            "Atención",
            "No se han registrado rutas para la Planilla: " + model.planilla_id,
            "warning"
          );
        }
      });
  }

  ver_lugares(model: RutaDespachoLugar) {
    this._serviceRdl
      .getSoloLugaresByRutaDespachoId(
        "1",
        "10",
        model.rutadespacho_id.toString()
      )
      .subscribe((resp: any) => {
        this.arrayLugaresControl = resp["data"];
        this.buscaLugares = true;
        this._rutadespacho_nombre = model.rutadespacho_nombre;
        if (this.arrayLugaresControl.length === 0) {
          Swal.fire(
            "Atención",
            "No se han registrado rutas para la Ruta: ",
            "warning"
          );
        }
      });
  }

  async add_ruta(model: PlanillaRutaDespacho) {
    const ref = this.modalService.open(PlanillaRutaDespachoNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    console.log("model", model.vehiculo_id);
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      async (data) => {
        const date = data.frm._fecha_planilla;
        const time = data.frm._fechahora_salida;
        const fsalida = combineDateTime(date, time);

        data.frm.fechahora_salida = fsalida;
        const isFechaHoraValida = await this.validarFechaHoraRemota(
          data.frm.fechahora_salida,
          model.vehiculo_id
        );

        if (!isFechaHoraValida) {
          return false;
        } else {
          if (data.valido) {
            this._serviceToastr.showInfo("Creando ruta. Espere un momento...");
            this.min = 0;

            const resp: any = await this._servicePlanillasRutasDespachos
              .create(data.frm)
              .toPromise();
            if (resp.status == "ok") {
              setTimeout(() => {
                this._serviceRdl
                  .getByIdAndPlanilla_id(
                    resp.data.planillarutadespacho_id,
                    resp.data.planilla_id
                  )
                  .subscribe((resp: any) => {
                    const planillaLiquidaciones = resp.map(
                      (dato: any) =>
                        new PlanillaLiquidacion(
                          0,
                          dato.planillarutadespacho_id,
                          dato.lugarcontrol_id,
                          dato.minuto,
                          dato.minutos_retardo_sancion,
                          dato.valor_minuto_sancion,
                          this.sumarMinutos(fsalida, dato.minuto),
                          this._gps
                        )
                    );
                    this._servicePlanillaLiquidacion
                      .insert_recorrido(planillaLiquidaciones)
                      .subscribe((resp: any) => {
                        // setTimeout(() => {
                        this.seleccion_query();
                        this.ver_rutas(model);
                        this._serviceToastr.showSuccess(
                          "Ruta creada correctamente..."
                        );
                        // }, 500);
                      });
                  });
              }, 1500);
            }
          } else {
            Swal.fire("Atención", "Los datos no son válidos", "error");
          }
        }
      },
      (cancel) => {}
    );
  }

  insert_recorrido(model: any) {
    const date = moment(model.fecha_planilla).format("DD-MM-YYYY");
    const time = moment(model.fechahora_salida).format("HH:mm");
    const fsalida = combineDateTime(date, time);
    let datosliquidar = [];

    this._serviceRdl
      .getByIdAndPlanilla_id(model.planillarutadespacho_id, model.planilla_id)
      .subscribe((resp: any) => {
        datosliquidar = resp;

        this.min = 0;
        // Crear una lista de objetos PlanillaLiquidacion
        const planillaLiquidaciones = datosliquidar.map(
          (dato: any) =>
            new PlanillaLiquidacion(
              0,
              dato.planillarutadespacho_id,
              dato.lugarcontrol_id,
              dato.minuto,
              dato.minutos_retardo_sancion,
              dato.valor_minuto_sancion,
              this.sumarMinutos(fsalida, dato.minuto),
              this._gps
            )
        );
        this._servicePlanillaLiquidacion
          .insert_recorrido(planillaLiquidaciones)
          .subscribe(
            (response: any) => {
              this._serviceToastr.showSuccess(response.msg);
              this.ver_rutas(model);
            },
            (error) => {
              this._serviceToastr.showSuccess(error);
            }
          );

        // Enviar los datos al API uno por uno
        /* planillaLiquidaciones.forEach((planilla) => {
          this._servicePlanillaLiquidacion.insert_recorrido(planilla).subscribe(
            (response) => {
              console.log("Planilla guardada:", response);
            },
            (error) => {
              console.error("Error al guardar la planilla:", error);
            }
          );
        }); */
      });
  }

  delete_ruta_asignada(model: any) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html:
        "<p><b>Ruta:</b> " +
        model.rutadespacho_nombre +
        " - " +
        moment(model.fechahora_salida).format("HH:mm") +
        "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._servicePlanillasRutasDespachos
          .get_delete_ruta_asignada(model.planillarutadespacho_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.seleccion_query();
              this.ver_rutas(this.arrayRutasDespachos[0]);
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  liquidar(model: Planilla) {
    if (model.cuenta_rutas === 0) {
      Swal.fire(
        "Atención",
        "No se han registrado rutas para la Planilla",
        "warning"
      );
    } else {
      this.router.navigate([`/liquidar-planilla/${model.planilla_id}`], {
        queryParams: {
          fdesde: moment(this.frmSearch.controls.fecha_desde.value).format(
            "YYYY-MM-DD"
          ),
          fhasta: moment(this.frmSearch.controls.fecha_hasta.value).format(
            "YYYY-MM-DD"
          ),
          // term: this.frmBuscar.controls["_txtBuscar"].value,
        },
      });
    }
  }

  async seleccion_query() {
    if (this.isMostrarTodasPlanillas) {
      await this.listar_registradasbyperiodo(this.desde, this.hasta); //Con Rol administrador
    } else {
      await this.listar_registradasbyuserperiodo(
        this.desde,
        this.hasta,
        this._limit.toString(),
        this._user_id
      );
    }
  }

  /* en produccion 04112024 seleccion_query() {
    if (this.isMostrarTodasPlanillas) {
     const resp: any = this.listar_registradasbyperiodo(this.desde, this.hasta); //Con Rol administrador
    } else {
      this.listar_registradasbyuserperiodo(
        this.desde,
        this.hasta,
        this._limit.toString(),
        this._user_id
      );
    }
  }
 */
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  // Menu contextual

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menu.focusFirstItem("mouse");

    const ref = this.modalService.open(DlgPlanillasComponent, {
      centered: true,
      size: "lg",
      backdrop: "static",
    });

    ref.result.then(
      (data) => {
        console.log(data);
      },
      (cancel) => {
        console.log(cancel);
        this.seleccion_query();
      }
    );
  }

  onContextMenuAction1(item: Item) {
    alert(`Click on Action 1 for ${item.name}`);
  }

  onContextMenuAction2(item: Item) {
    alert(`Click on Action 2 for ${item.name}`);
  }

  ngOnDestroy() {
    this.planillasSubscription.unsubscribe();
  }

  sendWhatsApp() {
    const phoneNumber = "573183819277"; // Reemplaza con el número de teléfono en formato internacional
    const message = "Hola, este es un mensaje desde mi aplicación Angular"; // Tu mensaje aquí
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  }

  generar_qr(row: Planilla) {
    if (row.conductor_celular.trim().length == 0) {
      Swal.fire("Atención", "El número de celular no es válido...", "warning");
    } else if (row.conductor_celular.trim().length !== 10) {
      Swal.fire(
        "Atención",
        "El número de celular no es válido. " + row.conductor_celular,
        "warning"
      );
    } else {
      this.pid = row.planilla_id.toString();
      this._celphone = "3183819277";
      this.qrCodeService.generateQRCode(this.pid).then((base64Image) => {
        const mimeType = "image/png";
        const archivo = this.qrCodeService.base64ToBlob(base64Image, mimeType);
        if (archivo) {
          this.upload_file(archivo);
        }
      });
    }
  }

  change_chk() {
    if (this.frmSearch.controls["chkvehiculo"].value) {
      // this.numeroElement.nativeElement.focus();
    }
  }

  upload_file(archivo: File) {
    this.fileUploadService
      .uploadFile(archivo, this.pid.toString())
      .then((resp) => {
        if (resp !== "Error") {
          this.urlQrCode = `${resp}.png`;
          const msg = `Se ha Generado Planilla No. ${this.pid.toString()} para el Vehículo 175. Pulse sobre el link ${
            this.urlQrCode
          }`;
          this.icloudService
            .sendSms_Planilla(this._celphone, msg)
            .subscribe((resp: any) => {
              Swal.fire("Atención", resp.message, "success");
            });
        } else {
          Swal.fire(
            "Atención",
            "El Archivo QR no fue subido correctamente...",
            "warning"
          );
        }
      });
  }

  sumarMinutos(fecha: Date, minutos: number): Date {
    let nuevaFecha = new Date(fecha.getTime());
    this.min += minutos;
    nuevaFecha.setMinutes(nuevaFecha.getMinutes() + this.min);
    this._gps = nuevaFecha.toTimeString().split(" ")[0];
    return nuevaFecha;
  }

  async validarFechaHoraRemota(value: any, vhid: number): Promise<boolean> {
    let fsalida = value;
    try {
      const resp: any = await this._servicePlanillasRutasDespachos
        .get_fechahora_salida(fsalida, vhid)
        .toPromise();

      if (resp.registros > 0) {
        Swal.fire(
          "Atención",
          "Hora de Salida <strong>" +
            moment(resp.data.fechahora_salida).format("YYYY-MM-DD HH:mm") +
            "</strong> ya asignada:<br> " +
            "<strong>Vehículo:</strong> " +
            resp.data.numero +
            "<br><strong>Ruta:</strong> " +
            resp.data.rutadespacho_nombre,
          "warning"
        );
        return false;
      } else {
        return true;
      }
    } catch (error) {
      Swal.fire("Atención", error, "warning");
      return false;
    }
  }
}

function combineDateTime(dateString: string, timeString: string): Date {
  const [day, month, year] = dateString.split("-").map(Number);
  const [hours, minutes] = timeString.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes);
}

export interface Item {
  id: number;
  name: string;
}
