import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as aniosActions from "../../store/actions/anios.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AniosService } from "../../../../pages/anios/services/anios.service";
import { of } from "rxjs";

@Injectable()
export class AniosEffects {
  constructor(private actions$: Actions, private aniosService: AniosService) {}

  cargarAnios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(aniosActions.cargarAnios),
      mergeMap((action) =>
        this.aniosService.getListar(action.pagina, action.limit).pipe(
          map((resp) =>
            aniosActions.cargarAniosSuccess({
              anios: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              aniosActions.cargarAniosError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  cargarAniosByQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(aniosActions.cargarAniosByQuery),
      mergeMap((action) =>
        this.aniosService
          .get_buscar(action.pagina, action.limit, action.query)
          .pipe(
            map((resp) =>
              aniosActions.cargarAniosByQuerySuccess({
                anios: resp["data"],
                registros: resp["registros"],
                pagecount: resp["pageCount"],
              })
            ),
            catchError((err) =>
              of(
                aniosActions.cargarAniosByQueryError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );
}
