import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as rutasdespachoslugaresActions from "../../store/actions/rutasdespachos-lugares.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { RutasDespachosLugaresService } from "../../../../pages/rutasdespachos-lugares/services/rutasdespachos-lugares.service";
import { of } from "rxjs";

@Injectable()
export class RutasDespachosLugaresEffects {
  constructor(
    private actions$: Actions,
    private rutasdespachoslugaresService: RutasDespachosLugaresService
  ) {}

  cargarRutasDespachosLugares$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rutasdespachoslugaresActions.cargarRutasDespachosLugares),
      mergeMap(() =>
        this.rutasdespachoslugaresService.getListar(1, 10).pipe(
          map((resp) =>
            rutasdespachoslugaresActions.cargarRutasDespachosLugaresSuccess({
              rutasdespachoslugares: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              rutasdespachoslugaresActions.cargarRutasDespachosLugaresError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
