import {
  Component,
  Inject,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConductoresService } from "../services/conductores.service";
import { VConductor } from "../models/v-conductores.model";
import { PaginadorService } from "../paginador/paginador.service";
import Swal from "sweetalert2";

export interface DialogData {
  _vehiculo_id: number;
  conductor_nombre: string;
}

@Component({
  selector: "app-dlg-conductores",
  templateUrl: "./dlg-conductores.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      mat-form-field {
        width: 100%;
        font-size: 10px;
      }
      .btndlg.btn.btn-link {
        padding-bottom: 0px;
        padding-top: 4px;
      }

      mat-dialog {
        width: 200%;
      }
    `,
  ],
})
export class DlgConductoresComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<any>();
  data_model: VConductor[] = [];
  Titulo: string = "Conductores";

  constructor(
    public dialogRef: MatDialogRef<DlgConductoresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _serviceModel: ConductoresService,
    private _servicePaginador: PaginadorService
  ) {}

  ngOnInit() {
    this.data_model = [];

    if (this.data._vehiculo_id > 0) {
      this.get_conductorbyVehiculoId("1", "10", this.data._vehiculo_id);
    } else {
      this.getLista(1, 10);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  BuscarRegistro(buscar: string) {
    if (buscar.length > 0) {
      this._serviceModel
        .get_buscar("1", "10", buscar)
        .subscribe((resp: any) => {
          this.data_model = resp.data;
        });
    } else {
      this.getLista(1, 10);
    }
  }

  onSelect(id: number) {
    this.submitClicked.emit(
      this.data_model
        .filter((x) => x.conductor_id === id)
        .map((m) => ({
          conductor_id: m.conductor_id,
          conductor_nombres: m.nombres,
        }))
    );
    this.dialogRef.close();
  }

  getLista(pagina: number, recxpag: number) {
    this._serviceModel
      .get_listar_vconductores(pagina.toString(), recxpag.toString())
      .subscribe((resp: any) => {
        this.data_model = resp.data;
        this._servicePaginador.page$.emit(pagina);
        this._servicePaginador.registros$.emit(resp.registros);
      });
  }

  get_conductorbyVehiculoId(
    pagina: string,
    recxpag: string,
    vehiculo_id: number
  ) {
    this._serviceModel.getbyvehiculoid(vehiculo_id).subscribe((resp: any) => {
      this.data_model = resp.data;
      if (this.data_model.length === 0) {
        this._servicePaginador.page$.emit(1);
        this._servicePaginador.registros$.emit(1);
        this.dialogRef.close();
        Swal.fire(
          "Atención",
          "No se registra Conductor para este vehículo...",
          "warning"
        );
      } else {
        this._servicePaginador.page$.emit(pagina);
        this._servicePaginador.registros$.emit(1);
      }
    });
  }

  receiveMessage($event: any) {
    // this.getLista($event.pagina, $event.recxpag);
  }
}
