import { ActionReducerMap } from "@ngrx/store";
import * as userReducers from "./store/reducers";
import * as ppReducers from "./despachos/pages/planilla-parametros/store/reducers/planilla-parametros.reducer";
import * as pp2Reducers from "./despachos/pages/planilla-parametros/store/reducers/planilla-parametro.reducer";
import * as empresaReducers from "./pages/empresa/store/reducers/empresa.reducer";
import * as aniosReducers from "./despachos/pages/anios/store/reducers/anios.reducer";
import * as pcReducers from "./despachos/pages/planillas-consecutivos/store/reducers/planillas-consecutivos.reducer";
import * as planillaconsecutivoReducers from "./despachos/pages/planillas-consecutivos/store/reducers/planilla-consecutivo.reducer";
import * as plReducers from "./despachos/pages/planillas-liquidacion/store/reducers/planillas-liquidacion.reducer";
import * as planillasReducers from "./despachos/pages/planillas/store/reducers/planillas.reducer";
import * as planillaReducers from "./despachos/pages/planillas/store/reducers/planilla.reducer";
import * as planillasRutasDespachosReducers from "./despachos/pages/planillas-rutasdespachos/store/reducers/planillas_rutasdespachos.reducer";
import * as rdReducers from "./despachos/pages/rutas-despachos/store/reducers/rutas-despachos.reducer";
import * as sancionesReducer from "./despachos/pages/sanciones/store/reducers/sanciones.reducer";
import * as planillassancionesReducer from "./despachos/pages/planillas-sanciones/store/reducers/planillas_sanciones.reducer";
import * as lcReducers from "./despachos/pages/lugares-control/store/reducers/lugares-control.reducer";

import * as rdlReducers from "./despachos/pages/rutasdespachos-lugares/store/reducers/rutasdespachos-lugares.reducer";

export interface AppState {
  users: userReducers.UsersState;
  user: userReducers.UserState;
  planillaparametros: ppReducers.PlanillaParametrosState;
  planillaparametro: pp2Reducers.PlanillaParametroState;
  empresa: empresaReducers.EmpresaState;
  planillasconsecutivos: pcReducers.PlanillasConsecutivosState;
  planillaconsecutivo: planillaconsecutivoReducers.PlanillaConsecutivoState;
  planillasliquidacion: plReducers.PlanillasLiquidacionState;
  planillas: planillasReducers.PlanillasState;
  planilla: planillaReducers.PlanillaState;
  planillasrutasdespachos: planillasRutasDespachosReducers.PlanillasRutasDespachosState;
  rutasdespachos: rdReducers.RutasDespachosState;
  rutasdespachoslugares: rdlReducers.RutasDespachosLugaresState;
  lugarescontrol: lcReducers.LugaresControlState;
  anios: aniosReducers.AniosState;
  sanciones: sancionesReducer.SancionesState;
  planillassanciones: planillassancionesReducer.PlanillasSancionesState;
}

export const appReducers: ActionReducerMap<AppState> = {
  users: userReducers.usersReducer,
  user: userReducers.userReducer,
  planillaparametros: ppReducers.planillaparametrosReducer,
  planillaparametro: pp2Reducers.planillaparametroReducer,
  empresa: empresaReducers.empresaReducer,
  planillasconsecutivos: pcReducers.planillasconsecutivosReducer,
  planillaconsecutivo: planillaconsecutivoReducers.planillaconsecutivoReducer,
  planillasliquidacion: plReducers.planillasliquidacionReducer,
  planillas: planillasReducers.planillasReducer,
  planilla: planillaReducers.planillaReducer,
  planillasrutasdespachos:
    planillasRutasDespachosReducers.planillasrutasdespachosReducer,
  rutasdespachos: rdReducers.rutasdespachosReducer,
  rutasdespachoslugares: rdlReducers.rutasdespachoslugaresReducer,
  lugarescontrol: lcReducers.lugarescontrolReducer,
  anios: aniosReducers.aniosReducer,
  sanciones: sancionesReducer.sancionesReducer,
  planillassanciones: planillassancionesReducer.planillassancionesReducer,
};
