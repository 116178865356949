import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { EmpresaService } from "../services/empresa.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styles: [],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  submitted: boolean = false;
  messageLogin: string = "";
  data: Date = new Date();
  isLoading: boolean = false;
  showError: boolean = false;
  empresa: any;
  linea2: any;
  _errorLogin: boolean = false;
  returnUrl: string;

  constructor(
    private _serviceAuth: AuthService,
    private _serviceEmpresa: EmpresaService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this._serviceEmpresa.get_listar("1", "10").subscribe((resp: any) => {
      this.empresa = resp.data[0].razon_social;
      this.linea2 = resp.data[0].razon_social2;
    });
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.email, Validators.required]],
      password: ["", Validators.required],
    });
  }

  get frm() {
    return this.loginForm.controls;
  }

  login() {
    this.returnUrl = "/home";
    if (this.route.snapshot.queryParams["returnUrl"]) {
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
    }
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    this._serviceAuth.login(this.loginForm.value).subscribe(
      (resp) => {
        if (resp.ok) {
          this.router.navigate([this.returnUrl]);
        }
        if (!resp.ok) {
          this._errorLogin = true;
          this.messageLogin = resp.msg;
          return;
        }
        /* this._errorLogin = false;
        this.isLoading = false;
        this._serviceAuth.isAuthenticated = true;
        this._serviceAuth.isLogueado$.emit(true);

        this.router.navigate([this.returnUrl]); */
      },
      (error) => {
        this.messageLogin = error;
        this._errorLogin = true;
        this.isLoading = false;
        this.showError = true;
      }
    );
  }

  gethttps() {
    this._serviceEmpresa.get_https().subscribe((resp) => {
      console.log("https", resp);
    });
  }
}
