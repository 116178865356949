import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { RutaDespachoLugar } from "src/app/models/rutasdespachos-lugares.model";

@Injectable({
  providedIn: "root",
})
export class RutasDespachosLugaresService {
  httpOptions = {};
  private url = URL_API;

  constructor(private http: HttpClient) {}

  getListar(pagina: number, limit: number) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/rutasdespachos_lugares/get_listar/${pagina}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  get_listar(page: string, limit: string) {
    this.loadAuth();
    const url =
      URL_API + "/rutasdespachos_lugares/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API +
      "/rutasdespachos_lugares/get_buscar/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  getById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/rutasdespachos_lugares/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  getByIdAndPlanilla_id(id: number, planilla_id: number) {
    return this.http
      .get(
        `${this.url}/rutasdespachos_lugares/get_byid_and_planillaid/${id}/${planilla_id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  getByRutaDespachoId(rutadespacho_id: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/rutasdespachos_lugares/get_byrutadespachoid/${rutadespacho_id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  getSoloLugaresByRutaDespachoId(
    pagina: string,
    limit: string,
    rutadespacho_id: string
  ) {
    this.loadAuth();
    /* return this.http
      .get(
        `${this.url}/rutasdespachos_lugares/get_sololugaresbyrutadespachoid/${pagina}/${limit}/${rutadespacho_id}`
      )
      .pipe(map((resp) => resp["data"])); */
    return this.http.get(
      `${this.url}/rutasdespachos_lugares/get_sololugaresbyrutadespachoid/${pagina}/${limit}/${rutadespacho_id}`
    );
  }

  getTarjetasRutas() {
    this.loadAuth();
    return this.http.get(
      `${this.url}/rutasdespachos_lugares/get_tarjetas_rutas`
    );
  }

  getRutaDespachoLugar(planilla_id: string, rutadespacho_id: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/rutasdespachos_lugares/get_rutadespacho/${planilla_id}/${rutadespacho_id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  create(model: any) {
    this.loadAuth();
    return this.http.post(
      `${this.url}/rutasdespachos_lugares/nuevo`,
      model,
      this.httpOptions
    );
  }

  update(id: string, model: RutaDespachoLugar) {
    this.loadAuth();
    return this.http.put(
      `${this.url}/rutasdespachos_lugares/update/${id}`,
      model,
      this.httpOptions
    );
  }

  getdelete(id: string) {
    this.loadAuth();
    return this.http.delete(
      `${this.url}/rutasdespachos_lugares/delete/${id}`,
      this.httpOptions
    );
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
