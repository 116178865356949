import { createAction, props } from "@ngrx/store";
import { PlanillaSancion } from "../../../../../models/planillas-sanciones.model";

export const cargarPlanillaSancion = createAction(
  "[Planilla Sancion] Cargar Planilla Sancion",
  props<{ id: string }>()
);

export const cargarListaPlanillaSancion = createAction(
  "[Lista Planilla Sancion] Cargar Lista Planilla Sancion"
);

export const cargarPlanillaSancionSuccess = createAction(
  "[Planilla Sancion] Cargar Planilla Sancion Success",
  props<{ planillasancion: PlanillaSancion }>()
);

export const cargarPlanillaSancionError = createAction(
  "[Planilla Sancion] Cargar Planilla Sancion Error",
  props<{ payload: any }>()
);
export const addPlanillaSancion = createAction(
  "[Planilla Sancion] Nuevo Planilla Sancion",
  props<{ planillasancion: PlanillaSancion }>()
);

export const addPlanillaSancionSuccess = createAction(
  "[Planilla Sancion] Nuevo Planilla Sancion Success",
  props<{ planillasancion: any }>()
);

export const addPlanillaSancionError = createAction(
  "[Planilla Sancion] Nuevo Planilla Sancion Error",
  props<{ payload: any }>()
);

export const updPlanillaSancion = createAction(
  "[Planilla Sancion] Update Planilla Sancion",
  props<{ planillasancion: any }>()
);

export const updPlanillaSancionSuccess = createAction(
  "[Planilla Sancion] Update Planilla Sancion Success",
  props<{ planillasancion: any }>()
);

export const updPlanillaSancionError = createAction(
  "[Planilla Sancion] Update Planilla Sancion Error",
  props<{ payload: any }>()
);
