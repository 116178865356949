import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillasancionActions from "../../store/actions/planilla_sancion.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { PlanillasSancionesService } from "../../services/planillas_sanciones.service";

@Injectable()
export class PlanillaSancionEffects {
  constructor(
    private actions$: Actions,
    private planillassancionesService: PlanillasSancionesService
  ) {}

  cargarPlanillaSancion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasancionActions.cargarPlanillaSancion),
      mergeMap((action) =>
        this.planillassancionesService.getById(action.id).pipe(
          map((data) =>
            planillasancionActions.cargarPlanillaSancionSuccess({
              planillasancion: data,
            })
          ),
          catchError((err) =>
            of(
              planillasancionActions.cargarPlanillaSancionError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  addPlanilla$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasancionActions.addPlanillaSancion),
      mergeMap((action) =>
        this.planillassancionesService.create(action.planillasancion).pipe(
          map((data) =>
            planillasancionActions.addPlanillaSancionSuccess({
              planillasancion: data,
            })
          ),
          catchError((err) =>
            of(
              planillasancionActions.addPlanillaSancionError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
