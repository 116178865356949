import { AppState } from "src/app/app.reducers";
import { PlanillaParametrosState } from "../reducers/planilla-parametros.reducer";
import { createSelector } from "@ngrx/store";
import { PlanillaParametroState } from "../reducers/planilla-parametro.reducer";

export const selectPlanillasParametros = (state: AppState) =>
  state.planillaparametros;

export const selectListPlanillasParametros = createSelector(
  selectPlanillasParametros,
  (state: PlanillaParametrosState) => state.items
);
export const selectLoadingPlanillasParametros = createSelector(
  selectPlanillasParametros,
  (state: PlanillaParametrosState) => state.loading
);
export const selectLoadedPlanillasParametros = createSelector(
  selectPlanillasParametros,
  (state: PlanillaParametrosState) => state.loaded
);
export const selectFailPlanillasParametros = createSelector(
  selectPlanillasParametros,
  (state: PlanillaParametrosState) => state.error
);

//Registro
export const selectPlanillaParametro = (state: AppState) =>
  state.planillaparametro;

export const selectListPlanillaParametro = createSelector(
  selectPlanillaParametro,
  (state: PlanillaParametroState) => state.item
);

export const selectLoadingPlanillaParametro = createSelector(
  selectPlanillaParametro,
  (state: PlanillaParametroState) => state.loading
);
export const selectLoadedPlanillaParametro = createSelector(
  selectPlanillaParametro,
  (state: PlanillaParametroState) => state.loaded
);
