import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { AppState } from "../../../../app.reducers";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Anio } from "src/app/models/anios.model";
import { PlanillaConsecutivo } from "src/app/models/planillas-consecutivos.model";
import { PlanillasConsecutivosService } from "../services/planillas-consecutivos.service";
import { greaterThanZeroValidator } from "src/app/validators/form-validators";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PcNewEditComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;

  frm: FormGroup;
  loading: boolean = false;
  _userSession: any = "0";
  _numero = "";
  anios: Anio[] = [];
  isEdit: boolean = false;
  _model: PlanillaConsecutivo;
  ultimoConsecutivo: number = 0;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private store: Store<AppState>,
    private _serviceModel: PlanillasConsecutivosService
  ) {}

  async ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      planillaconsecutivo_id: [0],
      anio_id: [6, Validators.required],
      consecutivo: [0, Validators.required],
      _desde: [0, [Validators.required, Validators.min(10010)]],
      _cantidad: [0, [Validators.required, greaterThanZeroValidator()]],
      _ultimoConsecutivo: [0],
    });
    this.getAnios().subscribe((datos) => {
      this.anios = datos.items;
    });
    await this.get_consecutivo_max();

    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.planillaconsecutivo_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceModel.getById(id.toString()).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp;
      this.frm = this.fb.group({
        planillaconsecutivo_id: [this._model.planillaconsecutivo_id],
        anio_id: [this._model.anio_id],
        consecutivo: [this._model.consecutivo, Validators.required],
      });
      this.blockUI.stop();
    });
  }

  getAnios(): Observable<any> {
    return this.store.select((appState) => appState.anios);
  }

  async get_consecutivo_max() {
    this._serviceModel.get_consecutivo_max().subscribe((resp) => {
      this.ultimoConsecutivo = resp.consecutivo_max;
      this.frm.controls["_ultimoConsecutivo"].patchValue(
        this.ultimoConsecutivo
      );
      this.frm.controls["_desde"].patchValue(this.ultimoConsecutivo + 1);
    });
  }
  solo_numeros(value: string, ctrl: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls[ctrl].setValue(value);
    }
  }
}
