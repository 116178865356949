import { createReducer, on } from "@ngrx/store";
import {
  cargarRutasDespachosLugares,
  cargarRutasDespachosLugaresError,
  cargarRutasDespachosLugaresSuccess,
} from "../actions";
import { RutaDespachoLugar } from "../../../../../models/rutasdespachos-lugares.model";

export interface RutasDespachosLugaresState {
  items: RutaDespachoLugar[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const rutasdespachoslugaresInitialState: RutasDespachosLugaresState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _rutasdespachoslugaresReducer = createReducer(
  rutasdespachoslugaresInitialState,

  on(cargarRutasDespachosLugares, (state) => ({ ...state, loading: true })),

  on(
    cargarRutasDespachosLugaresSuccess,
    (state, { rutasdespachoslugares, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...rutasdespachoslugares],
      registros: registros,
      pagecount: pagecount,
    })
  ),

  on(cargarRutasDespachosLugaresError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function rutasdespachoslugaresReducer(state, action) {
  return _rutasdespachoslugaresReducer(state, action);
}
