import { createAction, props } from "@ngrx/store";
import { PlanillaRutaDespacho } from "../../../../../models/planillas_rutasdespachos.model";

export const cargarPlanillasRutasDespachos = createAction(
  "[Planillas Rutas Despachos] Cargar Planillas Rutas Despachos"
);

export const cargarPlanillasRutasDespachosSuccess = createAction(
  "[Planillas Rutas Despachos] Cargar Planillas Rutas Despachos Success",
  props<{
    planillasrutasdespachos: PlanillaRutaDespacho[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarPlanillasRutasDespachosError = createAction(
  "[Planillas Rutas Despachos] Cargar Planillas Rutas Despachos Error",
  props<{ payload: any }>()
);
