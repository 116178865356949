import {
  Component,
  Inject,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  HostListener,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VehiculosService } from "../services/vehiculos.service";
import { Vehiculo } from "../models/vehiculos.model";
import { PaginadorService } from "../paginador/paginador.service";
import { FormBuilder, FormGroup } from "@angular/forms";

export interface DialogData {
  vehiculo_id: number;
  numero: number;
  placa: string;
  permitir_recaudo: number;
}

@Component({
  selector: "app-dlg-vehiculos",
  templateUrl: "./dlg-vehiculos.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      mat-form-field {
        width: 100%;
        font-size: 10px;
      }
      .btndlg.btn.btn-link {
        padding-bottom: 0px;
        padding-top: 4px;
      }

      mat-dialog {
        width: 200%;
      }
    `,
  ],
})
export class DlgVehiculosComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<any>();
  data_model: Vehiculo[] = [];
  Titulo: string = "Vehículos";
  buscando: boolean = false;
  frmBuscar: FormGroup;
  _vehiculo_id: number = 0;

  @HostListener("window:keydown", ["$event"])
  handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case "F12":
        event.preventDefault(); // Evitar la acción predeterminada del navegador
        this.onSelect(this._vehiculo_id);
        break;
      default:
        break;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<DlgVehiculosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _serviceModel: VehiculosService,
    private _servicePaginador: PaginadorService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.data_model = [];
    this.buscando = false;
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    // this.getLista("1", "10");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  BuscarRegistro(pagina: string, recxpag: string, buscar: string) {
    this.data_model = [];
    this.buscando = false;

    if (this.data.permitir_recaudo) {
      this._serviceModel
        .get_buscar_veh_recaudos_unq(buscar)
        .subscribe((resp: any) => {
          this.data_model = resp.data;
          this.buscando = true;
        });
    } else {
      this._serviceModel
        .get_buscar_propietarios_vehiculos_unq(pagina, recxpag, buscar)
        .subscribe((resp: any) => {
          this.data_model = resp.data;
        });
    }
  }

  onSelect(id: number) {
    this.submitClicked.emit(
      this.data_model
        .filter((x) => x.vehiculo_id === id)
        .map((m) => ({
          vehiculo_id: m.vehiculo_id,
          propietario_id: m.propietario_id,
          socio_id: m.socio_id,
          numero: m.numero,
          placa: m.placa,
          count_abiertas: m.count_abiertas,
        }))
    );
    this.dialogRef.close();
  }

  /*  getLista(pagina: string, recxpag: string) {
    this.data_model = [];
    this._serviceModel
      .get_listar_veh_propietarios_unq(pagina, recxpag)
      .subscribe((resp: any) => {
        this.data_model = resp.data;
        this._servicePaginador.page$.emit(pagina);
        this._servicePaginador.registros$.emit(resp.registros);
      });
  } */

  /*   receiveMessage($event: any) {
    this.getLista($event.pagina, $event.recxpag);

    if (this.frmBuscar.controls._txtBuscar.value === "") {
      this.buscando = false;
    }

    if (!this.buscando) {
      this.getLista($event.pagina, $event.recxpag);
    }

    if (this.buscando) {
      const value = this.frmBuscar.controls._txtBuscar.value;
      this.BuscarRegistro($event.pagina, $event.recxpag, value);
    }
  } */

  Buscar() {
    const value = this.frmBuscar.controls._txtBuscar.value;
    if (value.length > 0) {
      this.buscando = true;
      this.BuscarRegistro("1", "10", value);
    }
  }

  change_texto() {
    this.buscando = false;
  }
}
