import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ReportesService } from "../../../services/reportes.service";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
@Component({
  selector: "app-rpt-arqueo",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./rpt-arqueo.component.html",
  styles: [],
})
export class RptArqueoComponent implements OnInit {
  @Input() data_arqueo = [];
  @Input() data_arqueo_retiros = [];
  @Input() consultando = false;
  @Input() _totalSaldo = 0;
  tituloReporte = "ARQUEO";
  fileName = "";
  FechaDesde = "";
  FechaHasta = "";
  Empresa = "";
  loading: boolean = false;
  _mes = "";
  _bus = "";

  constructor(
    private _serviceReportes: ReportesService,
    private datePipe: DatePipe
  ) {
    this._serviceReportes.EventDatosArqueo$.subscribe(async (resp: any) => {
      this.data_arqueo = resp;
    });

    this._serviceReportes.EventDatosArqueoRetiros$.subscribe(
      async (resp: any) => {
        this.data_arqueo_retiros = resp;
      }
    );

    this._serviceReportes.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportes.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });

    this._serviceReportes.mes$.subscribe((resp: any) => {
      this._mes = resp;
    });

    this._serviceReportes.bus$.subscribe((resp: any) => {
      this._bus = resp;
    });
  }

  ngOnInit() {}
}
