import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";

@Injectable({
  providedIn: "root",
})
export class TiposReportesDespachosService {
  httpOptions = {};
  constructor(private http: HttpClient) {}

  getLista() {
    this.loadAuth();
    const url = URL_API + "/tipos_reportes_despachos/get_listar";
    return this.http.get(url, this.httpOptions);
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
