import { createAction, props } from "@ngrx/store";
import { Planilla } from "../../../../../models/planillas.model";

export const cargarPlanilla = createAction(
  "[Planilla] Cargar Planilla",
  props<{ id: string }>()
);

export const cargarListaPlanilla = createAction(
  "[Lista Planilla] Cargar Lista Planilla"
);

export const cargarPlanillaSuccess = createAction(
  "[Planilla] Cargar Planilla Success",
  props<{ planilla: Planilla }>()
);

export const cargarPlanillaError = createAction(
  "[Planilla] Cargar Planilla Error",
  props<{ payload: any }>()
);
export const addPlanilla = createAction(
  "[Planilla] Nuevo Planilla",
  props<{ planilla: Planilla }>()
);

export const addPlanillaSuccess = createAction(
  "[Planilla] Nuevo Planilla Success",
  props<{ planilla: any }>()
);

export const addPlanillaError = createAction(
  "[Planilla] Nuevo Planilla Error",
  props<{ payload: any }>()
);

export const updPlanilla = createAction(
  "[Planilla] Update Planilla",
  props<{ planilla: any }>()
);

export const updPlanillaSuccess = createAction(
  "[Planilla] Update Planilla Success",
  props<{ planilla: any }>()
);

export const updPlanillaError = createAction(
  "[Planilla] Update Planilla Error",
  props<{ payload: any }>()
);

//Para Formulario de liquidación de Planilas
export const cargarPlanillasMasterByPlanilla_id = createAction(
  "[Planillas] Cargar Planillas Master por ID",
  props<{ planilla_id: number }>()
);

export const cargarPlanillasMasterByPlanilla_idSuccess = createAction(
  "[Planillas] Cargar Planillas Master por ID Success",
  props<{
    planilla: Planilla;
  }>()
);

export const cargarPlanillasMasterByPlanilla_idError = createAction(
  "[Planillas] Cargar Planillas Master por ID Error",
  props<{ payload: any }>()
);
