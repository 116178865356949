import { createAction, props } from "@ngrx/store";
import { LugarControl } from "../../../../../models/lugares-control.model";

export const cargarLugaresControl = createAction(
  "[Lugares Control] Cargar Lugares",
  props<{ pagina: string; limit: string }>()
);

export const cargarLugaresControlSuccess = createAction(
  "[Lugares Control] Cargar Lugares Success",
  props<{
    lugarescontrol: LugarControl[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarLugaresControlError = createAction(
  "[Lugares Control] Cargar Lugares Error",
  props<{ payload: any }>()
);

export const cargarLugaresControlByQuery = createAction(
  "[LugaresControl] Cargar LugaresControl Query",
  props<{ pagina: string; limit: string; query: string }>()
);

export const cargarLugaresControlByQuerySuccess = createAction(
  "[LugaresControl] Cargar LugaresControl Query Success",
  props<{
    lugarescontrol: LugarControl[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarLugaresControlByQueryError = createAction(
  "[LugaresControl] Cargar LugaresControl Query Error",
  props<{ payload: any }>()
);
