import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ItCloudSmsService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  SMS_EMAIL = "oscarboc@hotmail.com";
  SMS_TOKEN = "1jv6vudtg8y8i7kk16e3p";
  SMS_ENDPOINT =
    "https://contacto-masivo.com/sms/back_sms/public/api/send/sms/json";
  SMS_TYPESEND = "1via";

  constructor(private http: HttpClient) {}

  sendSms(endpoint: any, model: any) {
    console.log(endpoint, model);
    return this.http.post(endpoint, model);
  }

  sendSms_Planilla(phone: string, message: string) {
    console.log(phone, message);
    const datax = [
      {
        cellphone: phone,
        message: message,
      },
    ];
    const objSendSMS = [
      {
        email: this.SMS_EMAIL,
        token: this.SMS_TOKEN,
        type_send: this.SMS_TYPESEND,
        data: datax,
      },
    ];

    return this.http.post(this.SMS_ENDPOINT, objSendSMS[0]);
  }
}

// import { Injectable } from "@nestjs/common";
// import { ConfigModule, ConfigService } from "@nestjs/config";
// import {
//   DataSMS,
//   ITCloudSMSInterface,
// } from "../interfaces/it-cloud-sms.interface";
// import { HttpClient } from "@angular/common/http";

// ConfigModule.forRoot({
//   envFilePath: `.${process.env.NODE_ENV}.env`,
// });

// const configService = new ConfigService();

// const SMS_EMAIL = "oscarboc@hotmail.com";
// const SMS_TOKEN = "1jv6vudtg8y8i7kk16e3p";
// const SMS_ENDPOINT =
//   "https://contacto-masivo.com/sms/back_sms/public/api/send/sms/json";
// const SMS_TYPESEND = "1via";

// @Injectable()
// export class ItCloudSmsService {
//   private email: string | undefined;
//   private token: string | undefined;
//   private endPoint: string | undefined;
//   private typeSend: string | undefined;

//   constructor(
//     private readonly http: HttpClient
//   ) {
//     this.endPoint = SMS_ENDPOINT; // configService.get<string>("SMS_ENDPOINT");
//     this.email = SMS_EMAIL; // configService.get<string>("SMS_EMAIL");
//     this.token = SMS_TOKEN; // configService.get<string>("SMS_TOKEN");
//     this.typeSend = SMS_TYPESEND; // configService.get<string>("SMS_TYPESEND");
//   }
//   async sendSms(data: DataSMS[]): Promise<any> {
//     const objSendSMS: ITCloudSMSInterface = new ITCloudSMSInterface();
//     objSendSMS.email = this.email;
//     objSendSMS.token = this.token;
//     objSendSMS.type_send = this.typeSend;
//     objSendSMS.data = data;
//     //const response = await firstValueFrom(this.http.post(this.endPoint,objSendSMS) );
//     const response = await this.http.post(this.endPoint, objSendSMS);

//     return response;
//   }
// }
