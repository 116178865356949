import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AniosService {
  httpOptions = {};
  private url = URL_API;

  constructor(private http: HttpClient) {}

  getListar(pagina: string, limit: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/anios/get_listar/${pagina}/${limit}`, this.httpOptions)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getListaById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/anios/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API + "/anios/get_buscar/" + pagina + "/" + limit + "/" + buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    this.loadAuth();
    return this.http.post(`${this.url}/anios/nuevo`, model);
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
