import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { PlanillaConsecutivo } from "src/app/models/planillas-consecutivos.model";
import { AppState } from "../../../app.reducers";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  cargarPlanillasConsecutivos,
  cargarPlanillasConsecutivosByQuery,
} from "../planillas-consecutivos/store/actions";
import { cargarAnios } from "../anios/store/actions";
import { FormBuilder, FormGroup } from "@angular/forms";
import pageSettings from "src/app/config/page-settings";
import { PcNewEditComponent } from "./new-edit/new-edit.component";
import { PlanillasConsecutivosService } from "./services/planillas-consecutivos.service";
import Swal from "sweetalert2";
import { Anio } from "src/app/models/anios.model";
import { tap } from "rxjs/operators";
import { selectPlanillasConsecutivos } from "./store/selectors/planillas-consecutivos.selectors";
import { PaginadorxService } from "../paginadorx/paginadorx.service";
import { selectAnios } from "../anios/store/selectors/anios.selectors";

@Component({
  selector: "app-planillas-consecutivos",
  templateUrl: "./planillas-consecutivos.component.html",
  styleUrls: ["./planillas-consecutivos.component.css"],
})
export class PlanillasConsecutivosComponent
  implements OnInit, AfterViewChecked
{
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  pageSettings = pageSettings;
  buscando: boolean = false;

  arrayModel: PlanillaConsecutivo[] = [];
  anios: Anio[] = [];
  loading: boolean = false;
  error: any;
  ppSubs: Subscription;
  frmBuscar: FormGroup;
  _planillaconsecutivo_id: number;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _serviceModel: PlanillasConsecutivosService,
    private _servicePaginador: PaginadorxService,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    this.blockUI.start("Consultando datos. Espere un momento...");
    await this.listar("1", "10");
    await this.listar_anios();
  }

  async listar(pagina: string, limit: string) {
    this.store.dispatch(cargarPlanillasConsecutivos({ pagina, limit }));
    this.store
      .select(selectPlanillasConsecutivos)
      .pipe(
        tap((e: any) => {
          this.arrayModel = e.items;
          this.loading = e.loading;
          this._servicePaginador.pagex$.emit(pagina);
          this._servicePaginador.registrosx$.emit(e.registros);
          this.blockUI.stop();
        })
      )
      .subscribe();
  }

  async listar_anios() {
    this.store.dispatch(cargarAnios({ pagina: "1", limit: "100" }));
    this.store.select(selectAnios).pipe(
      tap((e: any) => {
        this.anios = e.items;
        this.loading = e.loading;
      })
    );
  }

  new(model: PlanillaConsecutivo) {
    const ref = this.modalService.open(PcNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          console.log(data.frm);
          this._serviceModel
            .get_generar_consecutivo(
              data.frm._desde,
              data.frm._cantidad,
              data.frm.anio_id
            )
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                console.log(resp);
                this.listar("1", "10");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
          this.listar("1", "10");
          Swal.fire("Atención", "Terminado", "info");
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  receiveMessage($event: any) {
    if (this.frmBuscar.controls._txtBuscar.value.length === 0) {
      this.listar($event.pagina, $event.recxpag);
    } else {
      const value = this.frmBuscar.controls._txtBuscar.value;
      this.getBuscar($event.pagina, $event.recxpag, value);
    }
  }

  async getBuscar(pagina: string, limit: string, buscar: string) {
    if (buscar.trim().length > 0) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      this.buscando = true;

      this.store.dispatch(
        cargarPlanillasConsecutivosByQuery({
          pagina,
          limit,
          query: this.frmBuscar.controls["_txtBuscar"].value,
        })
      );
    } else {
      this.listar("1", "10");
    }
  }

  edit(model: PlanillaConsecutivo) {
    const ref = this.modalService.open(PcNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._planillaconsecutivo_id =
      model.planillaconsecutivo_id;
    ref.result.then(
      (data) => {
        console.log(data.frm);
        if (data.valido) {
          this._serviceModel
            .update(model.planillaconsecutivo_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.listar("1", "10");
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: any) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html:
        "<p><b>Año:</b> " +
        model.anio +
        ", <b>Consecutivo: " +
        model.consecutivo +
        "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceModel
          .getdelete(model.planillaconsecutivo_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.listar("1", "10");
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
