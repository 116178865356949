import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { EstadoCreditoModel } from "../models/estado-credito-model";
import { EstadosCreditoService } from "../services/estados-credito.service";

@Component({
  selector: "app-estados-credito-new-edit",
  templateUrl: "./estados-credito-new-edit.component.html",
  styleUrls: ["./estados-credito-new-edit.component.css"],
})
export class EstadosCreditoNewEditComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  _userSession: any = "0";
  frm: FormGroup;
  isEdit: boolean = false;
  _model: EstadoCreditoModel;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private _serviceModel: EstadosCreditoService
  ) {}

  ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));

    this.frm = this.fb.group({
      estadocredito_id: [0],
      descripcion: ["", Validators.required],
    });
    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.estadocredito_id;
      this.setForm(id);
    }
  }

  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceModel.getById(id).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp.data[0];

      this.frm = this.fb.group({
        estadocredito_id: [this._model.estadocredito_id],
        descripcion: [this._model.descripcion, Validators.required],
      });
      this.blockUI.stop();
    });
  }
}
