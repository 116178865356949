import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { Movimiento } from "../../models/movimientos.model";
import { CuentasBancariasService } from "../../services/cuentas_bancarias.service";
import { MovimientosService } from "../../services/movimientos.service";
import Swal from "sweetalert2";
import { TiposMovimientoService } from "src/app/services/tipos_movimiento.service";
import { formatNumber } from "@angular/common";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-movimientos-new",
  templateUrl: "./movimientos-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MovimientosNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  isLoading: boolean = false;
  isExistSaldoInicial: boolean = true;
  frm: FormGroup;
  tipos_movimiento = [];
  cuentas_bancarias = [];
  data_search_movimientos = [];
  data_saldo_inicial = [];
  _model: Movimiento;
  isEdit: boolean = false;
  _ctaid: number = 0;
  _tipomovid: number = 0;
  _ctanumero: string = "";
  btnActivo: boolean = true;
  _userSession: any = "0";

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceMovimientos: MovimientosService,
    private _serviceCuentasBancarias: CuentasBancariasService,
    private _serviceTiposMovimiento: TiposMovimientoService,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      movimiento_id: [0],
      cuentabancaria_id: ["", Validators.required],
      user_id: [this._userSession.user_id, Validators.required],
      fecha_movimiento: ["", Validators.required],
      tipomovimiento_id: ["", Validators.required],
      valor_movimiento: [0, Validators.required],
      observacion: [""],
      _ctanumero: [""],
    });

    this._serviceCuentasBancarias
      .get_listar("1", "10")
      .subscribe((resp: any) => {
        this.cuentas_bancarias = resp.data;
        this.blockUI.stop();
      });

    this._serviceTiposMovimiento
      .get_listar("1", "10")
      .subscribe((resp: any) => {
        this.tipos_movimiento = resp.data;
        this.blockUI.stop();
      });

    if (this.isEdit) {
      const id = this._model.movimiento_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceMovimientos
      .getbymovimientoid(id.toString())
      .subscribe((resp: any) => {
        this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
        this._model = resp.data[0];
        this.frm = this.fb.group({
          movimiento_id: [this._model.movimiento_id],
          cuentabancaria_id: [
            this._model.cuentabancaria_id,
            Validators.required,
          ],
          user_id: [this._userSession.user_id, Validators.required],
          fecha_movimiento: [this._model.fecha_movimiento, Validators.required],
          tipomovimiento_id: [
            this._model.tipomovimiento_id,
            Validators.required,
          ],
          valor_movimiento: [
            Number(this._model.valor_movimiento),
            Validators.required,
          ],
          observacion: [this._model.observacion],
        });
        this.blockUI.stop();
      });
  }

  change_tipomovimiento(e) {
    this.isExistSaldoInicial = true;
    if (e.value === 1) {
      this._serviceMovimientos
        .get_existeSaldoInicial()
        .subscribe((resp: any) => {
          this.data_saldo_inicial = resp.data;
          if (this.data_saldo_inicial.length > 0) {
            this.isExistSaldoInicial = true;
            Swal.fire(
              "Atención",
              "El Saldo Inicial ya fue registrado: <br> Fecha: " +
                this.data_saldo_inicial[0].fecha_movimiento +
                " | Valor: " +
                formatNumber(
                  this.data_saldo_inicial[0].valor_movimiento,
                  this.locale,
                  "1.1-2"
                ),
              "warning"
            );
          }
          console.log(this.isExistSaldoInicial);
        });
    } else {
      this.isExistSaldoInicial = false;
    }
  }
}
