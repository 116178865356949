import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginadorService {

  registros$ = new EventEmitter<any>();
  page$ =  new EventEmitter<any>();
  recxpag$ = new EventEmitter<any>();

  constructor() { }
}
