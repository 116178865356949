import { Injectable } from "@angular/core";
import { URL_API } from "../config/config";
import { Role } from "../models/auth/roles.model";
import { Observable } from "rxjs";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  httpOptions = {};

  constructor(private http: HttpClient) {}

  get_listar(page: string, limit: string) {
    this.loadAuth();
    const url = URL_API + "/roles/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API + "/roles/get_buscar/" + pagina + "/" + limit + "/" + buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    this.loadAuth();
    const url = URL_API + "/roles/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  update(id: string, model: Role) {
    this.loadAuth();
    const url = URL_API + "/roles/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    this.loadAuth();
    const url = URL_API + "/roles/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }

  getRoles(): Observable<Role[]> {
    this.loadAuth();
    let url = URL_API + "/roles/listar";
    return this.http.get<Role[]>(url, this.httpOptions);
  }

  getbyid(id: string): Observable<Role> {
    this.loadAuth();
    let url = URL_API + "/roles/get_byid/" + id;
    return this.http.get<Role>(url, this.httpOptions);
  }

  getRolesByUserId(id: string) {
    this.loadAuth();
    let url = URL_API + "/roles/get_rolesbyuserid/" + id;
    return this.http.get(url, this.httpOptions).pipe(map((resp) => resp));
  }

  getRolesSinAsignarByUserId(user_id: string) {
    this.loadAuth();
    let url = URL_API + "/roles/get_roles_sinasignar_byuserid/" + user_id;
    return this.http.get(url, this.httpOptions).pipe(map((resp) => resp));
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
