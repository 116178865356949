import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { PlanillaLiquidacion } from "src/app/models/planillas-liquidacion.model";
import { AppState } from "../../../app.reducers";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { cargarPlanillasLiquidacion } from "../planillas-liquidacion/store/actions";
import { cargarAnios } from "../anios/store/actions";
import { FormBuilder, FormGroup } from "@angular/forms";
import pageSettings from "src/app/config/page-settings";
import { PlNewEditComponent } from "./new-edit/new-edit.component";
import { PlanillasLiquidacionService } from "./services/planillas-liquidacion.service";
import Swal from "sweetalert2";
import { Anio } from "src/app/models/anios.model";
import { selectAnios } from "../anios/store/selectors/anios.selectors";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-planillas-liquidacion",
  templateUrl: "./planillas-liquidacion.component.html",
  styleUrls: ["./planillas-liquidacion.component.css"],
})
export class PlanillasLiquidacionComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  pageSettings = pageSettings;
  buscando: boolean = false;

  arrayModel: PlanillaLiquidacion[] = [];
  anios: Anio[] = [];
  loading: boolean = false;
  error: any;
  ppSubs: Subscription;

  frmBuscar: FormGroup;
  _planillaliquidacion_id: number;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _serviceModel: PlanillasLiquidacionService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.listar();
    this.listar_anios();
  }

  ngOnDestroy(): void {
    this.ppSubs.unsubscribe();
  }

  receiveMessage($event: any) {
    if (this.frmBuscar.controls._txtBuscar.value === "") {
      this.buscando = false;
    }

    if (!this.buscando) {
      // this.getLista($event.pagina, $event.recxpag);
    }

    if (this.buscando) {
      const value = this.frmBuscar.controls._txtBuscar.value;
      // this.getBuscar($event.pagina, $event.recxpag, value);
    }
  }

  listar() {
    this.store.dispatch(cargarPlanillasLiquidacion());
    this.ppSubs = this.store
      .select("planillasliquidacion")
      .subscribe(({ items, loading }) => {
        this.arrayModel = items;
        this.loading = loading;
      });
  }

  async listar_anios() {
    this.store.dispatch(cargarAnios({ pagina: "1", limit: "100" }));
    this.store.select(selectAnios).pipe(
      tap((e: any) => {
        this.anios = e.items;
        this.loading = e.loading;
      })
    );
  }

  update(item: any) {
    console.log(item);
    // const ref = this.modalService.open(NewEditComponent);
  }

  new(model: PlanillaLiquidacion) {
    const ref = this.modalService.open(PlNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceModel.create(data.frm).subscribe((resp: any) => {
            console.log(resp);
            if (resp.status === "ok") {
              this.listar();
            } else {
              Swal.fire("Atención", resp.msg, "error");
            }
          });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  Buscar() {}

  edit(model: PlanillaLiquidacion) {
    const ref = this.modalService.open(PlNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._planillaliquidacion_id =
      model.planillaliquidacion_id;
    ref.result.then(
      (data) => {
        console.log(data.frm);
        if (data.valido) {
          this._serviceModel
            .update(model.planillaliquidacion_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.listar();
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: any) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html:
        "<p><b>Año:</b> " +
        model.anio +
        ", <b>Consecutivo: " +
        model.consecutivo +
        "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceModel
          .getdelete(model.planillaliquidacion_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.listar();
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
