import { Injectable } from "@angular/core";
import { URL_API } from "../config/config";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { UserDespacho } from "../models/users_despachos.model";

@Injectable({
  providedIn: "root",
})
export class UsersDespachosService {
  httpOptions = {};

  constructor(private http: HttpClient) {}

  getLista() {
    const url = URL_API + "/users_despachos/get_lista";
    return this.http.get(url, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + "/users_despachos/get_byid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_users_despachos(page: string, limit: string) {
    const url =
      URL_API +
      "/users_despachos/get_listar_users_despachos/" +
      page +
      "/" +
      limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url =
      URL_API +
      "/users_despachos/get_buscar/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    this.loadAuth();
    const url = URL_API + "/users_despachos/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  update(id: string, model: UserDespacho) {
    const url = URL_API + "/users_despachos/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    this.loadAuth();
    const url = URL_API + "/users_despachos/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
