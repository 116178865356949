import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ItCloudSmsService } from "../services/it-cloud-sms.service";
import { ITCloudSMSInterface } from "../interfaces/it-cloud-sms.interface";
import { QRCodeElementType, QRCodeErrorCorrectionLevel } from "angularx-qrcode";
import { QrcodeService } from "src/app/qrcode/qrcode.service";
import { HttpClient } from "@angular/common/http";
import { FileuploadService } from "src/app/uploadfile/fileupload.service";

@Component({
  selector: "app-enviar-mensaje",
  templateUrl: "./enviar-mensaje.component.html",
  styleUrls: ["./enviar-mensaje.component.css"],
})
export class EnviarMensajeComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  heightSection: number = 475;
  form: FormGroup;
  qrCodeImage: string;
  imageUrl: string = "";
  images: any;
  multipleImages = [];
  pid: number = 0;
  urlQrCode: string = "";
  _celular: string = "";

  SMS_EMAIL = "oscarboc@hotmail.com";
  SMS_TOKEN = "1jv6vudtg8y8i7kk16e3p";
  SMS_ENDPOINT =
    "https://contacto-masivo.com/sms/back_sms/public/api/send/sms/json";
  SMS_TYPESEND = "1via";

  constructor(
    private fb: FormBuilder,
    private serviceSms: ItCloudSmsService,
    private qrCodeService: QrcodeService,
    private http: HttpClient,
    private fileUploadService: FileuploadService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      cellphone: ["3183819277", Validators.required],
      message: [
        "Probando envío de mensajes desde Colectivos",
        Validators.required,
      ],
    });
  }

  enviar_mensaje(urlQr: string, cellph: string) {
    // this.qrCodeService.generateQRCode(frm.message).then((resp) => {
    /* const datax = [
      {
        cellphone: frm.cellphone,
        message: frm.message,
      },
    ]; */

    const datax = [
      {
        cellphone: cellph,
        message: urlQr,
      },
    ];
    const objSendSMS = [
      {
        email: this.SMS_EMAIL,
        token: this.SMS_TOKEN,
        type_send: this.SMS_TYPESEND,
        data: datax,
      },
    ];

    this.serviceSms
      .sendSms(this.SMS_ENDPOINT, objSendSMS[0])
      .subscribe((resp) => {
        console.log(resp);
      });
    // });
  }

  enviar_mensaje_form(frm: any) {
    this.qrCodeService.generateQRCode(frm.message).then((resp) => {
      const datax = [
        {
          cellphone: frm.cellphone,
          message: frm.message,
        },
      ];

      const objSendSMS = [
        {
          email: this.SMS_EMAIL,
          token: this.SMS_TOKEN,
          type_send: this.SMS_TYPESEND,
          data: datax,
        },
      ];

      this.serviceSms
        .sendSms(this.SMS_ENDPOINT, objSendSMS[0])
        .subscribe((resp) => {
          console.log(resp);
        });
    });
  }

  selectImage(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.images = file;
    }
  }

  selectMultipleImage(event) {
    if (event.target.files.length > 0) {
      this.multipleImages = event.target.files;
    }
  }

  onSubmitImage(phone: string) {
    this.fileUploadService
      .uploadFile(this.images, this.pid.toString())
      .then((resp) => {
        this.urlQrCode = `${resp}.png`;
        this.enviar_mensaje(
          `Se ha Generado Planilla No. ${this.pid.toString()} para el Vehículo 175. Pulse sobre el link ${
            this.urlQrCode
          }`,
          phone
        );
      });
  }

  onMultipleSubmit() {
    const formData = new FormData();
    for (let img of this.multipleImages) {
      formData.append("files", img);
    }

    this.http
      .post<any>("http://localhost:3000/multipleFiles", formData)
      .subscribe(
        (res) => console.log(res),
        (err) => console.log(err)
      );
  }

  generar_qr(phone: number, planilla: number) {
    this.pid = planilla; // Math.random();
    console.log(this.pid);
    console.log(this._celular);
    this.qrCodeService
      .generateQRCode(this.pid.toString())
      .then((base64Image) => {
        const mimeType = "image/png";
        this.qrCodeImage = base64Image;
        this.base64ToBlob(base64Image, mimeType);
      });
  }

  downloadQRCode(): void {
    if (this.qrCodeImage) {
      this.qrCodeService.downloadQRCode(this.qrCodeImage, "qrcode.png");
    }
  }

  base64ToBlob(base64: string, mime: string) {
    base64 = base64.replace("data:image/png;base64,", "");
    const byteArray = new Uint8Array(
      atob(base64)
        .split("")
        .map((char) => char.charCodeAt(0))
    );

    const fileblob = new Blob([byteArray], { type: mime });
    const imageFile = new File([fileblob], "png", { type: mime });

    this.images = imageFile;
    this.onSubmitImage(this._celular);
  }
}
