import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import Swal from "sweetalert2";
import { name_mes } from "../../../config/globals";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducers";
import { CreditosService } from "../creditos/services/creditos.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-simulador-credito",
  templateUrl: "./simulador-credito.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
      mat-form-field {
        width: 100%;
      }
      div.matfila {
        max-width: 70px !important;
      }

      #content > mat-form-field > div > div.mat-form-field-flex {
        padding: 5px !important;
      }
      .material-icons {
        vertical-align: middle;
      }

      table.tableliquidacion {
        font-size: 16px;
      }
      td.tdtitulo {
        font-weight: 600;
      }

      h4.panel-title.ng-star-inserted {
        font-size: 14px !important;
      }

      .panel.panel-heading.panel-title {
        font-size: 14px;
      }

      .borde-totales-doble {
        border-top: double;
        border-bottom: double;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SimuladorCreditoComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  @ViewChild("bus", { static: false }) numeroElement: ElementRef;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  data_amortizacion = [];
  form: FormGroup;
  _suma_cuotas: number = 0;
  _suma_intereses: number = 0;
  _suma_capital: number = 0;

  constructor(
    private _serviceCreditos: CreditosService,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      fecha_primer_pago: [new Date(), Validators.required],
      monto: [4000000],
      tasa: [2.5],
      cuotas: [12],
    });
  }

  ngOnInit() {}

  get_tabla_amortizacion() {
    const _monto = this.form.value.monto;
    const _tasa = this.form.value.tasa;
    const _cuotas = this.form.value.cuotas;
    const _fechaprimerpago = moment(this.form.value.fecha_primer_pago).format(
      "YYYY-MM-DD 00:00"
    );

    this._serviceCreditos
      .get_simulador_credito(_monto, _tasa, _cuotas, 1, _fechaprimerpago, 0, 1)
      .subscribe((resp) => {
        this.data_amortizacion = resp["data"];
        this.getTotal_Cuota();
        this.getTotal_Intereses();
        this.getTotal_Capital();
      });
  }

  async getTotal_Cuota() {
    this._suma_cuotas = this.data_amortizacion
      .map((m) => m.cuota_mensual)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async getTotal_Intereses() {
    this._suma_intereses = this.data_amortizacion
      .map((m) => m.pago_interes)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async getTotal_Capital() {
    this._suma_capital = this.data_amortizacion
      .map((m) => m.pago_capital)
      .reduce((acc, curr) => +acc + +curr, 0);
  }
}
