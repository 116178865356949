import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as empresaActions from "../actions/empresa.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { EmpresaService } from "src/app/services/empresa.service";
import { of } from "rxjs";

@Injectable()
export class EmpresaEffects {
  constructor(
    private actions$: Actions,
    private empresaService: EmpresaService
  ) {}

  cargarEmpresa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(empresaActions.cargarEmpresa),
      mergeMap((action) =>
        this.empresaService.getById(action.id).pipe(
          map(
            (data) =>
              empresaActions.cargarEmpresaSuccess({
                empresa: data,
              }),
            catchError((err) =>
              of(
                empresaActions.cargarEmpresaError({
                  payload: err,
                })
              )
            )
          )
        )
      )
    )
  );

  addEmpresa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(empresaActions.addEmpresa),
      mergeMap((action) =>
        this.empresaService.create(action.empresa).pipe(
          map((data) =>
            empresaActions.addEmpresaSuccess({
              empresa: data,
            })
          ),
          catchError((err) =>
            of(
              empresaActions.addEmpresaError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
