import { createReducer, on } from "@ngrx/store";
import {
  cargarPlanillasLiquidacion,
  cargarPlanillasLiquidacionError,
  cargarPlanillasLiquidacionSuccess,
} from "../actions";
import { PlanillaLiquidacion } from "../../../../../models/planillas-liquidacion.model";

export interface PlanillasLiquidacionState {
  items: PlanillaLiquidacion[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const planillasliquidacionInitialState: PlanillasLiquidacionState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _planillasliquidacionReducer = createReducer(
  planillasliquidacionInitialState,

  on(cargarPlanillasLiquidacion, (state) => ({ ...state, loading: true })),

  on(
    cargarPlanillasLiquidacionSuccess,
    (state, { planillasliquidacion, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillasliquidacion],
      registros: registros,
      pagecount: pagecount,
    })
  ),

  on(cargarPlanillasLiquidacionError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function planillasliquidacionReducer(state, action) {
  return _planillasliquidacionReducer(state, action);
}
