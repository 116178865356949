import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-access-denied",
  templateUrl: "./access-denied.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .modal-xl {
        max-width: 460px;
      }
    `,
  ],
})
export class AccessDeniedComponent implements OnInit {
  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {}
}
