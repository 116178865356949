import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillasrutasdespachosActions from "../../store/actions/planillas_rutasdespachos.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillaRutasDespachosService } from "../../services/planillas_rutasdespachos.service";
import { of } from "rxjs";

@Injectable()
export class PlanillasRutasDespachosEffects {
  constructor(
    private actions$: Actions,
    private planillasrutasdespachosService: PlanillaRutasDespachosService
  ) {}

  cargarPlanillas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasrutasdespachosActions.cargarPlanillasRutasDespachos),
      mergeMap(() =>
        this.planillasrutasdespachosService.getListar(1, 10).pipe(
          map((resp) =>
            planillasrutasdespachosActions.cargarPlanillasRutasDespachosSuccess(
              {
                planillasrutasdespachos: resp["data"],
                registros: resp["registros"],
                pagecount: resp["pageCount"],
              }
            )
          ),
          catchError((err) =>
            of(
              planillasrutasdespachosActions.cargarPlanillasRutasDespachosError(
                {
                  payload: err,
                }
              )
            )
          )
        )
      )
    )
  );
}
