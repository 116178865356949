import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RolesService } from "../../services/roles.service";
import { Role } from "../../models/auth/roles.model";

@Component({
  selector: "app-roles-new",
  templateUrl: "./roles-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
})
export class RolesNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  isLoading: boolean = false;
  frm: FormGroup;
  _model: Role;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceRoles: RolesService
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.frm = this.fb.group({
      role_id: [0],
      name: ["", Validators.required],
      description: ["", Validators.required],
    });

    if (this.isEdit) {
      const id = this._model.role_id;
      this.setForm(id);
    }
    this.blockUI.stop();
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceRoles.getbyid(id.toString()).subscribe((resp: any) => {
      this._model = resp.data[0];
      this.frm = this.fb.group({
        role_id: [this._model.role_id],
        name: [this._model.role_name, Validators.required],
        description: [this._model.role_description, Validators.required],
      });
      this.blockUI.stop();
    });
  }
}
