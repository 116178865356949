import { createAction, props } from "@ngrx/store";
import { Sancion } from "../../../../../models/sanciones.model";

export const cargarSancion = createAction(
  "[Sanción] Cargar Sanción",
  props<{ id: string }>()
);

export const cargarListaSanciones = createAction(
  "[Lista Sanción] Cargar Lista Sanciones"
);

export const cargarSancionSuccess = createAction(
  "[Sanción] Cargar Sanción Success",
  props<{ sancion: Sancion }>()
);

export const cargarSancionError = createAction(
  "[Sanción] Cargar Sanción Error",
  props<{ payload: any }>()
);
export const addSancion = createAction(
  "[Sanción] Nuevo Sanción",
  props<{ sancion: Sancion }>()
);

export const addSancionSuccess = createAction(
  "[Sanción] Nuevo Sanción Success",
  props<{ sancion: any }>()
);

export const addSancionError = createAction(
  "[Sanción] Nuevo Sanción Error",
  props<{ payload: any }>()
);

export const updSancion = createAction(
  "[Sanción] Update Sanción",
  props<{ sancion: any }>()
);

export const updSancionSuccess = createAction(
  "[Sanción] Update Sanción Success",
  props<{ sancion: any }>()
);

export const updSancionError = createAction(
  "[Sanción] Update Sanción Error",
  props<{ payload: any }>()
);
