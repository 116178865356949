import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillaparametroActions from "../../store/actions/planilla-parametro.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillaParametrosService } from "../../../../pages/planilla-parametros/services/planilla-parametros.service";
import { of } from "rxjs";

@Injectable()
export class PlanillaParametroEffects {
  constructor(
    private actions$: Actions,
    private planillaparametrosService: PlanillaParametrosService
  ) {}

  cargarPlanillaParametro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaparametroActions.cargarParametroAnioActual),
      mergeMap((action) =>
        this.planillaparametrosService.getByAnio(action.id).pipe(
          map(
            (data) =>
              planillaparametroActions.cargarParametroAnioActualSuccess({
                planillaparametro: data,
              }),
            catchError((err) =>
              of(
                planillaparametroActions.cargarParametroAnioActualError({
                  payload: err,
                })
              )
            )
          )
        )
      )
    )
  );

  addPlanillaParametro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaparametroActions.addPlanillaParametro),
      mergeMap((action) =>
        this.planillaparametrosService.create(action.planillaparametro).pipe(
          map((data) =>
            planillaparametroActions.addPlanillaParametroSuccess({
              planillaparametro: data,
            })
          ),
          catchError((err) =>
            of(
              planillaparametroActions.addPlanillaParametroError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
