import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { ReportesService } from "../../services/reportes.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import { TiposReporteService } from "src/app/services/tipos-reporte.service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import Swal from "sweetalert2";
import { name_mes } from "../../config/globals";
import { RutasService } from "src/app/services/rutas.service";
import { CuentasBancariasService } from "src/app/services/cuentas_bancarias.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-reportes-page",
  templateUrl: "./reportes-page.component.html",
  styles: [],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ReportesPageComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  data_rpt_retiros = [];
  data_rpt_arqueo = [];
  data_rpt_arqueo_retiros = [];
  data_rpt_movimientos = [];
  data_rpt_sumatorias = [];
  data_rpt_totales_recaudos = [];
  data_saldoInicial = [];
  form: FormGroup;
  desde: string = "";
  hasta: string = "";
  tiporpt_id: number = 0;
  ruta_id: number = 0;
  tipos_rpt = [];
  cuentas_bancarias = [];
  rutas = [];
  cargando: boolean = false;
  btndisabled: boolean = false;
  xbus: string = "";
  xmes: string = "";
  _chkVehiculoEnabled: boolean = true;
  _chkEmpresaEnabled: boolean = true;
  _chkRutasEnabled: boolean = true;
  _consultando: boolean = false;
  _totalProducidos: number = 0;
  _totalRetiros: number = 0;
  _totalSaldo: number = 0;
  public cuentabancaria_id = 1;
  saldoviene: number = 0;

  constructor(
    private _serviceReportes: ReportesService,
    private _servcieTiposReporte: TiposReporteService,
    private _serviceRutas: RutasService,
    private _serviceCuentasBancarias: CuentasBancariasService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      fecha_desde: [new Date(), Validators.required],
      fecha_hasta: [new Date(), Validators.required],
      tiporeporte_id: [1, Validators.required],
      ruta_id: [1],
      cuentabancaria_id: [1],
      numero: [""],
      chkvehiculo: [false],
      chkempresa: [false],
      chkrutas: [false],
    });
  }

  ngOnInit() {
    this._servcieTiposReporte.getLista().subscribe((resp: any) => {
      this.tipos_rpt = resp.data;
      this.form.controls["tiporeporte_id"].patchValue(1);
    });

    this._serviceRutas.get_listar("1", "20").subscribe((resp: any) => {
      this.rutas = resp.data;
      this.form.controls["ruta_id"].patchValue(1);
    });
    this._serviceCuentasBancarias
      .get_listar("1", "20")
      .subscribe((resp: any) => {
        this.cuentas_bancarias = resp.data;
        this.form.controls["cuentabancaria_id"].patchValue(1);
      });
  }

  get fdata() {
    return this.form.controls;
  }

  consultar() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.desde = moment(this.form.controls.fecha_desde.value).format(
      "YYYY-MM-DD 00:00"
    );
    this.hasta = moment(this.form.controls.fecha_hasta.value).format(
      "YYYY-MM-DD 23:59"
    );

    this.xmes =
      name_mes[
        parseInt(moment(this.form.controls.fecha_desde.value).format("MM")) - 1
      ];

    this.data_rpt_retiros = [];
    this.data_rpt_movimientos = [];
    this.data_rpt_arqueo = [];
    this.data_rpt_totales_recaudos = [];
    let _condicion = "";
    this._serviceReportes.EventDatosRecaudos$.emit([]);
    this._serviceReportes.EventDatosTotalesRecaudos$.emit([]);
    this._serviceReportes.FechaDesde$.emit(this.desde);
    this._serviceReportes.FechaHasta$.emit(this.hasta);
    this._consultando = true;

    switch (this.tiporpt_id) {
      case 1:
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false &&
          this.form.controls.chkrutas.value === false
        ) {
          this._chkVehiculoEnabled = false;
          this._chkEmpresaEnabled = false;
          this._chkRutasEnabled = false;
          this.xbus = "-1";
          this._serviceReportes.mes$.emit(this.xmes);
          this._serviceReportes.bus$.emit(this.xbus);
          this._serviceReportes
            .getrecaudos("1", "10", this.desde, this.hasta, _condicion)
            .subscribe((resp: any) => {
              this.data_rpt_retiros = resp.data;
              this._serviceReportes.EventDatosRecaudos$.emit(resp.data);
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }

        if (this.form.controls.chkvehiculo.value === true) {
          if (this.form.controls.numero.value === "") {
            Swal.fire(
              "Precaución",
              "Número de Vehículo no válido...",
              "warning"
            );
            this.blockUI.stop();
            return false;
          } else {
            this._chkVehiculoEnabled = true;
            this.xbus = this.form.controls.numero.value;
            this._serviceReportes.mes$.emit(this.xmes);
            this._serviceReportes.bus$.emit(this.xbus);
            this._serviceReportes
              .getrecaudosByBus(
                "1",
                "10",
                this.desde,
                this.hasta,
                this.form.controls.numero.value
              )
              .subscribe((resp: any) => {
                this._serviceReportes.EventDatosRecaudos$.emit(resp.data);
                this.blockUI.stop();
                if (resp.data.length === 0) {
                  Swal.fire(
                    "Atención",
                    "No se encontraron datos para esta consulta...",
                    "info"
                  );
                }
              });
          }
        }

        if (this.form.controls.chkempresa.value === true) {
          this._serviceReportes.mes$.emit(this.xmes);
          this._serviceReportes.bus$.emit(this.xbus);
          this._serviceReportes
            .getrecaudosByEmpresa("1", "10", this.desde, this.hasta)
            .subscribe((resp: any) => {
              this._serviceReportes.EventDatosRecaudos$.emit(resp.data);
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }

        if (this.form.controls.chkrutas.value === true) {
          this._serviceReportes.mes$.emit(this.xmes);
          this._serviceReportes.bus$.emit(this.xbus);
          this._serviceReportes
            .getrecaudosByRuta(
              "1",
              "10",
              this.desde,
              this.hasta,
              this.form.controls.ruta_id.value
            )
            .subscribe((resp: any) => {
              this._serviceReportes.EventDatosRecaudos$.emit(resp.data);
              this.blockUI.stop();
              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
            });
        }

        break;
      case 3:
        if (
          this.form.controls.chkvehiculo.value === false &&
          this.form.controls.chkempresa.value === false
        ) {
          this._chkVehiculoEnabled = false;
          this._serviceReportes
            .getretiros("1", "10", this.desde, this.hasta, _condicion)
            .subscribe((resp: any) => {
              this.data_rpt_retiros = resp.data;

              this.data_rpt_retiros.forEach((x) => {
                x.valor_retiro = parseFloat(x.valor_retiro);
                x.administracion = parseFloat(x.administracion);
                x.cooperativa = parseFloat(x.cooperativa);
                x.aportes = parseFloat(x.aportes);
                x.otros = parseFloat(x.otros);
                x.valor_neto = parseFloat(x.valor_neto);
              });

              if (resp.data.length === 0) {
                Swal.fire(
                  "Atención",
                  "No se encontraron datos para esta consulta...",
                  "info"
                );
              }
              this.blockUI.stop();
            });
        } else {
          if (this.form.controls.numero.value === "") {
            Swal.fire(
              "Precaución",
              "Número de Vehículo no válido...",
              "warning"
            );
            return false;
          } else {
            this._chkVehiculoEnabled = true;
            this._serviceReportes
              .getretirosByBus(
                "1",
                "10",
                this.desde,
                this.hasta,
                this.form.controls.numero.value
              )
              .subscribe((resp: any) => {
                this.data_rpt_retiros = resp.data;
                if (resp.data.length > 0) {
                  this._serviceReportes
                    .get_totalesbyvehiculoPivot(resp.data[0].vehiculo_id)
                    .subscribe((resp: any) => {
                      this.data_rpt_sumatorias = resp.data;
                      console.log("Totales => ", resp.data);
                    });
                }
                if (resp.data.length === 0) {
                  Swal.fire(
                    "Atención",
                    "No se encontraron datos para esta consulta...",
                    "info"
                  );
                }
                this.blockUI.stop();
              });
          }
        }
        break;
      case 4:
        this._serviceReportes
          .get_arqueo("1", "10", this.desde, this.hasta)
          .subscribe(async (resp: any) => {
            this.data_rpt_arqueo = await resp.data;
            if (resp.data.length === 0) {
              Swal.fire(
                "Atención",
                "No se encontraron datos para esta consulta...",
                "info"
              );
            }
            this.blockUI.stop();
          });
        break;

      case 5:
        this._serviceReportes
          .get_movimientosSaldoViene(
            "1",
            "10",
            this.desde,
            this.hasta,
            this.form.controls.cuentabancaria_id.value
          )
          .subscribe(async (resp: any) => {
            await resp.data;
            this.saldoviene =
              resp.data[0].total_debitos - resp.data[0].total_creditos;
            this._serviceReportes.saldoviene$.emit(this.saldoviene);
          });

        this._serviceReportes
          .get_movimientosbycuenta(
            "1",
            "10",
            this.desde,
            this.hasta,
            this.form.controls.cuentabancaria_id.value
          )
          .subscribe(async (resp: any) => {
            this.data_rpt_movimientos = await resp.data;

            if (resp.data.length > 0) {
              this.data_rpt_movimientos.unshift({
                cuenta_banco: this.data_rpt_movimientos[0].cuenta_banco,
                fecha_movimiento: moment(new Date()).format("DD-MM-YYYY"),
                tipo_movimiento_nombre: "Saldo que viene",
                debitos: 0,
                creditos: 0,
                saldo: this.saldoviene,
              });
            }

            let saldo_que_pasa = this.saldoviene;
            this.data_rpt_movimientos.forEach((x) => {
              x.debitos = parseFloat(x.debitos);
              x.creditos = parseFloat(x.creditos);
              x.valor_movimiento = parseFloat(x.valor_movimiento);
              x.saldo =
                saldo_que_pasa + parseFloat(x.debitos) - parseFloat(x.creditos);
              saldo_que_pasa = x.saldo;
            });
            this._serviceReportes.saldoquepasa$.emit(saldo_que_pasa);
            if (resp.data.length === 0) {
              Swal.fire(
                "Atención",
                "No se encontraron datos para esta consulta...",
                "info"
              );
            }
            this.blockUI.stop();
          });

        break;

      case 6:
        this._serviceReportes
          .gettotalesrecaudos("1", "10", this.desde, this.hasta)
          .subscribe((resp: any) => {
            this.data_rpt_totales_recaudos = resp.data;
            this._serviceReportes.EventDatosTotalesRecaudos$.emit(resp.data);
            this.blockUI.stop();
            if (resp.data.length === 0) {
              Swal.fire(
                "Atención",
                "No se encontraron datos para esta consulta...",
                "info"
              );
            }
          });
        break;

      default:
        break;
    }
  }

  change_bus(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.form.controls["numero"].setValue(value);
    }
  }

  change_chk() {
    if (this.form.controls["chkvehiculo"].value) {
      this.form.controls["chkempresa"].patchValue(false);
    }
  }
  change_chkEmpresa(e) {
    if (this.form.controls["chkempresa"].value) {
      this.form.controls["chkvehiculo"].patchValue(false);
    }
  }

  change_opcion() {
    this.data_rpt_retiros = [];
    this.data_rpt_totales_recaudos = [];
  }
}
