import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";
import { RetiroDescuento } from "../models/retiro-descuentos.model";

@Injectable({
  providedIn: "root",
})
export class RetiroDescuentosService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(private http: HttpClient) {}

  get_listar(page: string, limit: string) {
    const url = URL_API + "/retiro_descuentos/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_retiros(page: string, limit: string) {
    const url =
      URL_API + "/retiro_descuentos/get_listar_retiros/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_vehiculos_descuentos(page: string, limit: string) {
    const url =
      URL_API +
      "/retiro_descuentos/get_listar_vehiculos_descuentos/" +
      page +
      "/" +
      limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar_descuento_vehiculos(
    pagina: string,
    limit: string,
    buscar: string
  ) {
    const url =
      URL_API +
      "/retiro_descuentos/get_buscar_descuentos_vehiculos/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url =
      URL_API +
      "/retiro_descuentos/get_buscar/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    const url = URL_API + "/retiro_descuentos/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + "/retiro_descuentos/get_byid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  getbyretiroid(id: string) {
    const url = URL_API + "/retiro_descuentos/get_byretiroid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  update(id: string, model: RetiroDescuento) {
    const url = URL_API + "/retiro_descuentos/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    const url = URL_API + "/retiro_descuentos/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }
}
