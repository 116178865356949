import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanillasService } from "../services/planillas.service";
import { Planilla } from "src/app/models/planillas.model";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducers";
import { selectEmpresa } from "src/app/pages/empresa/store/selectors/empresa.selector";
import { filter } from "rxjs/operators";
import { Empresa } from "src/app/models/empresa.model";
import { RutaDespachoLugar } from "src/app/models/rutasdespachos-lugares.model";
import { QRCodeElementType, QRCodeErrorCorrectionLevel } from "angularx-qrcode";
import { EmpresaService } from "src/app/services/empresa.service";
import { FileuploadService } from "src/app/uploadfile/fileupload.service";

@Component({
  selector: "app-planilla-recorrido",
  templateUrl: "./planilla-recorrido.component.html",
  styleUrls: ["./planilla-recorrido.component.css"],
})
export class PlanillaRecorridoComponent implements OnInit  {
  @ViewChild("screenshotElement", { static: true })  screenshotElement: ElementRef;
  _model: Planilla;
  datos_recorrido: RutaDespachoLugar[] = [];
  data_empresa: Empresa;
  _bus: string = "";
  _conductor: string = "";
  _despacho: string = "";
  _placa: string = "";
  _fechaplanilla: Date;
  _consecutivo: number;
  groupedData: any[];
  qrcodename: string;

  public initial_state = {
    allowEmptyString: true,
    alt: "Código de la Planilla",
    ariaLabel: `QR Code Content`,
    colorDark: "#264c79",
    colorLight: "#ffffffff",
    cssClass: "qrcode-center",
    elementType: "svg" as QRCodeElementType,
    errorCorrectionLevel: "H" as QRCodeErrorCorrectionLevel,
    imageSrc: "/assets/img/logoempresa.png",
    imageHeight: 75,
    imageWidth: 75,
    margin: 2,
    qrdata: "",
    scale: 2,
    version: undefined,
    title: "A custom title attribute",
    width: 100,
  };

  public data_model = {
    ...this.initial_state,
  };

  public allowEmptyString: boolean;
  public alt: string;
  public ariaLabel: string;
  public colorDark: string;
  public colorLight: string;
  public cssClass: string;
  public elementType: QRCodeElementType;
  public errorCorrectionLevel: QRCodeErrorCorrectionLevel;
  public imageSrc?: string;
  public imageHeight?: number;
  public imageWidth?: number;
  public margin: number;
  public qrdata: string;
  public scale: number;
  public title: string;
  public width: number;
  public showImage: boolean;

  constructor(
    public modal: NgbActiveModal,
    private _servicePlanilla: PlanillasService,
    private _serviceEmpresa: EmpresaService,
    private fileUploadService: FileuploadService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    /* this.store
      .select(selectEmpresa)
      .pipe(filter((x) => x.item !== null))
      .subscribe((resp) => {
        this.data_empresa = resp.item[0];
      }); */
    this._serviceEmpresa.getById("1").subscribe((resp) => {
      this.data_empresa = resp[0];
    });
    const id = this._model.planilla_id;
    this._bus = this._model.numero;
    this._conductor = this._model.conductor_nombre;
    this._fechaplanilla = this._model.fecha_planilla;
    this._consecutivo = this._model.planilla_id;
    this._despacho = this._model.despacho_nombre;
    this._placa = this._model.placa;
    this.qrdata = this._model.planilla_id.toString();

    this.allowEmptyString = this.data_model.allowEmptyString;
    this.alt = this.data_model.alt;
    this.ariaLabel = this.data_model.ariaLabel;
    this.colorDark = this.data_model.colorDark;
    this.colorLight = this.data_model.colorLight;
    this.cssClass = this.data_model.cssClass;
    this.elementType = this.data_model.elementType;
    this.errorCorrectionLevel = this.data_model.errorCorrectionLevel;
    this.imageSrc = this.showImage ? this.data_model.imageSrc : undefined;
    this.imageHeight = this.showImage ? this.data_model.imageHeight : undefined;
    this.imageWidth = this.showImage ? this.data_model.imageWidth : undefined;
    this.margin = this.data_model.margin;
    this.scale = this.data_model.scale;
    this.title = this.data_model.title;
    this.width = this.data_model.width;

    this._servicePlanilla
      .get_planilla_recorridobyid(id.toString())
      .subscribe((resp) => {
        this.datos_recorrido = resp;
        this.groupData();
      });
  }

  groupData(): void {
    const groups = this.datos_recorrido.reduce((result, ruta) => {
      const group = result.find(
        (item) => item.ruta === ruta.group_ruta_horasalida
      );
      if (group) {
        group.lugares.push(ruta);
      } else {
        result.push({
          ruta: ruta.group_ruta_horasalida,
          lugares: [ruta],
        });
      }
      return result;
    }, []);
    this.groupedData = groups;
    let x = 0;
    this.groupedData.forEach((item) => {
      let acum = 0;
      item.lugares.forEach((element) => {
        acum = acum + element.minuto;
        element.minutos_acumulado = acum;
      });
      x++;
    });
  }

  takeScreenshot() {
    const element = this.screenshotElement.nativeElement;
    this.fileUploadService.takeScreenshot(
      element,
      this._model.conductor_celular,
      this._model.planilla_id,
      this._model.numero
    );
  }
}
