import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TiposCuentasBancariasService } from "../../services/tipos_cuentas_bancarias.service";
import { TipoCuentaBancaria } from "../../models/tipos_cuentas_bancarias.model";

@Component({
  selector: "app-tipos_cuentas_bancarias-new",
  templateUrl: "./tipos_cuentas_bancarias-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
})
export class TiposCuentasBancariasNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  isLoading: boolean = false;
  frm: FormGroup;
  _model: TipoCuentaBancaria;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceTiposCuentasBancarias: TiposCuentasBancariasService
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.frm = this.fb.group({
      tipocuentabancaria_id: [0],
      nombre: ["", Validators.required],
    });

    if (this.isEdit) {
      const id = this._model.tipocuentabancaria_id;
      this.setForm(id);
    }
    this.blockUI.stop();
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceTiposCuentasBancarias
      .getbyid(id.toString())
      .subscribe((resp: any) => {
        this._model = resp.data[0];
        this.frm = this.fb.group({
          tipocuentabancaria_id: [this._model.tipocuentabancaria_id],
          nombre: [this._model.nombre, Validators.required],
        });
        this.blockUI.stop();
      });
  }
}
