import { createAction, props } from "@ngrx/store";
import { RutaDespachoLugar } from "../../../../../models/rutasdespachos-lugares.model";

export const cargarRutaDespachoLugar = createAction(
  "[Ruta Lugar] Cargar Ruta Lugar",
  props<{ id: string }>()
);

export const cargarListaRutasLugares = createAction(
  "[Lista Ruta Lugar] Cargar Lista Ruta Lugar"
);

export const cargarRutaDespachoLugarSuccess = createAction(
  "[Ruta Lugar] Cargar Ruta Lugar Success",
  props<{ rutadespacholugar: RutaDespachoLugar }>()
);

export const cargarRutaDespachoLugarError = createAction(
  "[Ruta Lugar] Cargar Ruta Lugar Error",
  props<{ payload: any }>()
);
export const addRutaDespachoLugar = createAction(
  "[Ruta Lugar] Nuevo Ruta Lugar",
  props<{ rutadespacholugar: RutaDespachoLugar }>()
);

export const addRutaDespachoLugarSuccess = createAction(
  "[Ruta Lugar] Nuevo Ruta Lugar Success",
  props<{ rutadespacholugar: any }>()
);

export const addRutaDespachoLugarError = createAction(
  "[Ruta Lugar] Nuevo Ruta Lugar Error",
  props<{ payload: any }>()
);

export const updRutaDespachoLugar = createAction(
  "[Ruta Lugar] Update Ruta Lugar",
  props<{ rutadespacholugar: any }>()
);

export const updRutaDespachoLugarSuccess = createAction(
  "[Ruta Lugar] Update Ruta Lugar Success",
  props<{ rutadespacholugar: any }>()
);

export const updRutaDespachoLugarError = createAction(
  "[Ruta Lugar] Update Ruta Lugar Error",
  props<{ payload: any }>()
);
