import { Component, OnInit } from "@angular/core";
import { CreditosService } from "./services/creditos.service";
import { CreditoModel } from "./models/creditos-model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { CreditosNewEditComponent } from "./new-edit/creditos-new-edit.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: "app-creditos",
  templateUrl: "./creditos.component.html",
  styleUrls: ["./creditos.component.css"],
})
export class CreditosComponent implements OnInit {
  data_creditos = [];
  frmBuscar: FormGroup;
  frmSearch: FormGroup;
  _limit: number = 20;
  list_limit = [
    { value: 20, name: "20" },
    { value: 50, name: "50" },
    { value: 100, name: "100" },
  ];
  desde: string = "";
  hasta: string = "";

  constructor(
    private _serviceModel: CreditosService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    const fdesde = this.route.snapshot.queryParams["fdesde"];
    const fhasta = this.route.snapshot.queryParams["fhasta"];
    this.frmSearch = this.fb.group({
      fecha_desde: [moment(fdesde).format("YYYY-MM-DD"), Validators.required],
      fecha_hasta: [moment(fhasta).format("YYYY-MM-DD"), Validators.required],
      tiporeportedespacho_id: [1, Validators.required],
      limit: [20],
      numero: [""],
      chkvehiculo: [false],
      chkempresa: [false],
      vehiculo_id: [0],
      despacho_id: [1, Validators.required],
    });
  }

  ngOnInit() {
    this.get_lista(1, 10);
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
  }

  get_lista(pagina: number, regxpagina: number) {
    this._serviceModel.get_lista(pagina, regxpagina).subscribe((resp: any) => {
      this.data_creditos = resp.data;
    });
  }

  new(model: CreditoModel) {
    /* const ref = this.modalService.open(PlanillaNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceModel.create(data.frm).subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.seleccion_query();
            } else {
              Swal.fire("Atención", resp.msg, "error");
            }
          });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    ); */
  }

  async getBuscar(pagina: string, limit: string, buscar: string) {
    // this.blockUI.start("Consultando datos. Espere un momento...");
    /* this.desde = moment(this.frmSearch.controls.fecha_desde.value).format(
      "YYYY-MM-DD 00:00"
    );
    this.hasta = moment(this.frmSearch.controls.fecha_hasta.value).format(
      "YYYY-MM-DD 23:59"
    );
    this.arrayLugaresControl = [];
    this.arrayRutasDespachos = [];
    this.arrayModel = [];
    this.buscaRutas = false;
    this.buscaLugares = false;
    if (buscar.trim().length > 0) {
      console.log("buscar", buscar);
      this.buscando = true;

      if (this.isMostrarTodasPlanillas) {
        this._serviceModel
          .get_buscarbyperiodo(
            this.desde,
            this.hasta,
            this._limit.toString(),
            this.frmBuscar.controls["_txtBuscar"].value
          )
          .subscribe((resp: any) => {
            this.arrayModel = resp["data"];
          });
      } else {
        this._serviceModel
          .get_buscarbyperiodo(
            this.desde,
            this.hasta,
            this._limit.toString(),
            this.frmBuscar.controls["_txtBuscar"].value
          )
          .subscribe((resp: any) => {
            this.arrayModel = resp["data"];
          });
      }
    } else {
      this.seleccion_query();

      // this.blockUI.stop();
    } */
  }

  edit(model: CreditoModel) {
    const ref = this.modalService.open(CreditosNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._credito_id = model.credito_id;
    ref.result.then(
      (data) => {
        if (data.valido) {
          console.log("eeeee", data.frm);
          this._serviceModel
            .update(model.credito_id, data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.get_lista(1, 10);
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: CreditoModel) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de Eliminar el registro?</span>',
      html: "<p><b>Credito:</b> " + model.credito_id + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceModel.delete(model.credito_id).subscribe((resp: any) => {
          if (resp.status === "ok") {
            this.get_lista(1, 10);
            Swal.fire(
              "Información",
              "El registro ha sido eliminado...",
              "success"
            );
          } else {
            Swal.fire("Atención", resp.msg, "warning");
          }
        });
      }
    });
  }
}
