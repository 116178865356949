import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ReportesService } from "../../services/reportes.service";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Movimiento } from "src/app/models/movimientos.model";
import { MovimientosService } from "src/app/services/movimientos.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-movimientos-list-response",
  templateUrl: "./movimientos-list-response.component.html",
  styles: [],
})
export class MovimientosListResponseComponent implements OnInit, OnChanges {
  @Input() datax = [];
  @Input() dataTotales = [];
  tituloReporte = "RELACION DE MOVIMIENTOS";
  fileName = "";
  FechaDesde = "";
  FechaHasta = "";
  Empresa = "";
  loading: boolean = false;
  _mes = "";
  _bus = "";
  _propietario = "";
  frm: FormGroup;
  btnActivo: boolean = true;

  constructor(
    private _serviceReportes: ReportesService,
    private _serviceMovimientos: MovimientosService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.frm = this.fb.group({
      retiro_id: [0],
      vehiculo_id: ["", Validators.required],
      ruta_id: ["", Validators.required],
      propietario_id: ["", Validators.required],
      fecha_retiro: ["", Validators.required],
      valor_retiro: [0, Validators.required],
      administracion: [0, Validators.required],
      cooperativa: [0, Validators.required],
      aportes: [0, Validators.required],
      otros: [0, Validators.required],
      observacion: [""],
      _nombres: [""],
      _numero: [""],
      _total_producido: [0],
      _saldo_pasa: [0],
      _producido_acumulado: [0],
      _total_retiro: [0],
      _saldo: [0],
    });

    this._serviceReportes.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportes.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });

    this._serviceReportes.mes$.subscribe((resp: any) => {
      this._mes = resp;
    });

    this._serviceReportes.bus$.subscribe((resp: any) => {
      this._bus = resp;
      this.frm.controls["_numero"].patchValue(resp);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes => ", changes);
    this.get_Total_Producido();
  }
  get fdata() {
    return this.frm.controls;
  }
  exportAsXLSX(): void {
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let titulos = {
      A: "Fecha",
      B: "Ruta",
      C: "Bus",
      D: "Valor",
    };

    if (this.datax.length === 0) {
      Swal.fire("Advertencia", "No hay datos para exportar...", "warning");
    } else {
      let itemsDisplay: any[] = [];
      itemsDisplay.push(titulos);
      this.datax.map((c) => {
        itemsDisplay.push({
          A: this.datePipe.transform(c.fecha_retiro, "dd/MM/yyyy"),
          B: c.ruta_nombre,
          C: c.numero,
          D: parseFloat(c.valor_retiro),
        });
      });

      let Heading = [["COLECTIVOS CIUDAD DE IPIALES"]];
      let subtitulo = [["RELACION DE RETIROS"]];
      let fecha = [{ a: "MES:", b: strDate }];

      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, Heading);

      XLSX.utils.sheet_add_json(ws, subtitulo, {
        origin: "A2",
        skipHeader: true,
      });

      XLSX.utils.sheet_add_json(ws, fecha, {
        origin: "A3",
        skipHeader: true,
      });
      XLSX.utils.sheet_add_json(ws, itemsDisplay, {
        origin: "A5",
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(wb, ws, "Movimientos");
      this.fileName = `Movimientos${this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd"
      )}.xlsx`;
      XLSX.writeFile(wb, this.fileName);
    }
  }

  exportarMovimientos(): void {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd hh:mm:ss");
    let filename: string = `RETIROS_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY");

    this._serviceReportes.downloadFile(
      this.datax,
      filename,
      this._mes,
      this._bus,
      periodo
    );
  }

  get_Total_Producido() {
    let total = 0;
    this.datax.forEach((x) => {
      total += parseFloat(x.producido);
    });
    if (this.frm && this.datax.length > 0) {
      this.frm.controls["_total_producido"].patchValue(total);
      this.frm.controls["vehiculo_id"].patchValue(this.datax[0].vehiculo_id);
      this.frm.controls["propietario_id"].patchValue(
        this.datax[0].propietario_id
      );
      this.frm.controls["ruta_id"].patchValue(this.datax[0].ruta_id);
      this.frm.controls["_nombres"].patchValue(this.datax[0].nombres);

      this._propietario = this.datax[0].nombres;
    }
    if (this.frm && this.dataTotales.length > 0) {
      this.frm.controls["_producido_acumulado"].patchValue(
        this.dataTotales[0].total_producido
      );

      this.frm.controls["_total_retiro"].patchValue(
        this.dataTotales[0].total_retiro
      );
      this.frm.controls["_saldo"].patchValue(this.dataTotales[0].saldo);
    }
  }

  cancelar() {
    console.log("frm => ", this.frm.value);
  }

  calculos() {
    if (parseFloat(this.frm.controls.valor_retiro.value) > 0) {
      if (
        parseFloat(this.frm.controls.valor_retiro.value) >
        parseFloat(this.frm.controls._total_producido.value)
      ) {
        this.btnActivo = false;
        Swal.fire(
          "Atención",
          "El valor del retiro es mayor que el Producido..",
          "error"
        );
        return;
      }
      this.btnActivo = true;
      let saldo_pasa =
        parseFloat(this.frm.controls._total_producido.value) -
        parseFloat(this.frm.controls.valor_retiro.value);
      this.frm.controls._saldo_pasa.patchValue(saldo_pasa.toFixed(0));
    }
  }

  change(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls["valor_retiro"].setValue(value);
    }
  }

  new_record() {
    if (this.frm.valid) {
      this._serviceMovimientos.create(this.frm.value).subscribe((resp: any) => {
        if (resp.status === "ok") {
          this.router.navigateByUrl("movimientos");
        } else {
          Swal.fire("Atención", resp.msg, "error");
        }
      });
    } else {
      Swal.fire("Atención", "Los datos no son válidos", "error");
    }
  }
}
