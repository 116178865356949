import { createAction, props } from "@ngrx/store";
import { Empresa } from "src/app/models/empresa.model";

export const cargarEmpresa = createAction(
  "[Empresa] Cargar Empresa",
  props<{ id: string }>()
);

export const cargarEmpresaSuccess = createAction(
  "[Empresa] Cargar Empresa Success",
  props<{ empresa: Empresa }>()
);

export const cargarEmpresaError = createAction(
  "[Empresa] Cargar Empresa Error",
  props<{ payload: any }>()
);
export const addEmpresa = createAction(
  "[Empresa] Nuevo Empresa",
  props<{ empresa: Empresa }>()
);

export const addEmpresaSuccess = createAction(
  "[Empresa] Nuevo Empresa Success",
  props<{ empresa: any }>()
);

export const addEmpresaError = createAction(
  "[Empresa] Nuevo Empresa Error",
  props<{ payload: any }>()
);

export const updEmpresa = createAction(
  "[Empresa] Update Empresa",
  props<{ empresa: any }>()
);

export const updEmpresaSuccess = createAction(
  "[Empresa] Update Empresa Success",
  props<{ empresa: any }>()
);

export const updEmpresaError = createAction(
  "[Empresa] Update Empresa Error",
  props<{ payload: any }>()
);
