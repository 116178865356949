import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// import { io, Socket } from "socket.io-client";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  // private socket: Socket;

  constructor() {
    // this.socket = io("http://localhost:5000");
  }

  /* public sendMessage(message: string): void {
    this.socket.emit("mensajeDelCliente", message);
  }

  public getMessage() {
    return new Observable((observer) => {
      this.socket.on("mensajeDelServidor", (message: any) => {
        observer.next(message);
      });
    });
  } */
}
