import { createAction, props } from "@ngrx/store";
import { User } from "../../models/auth/users.model";

export const cargarUsers = createAction("[Usuarios] Cargar Usuarios");

export const cargarUsersSuccess = createAction(
  "[Usuarios] Cargar Usuarios Success",
  props<{ users: User[] }>()
);

export const cargarUsersError = createAction(
  "[Usuarios] Cargar Usuarios Error",
  props<{ payload: any }>()
);
