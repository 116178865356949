import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-planilla',
  templateUrl: './print-planilla.component.html',
  styleUrls: ['./print-planilla.component.css']
})
export class PrintPlanillaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
