import { createReducer, on } from "@ngrx/store";
import {
  cargarAnios,
  cargarAniosByQuery,
  cargarAniosByQueryError,
  cargarAniosByQuerySuccess,
  cargarAniosError,
  cargarAniosSuccess,
} from "../actions";
import { Anio } from "../../../../../models/anios.model";

export interface AniosState {
  items: Anio[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const aniosInitialState: AniosState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _aniosReducer = createReducer(
  aniosInitialState,

  on(cargarAnios, (state) => ({ ...state, loading: true })),

  on(cargarAniosSuccess, (state, { anios, registros, pagecount }) => ({
    ...state,
    loading: false,
    loaded: true,
    items: [...anios],
    registros: registros,
    pagecount: pagecount,
  })),

  on(cargarAniosError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(cargarAniosByQuery, (state) => ({ ...state, loading: true })),
  on(cargarAniosByQuerySuccess, (state, { anios, registros, pagecount }) => ({
    ...state,
    loading: false,
    loaded: true,
    items: [...anios],
    registros: registros,
    pagecount: pagecount,
  })),
  on(cargarAniosByQueryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function aniosReducer(state, action) {
  return _aniosReducer(state, action);
}
