import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";
import { TipoMovimiento } from "../models/tipos_movimiento.model";

@Injectable({
  providedIn: "root",
})
export class TiposMovimientoService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(private http: HttpClient) {}

  get_listar(pagina: string, limit: string) {
    const url =
      URL_API + "/tipos_movimiento/get_listar/" + pagina + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url =
      URL_API +
      "/tipos_movimiento/get_buscar/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    const url = URL_API + "/tipos_movimiento/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + "/tipos_movimiento/get_byid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  update(id: string, model: TipoMovimiento) {
    const url = URL_API + "/tipos_movimiento/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    const url = URL_API + "/tipos_movimiento/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }
}
