import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { RutaDespacho } from "src/app/models/rutas-despachos.model";

@Injectable({
  providedIn: "root",
})
export class RutasDespachosService {
  httpOptions = {};
  private url = URL_API;

  constructor(private http: HttpClient) {}

  getListar(pagina: string, limit: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/rutas_despachos/get_listar/${pagina}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  get_listar(page: string, limit: string) {
    this.loadAuth();
    const url = URL_API + "/rutas_despachos/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_solo_lugares(page: string, limit: string) {
    this.loadAuth();
    const url =
      URL_API + "/rutas_despachos/get_solo_lugares/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API +
      "/rutas_despachos/get_buscar/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  getById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/rutas_despachos/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  getRutaDespacho(planilla_id: string, planillarutadespacho_id: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/rutasdespachos_lugares/get_rutadespacho/${planilla_id}/${planillarutadespacho_id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  create(model: any) {
    this.loadAuth();
    return this.http.post(
      `${this.url}/rutas_despachos/nuevo`,
      model,
      this.httpOptions
    );
  }

  update(id: string, model: RutaDespacho) {
    this.loadAuth();
    return this.http.put(
      `${this.url}/rutas_despachos/update/${id}`,
      model,
      this.httpOptions
    );
  }

  getdelete(id: string) {
    this.loadAuth();
    return this.http.delete(
      `${this.url}/rutas_despachos/delete/${id}`,
      this.httpOptions
    );
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
