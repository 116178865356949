import { createAction, props } from "@ngrx/store";
import { LugarControl } from "../../../../../models/lugares-control.model";

export const cargarLugarControl = createAction(
  "[Lugar Control] Cargar Lugar Control",
  props<{ id: string }>()
);

export const cargarListaLugares = createAction(
  "[Lista Lugar Control] Cargar Lista Lugar Control"
);

export const cargarLugarControlSuccess = createAction(
  "[Lugar Control] Cargar Lugar Control Success",
  props<{ lugarcontrol: LugarControl }>()
);

export const cargarLugarControlError = createAction(
  "[Lugar Control] Cargar Lugar Control Error",
  props<{ payload: any }>()
);
export const addLugarControl = createAction(
  "[Lugar Control] Nuevo Lugar Control",
  props<{ lugarcontrol: LugarControl }>()
);

export const addLugarControlSuccess = createAction(
  "[Lugar Control] Nuevo Lugar Control Success",
  props<{ lugarcontrol: any }>()
);

export const addLugarControlError = createAction(
  "[Lugar Control] Nuevo Lugar Control Error",
  props<{ payload: any }>()
);

export const updLugarControl = createAction(
  "[Lugar Control] Update Lugar Control",
  props<{ lugarcontrol: any }>()
);

export const updLugarControlSuccess = createAction(
  "[Lugar Control] Update Lugar Control Success",
  props<{ lugarcontrol: any }>()
);

export const updLugarControlError = createAction(
  "[Lugar Control] Update Lugar Control Error",
  props<{ payload: any }>()
);
