import { createAction, props } from "@ngrx/store";
import { RutaDespacho } from "../../../../../models/rutas-despachos.model";

export const cargarRutaDespacho = createAction(
  "[Ruta Despacho] Cargar Ruta",
  props<{ id: string }>()
);

export const cargarListaRutas = createAction(
  "[Lista Ruta Despacho] Cargar Lista Ruta"
);

export const cargarRutaDespachoSuccess = createAction(
  "[Ruta Despacho] Cargar Ruta Success",
  props<{ rutadespacho: RutaDespacho }>()
);

export const cargarRutaDespachoError = createAction(
  "[Ruta Despacho] Cargar Ruta Error",
  props<{ payload: any }>()
);
export const addRutaDespacho = createAction(
  "[Ruta Despacho] Nuevo Ruta Despacho",
  props<{ rutadespacho: RutaDespacho }>()
);

export const addRutaDespachoSuccess = createAction(
  "[Ruta Despacho] Nuevo Ruta Despacho Success",
  props<{ rutadespacho: any }>()
);

export const addRutaDespachoError = createAction(
  "[Ruta Despacho] Nuevo Ruta Despacho Error",
  props<{ payload: any }>()
);

export const updRutaDespacho = createAction(
  "[Ruta Despacho] Update Ruta Despacho",
  props<{ rutadespacho: any }>()
);

export const updRutaDespachoSuccess = createAction(
  "[Ruta Despacho] Update Ruta Despacho Success",
  props<{ rutadespacho: any }>()
);

export const updRutaDespachoError = createAction(
  "[Ruta Despacho] Update Ruta Despacho Error",
  props<{ payload: any }>()
);
