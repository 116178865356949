import { AppState } from "src/app/app.reducers";
import { SancionesState } from "../reducers/sanciones.reducer";
import { createSelector } from "@ngrx/store";

export const selectSanciones = (state: AppState) => state.sanciones;

export const selectListSanciones = createSelector(
  selectSanciones,
  (state: SancionesState) => state.items
);
export const selectLoadingSanciones = createSelector(
  selectSanciones,
  (state: SancionesState) => state.loading
);
export const selectFailSanciones = createSelector(
  selectSanciones,
  (state: SancionesState) => state.error
);
