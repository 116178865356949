import { AppState } from "src/app/app.reducers";
import { RutasDespachosState } from "../reducers/rutas-despachos.reducer";
import { createSelector } from "@ngrx/store";

export const selectRutasDespachos = (state: AppState) => state.rutasdespachos;

export const selectListRutasDespachos = createSelector(
  selectRutasDespachos,
  (state: RutasDespachosState) => state.items
);
export const selectLoadingRutasDespachos = createSelector(
  selectRutasDespachos,
  (state: RutasDespachosState) => state.loading
);
export const selectFailRutasDespachos = createSelector(
  selectRutasDespachos,
  (state: RutasDespachosState) => state.error
);
