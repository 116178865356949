import { createAction, props } from "@ngrx/store";
import { User } from "../../models/auth/users.model";

export const cargarUser = createAction(
  "[Usuario] Cargar Usuario",
  props<{ id: string }>()
);

export const cargarUserSuccess = createAction(
  "[Usuario] Cargar Usuario Success",
  props<{ user: User }>()
);

export const cargarUserError = createAction(
  "[Usuario] Cargar Usuario Error",
  props<{ payload: any }>()
);
