import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as lugarcontrolActions from "../../store/actions/lugar-control.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { LugaresControlService } from "../../../../pages/lugares-control/services/lugares-control.service";
import { of } from "rxjs";

@Injectable()
export class LugarControlEffects {
  constructor(
    private actions$: Actions,
    private lugarcontrolsService: LugaresControlService
  ) {}

  cargarLugarControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lugarcontrolActions.cargarLugarControl),
      mergeMap((action) =>
        this.lugarcontrolsService.getById(action.id).pipe(
          map((data) =>
            lugarcontrolActions.cargarLugarControlSuccess({
              lugarcontrol: data,
            })
          ),
          catchError((err) =>
            of(
              lugarcontrolActions.cargarLugarControlError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  addLugarControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lugarcontrolActions.addLugarControl),
      mergeMap((action) =>
        this.lugarcontrolsService.create(action.lugarcontrol).pipe(
          map((data) =>
            lugarcontrolActions.addLugarControlSuccess({
              lugarcontrol: data,
            })
          ),
          catchError((err) =>
            of(
              lugarcontrolActions.addLugarControlError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
