import { Component } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { map } from "rxjs/operators";
import { VehiculosService } from "../../services/vehiculos.service";

@Component({
  selector: "app-vehiculos-validator",
  templateUrl: "./vehiculos-validator.component.html",
  styles: [],
})
export class VehiculosValidatorComponent {
  constructor(public _serviceModel: VehiculosService) {}

  static existingNumeroValidator(
    _serviceModel: VehiculosService,
    modo?: boolean
  ) {
    if (modo === false) {
      return (control: AbstractControl): Promise<null> => Promise.resolve(null);
    } else {
      return (control: AbstractControl) =>
        _serviceModel.numeroExiste(control.value).pipe(
          map((data: any) => {
            return data.count > 0 ? { existingNumeroValidator: true } : null;
          })
        );
    }
  }

  static existingPlacaValidator(
    _serviceModel: VehiculosService,
    modo?: boolean
  ) {
    if (modo === false) {
      return (control: AbstractControl): Promise<null> => Promise.resolve(null);
    } else {
      return (control: AbstractControl) =>
        _serviceModel.placaExiste(control.value).pipe(
          map((data: any) => {
            return data.count > 0 ? { existingPlacaValidator: true } : null;
          })
        );
    }
  }
}
