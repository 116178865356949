import { Component, Input, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PlanillasAbonosSSService } from "./services/planillas-abonos-ss.service";
import { PlanillaAbonoSS } from "src/app/models/planillas-abonos-ss.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanillasAbonosSSNewComponent } from "./planillas-abonos-ss-new.component";
import * as moment from "moment";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "../../templates/block-template.component";
import pageSettings from "src/app/config/page-settings";

@Component({
  selector: "app-planillas-abonos-ss-list-response",
  templateUrl: "./planillas-abonos-ss-list-response.component.html",
  styles: [],
})
export class PlanillasAbonosSSListResponseComponent implements OnInit {
  @Input() datax = [];
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  pageSettings = pageSettings;
  tituloReporte = "RELACION DE ABONOS SS";
  fileName = "";
  FechaDesde = "";
  FechaHasta = "";
  Empresa = "";
  loading: boolean = false;
  _mes = "";
  _bus = "";
  _propietario = "";
  _vehiculo_id: number = 0;
  _numero: number = 0;
  frm: FormGroup;
  btnActivo: boolean = true;

  constructor(
    private _servicePlanillasAbonosSS: PlanillasAbonosSSService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.frm = this.fb.group({
      planillaabonoss_id: [0],
      vehiculo_id: ["", Validators.required],
      propietario_id: ["", Validators.required],
      fecha_abono: ["", Validators.required],
      valor_abono: [0, Validators.required],
      observacion: [""],
      _nombres: [""],
      _numero: [""],
    });

    this._servicePlanillasAbonosSS.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._servicePlanillasAbonosSS.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });

    this._servicePlanillasAbonosSS.mes$.subscribe((resp: any) => {
      this._mes = resp;
    });

    this._servicePlanillasAbonosSS.bus$.subscribe((resp: any) => {
      this._bus = resp;
      // this.frm.controls["_numero"].patchValue(resp);
    });
  }

  consultar() {
    this.blockUI.start("Consultando datos. Espere un momento...");

    this._servicePlanillasAbonosSS
      .get_list_abonosbybus(
        "1",
        "10",
        this.FechaDesde,
        this.FechaHasta,
        this._bus
      )
      .subscribe((resp: any) => {
        this.datax = resp.data;
        this.blockUI.stop();
        if (resp.data.length === 0) {
          Swal.fire(
            "Atención",
            "No se encontraron datos para esta consulta...",
            "info"
          );
        }
      });
  }

  edit(model: PlanillaAbonoSS) {
    console.log("edit");
    const ref = this.modalService.open(PlanillasAbonosSSNewComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._nombres = model.propietario_nombres;
    ref.result.then(
      (data) => {
        if (data.valido) {
          const fechaabono = moment(data.frm.fecha_abono);
          data.frm.fecha_abono = fechaabono;
          this._servicePlanillasAbonosSS
            .update(model.planillaabonoss_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.consultar();
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: PlanillaAbonoSS) {
    console.log("delete");
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: "<p><b>Valor Abono:</b> " + model.valor_abono + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._servicePlanillasAbonosSS
          .getdelete(model.planillaabonoss_id.toString())
          .subscribe((resp: any) => {
            console.log("resp: ", resp);
            if (resp.status === "ok") {
              this.consultar();
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  registrar_abono(model: any) {
    console.log("model", model);
    const ref = this.modalService.open(PlanillasAbonosSSNewComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        console.log("Guardar: ", data);
        if (data.valido) {
          this._servicePlanillasAbonosSS
            .create(data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.consultar();
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  get fdata() {
    return this.frm.controls;
  }

  cancelar() {
    console.log("frm => ", this.frm.value);
  }

  change(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls["valor_abono"].setValue(value);
    }
  }
}
