import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { PlanillaSancion } from "src/app/models/planillas-sanciones.model";

@Injectable({
  providedIn: "root",
})
export class PlanillasSancionesService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };
  private url = URL_API;

  constructor(private http: HttpClient) {}

  getListar(pagina: number, limit: number) {
    return this.http
      .get(
        `${this.url}/planillas_sanciones/get_listar/${pagina}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getById(id: string) {
    return this.http
      .get(`${this.url}/planillas_sanciones/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  getByPlanillaId(planilla_id: string) {
    return this.http
      .get(`${this.url}/planillas_sanciones/get_byplanillaid/${planilla_id}`)
      .pipe(map((resp) => resp["data"]));
  }

  create(model: any) {
    return this.http.post(
      `${this.url}/planillas_sanciones/nuevo`,
      model,
      this.httpOptions
    );
  }

  update(id: string, model: PlanillaSancion) {
    return this.http.put(
      `${this.url}/planillas_sanciones/update/${id}`,
      model,
      this.httpOptions
    );
  }

  getdelete(id: string) {
    return this.http.delete(
      `${this.url}/planillas_sanciones/delete/${id}`,
      this.httpOptions
    );
  }

  getdeleteByPlanillaSancion(planilla_id: string, sancion_id: string) {
    return this.http.delete(
      `${this.url}/planillas_sanciones/deletebyplanillasancion/${planilla_id}/${sancion_id}`,
      this.httpOptions
    );
  }
}
