import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillarutadespachoActions from "../../store/actions/planilla_rutadespacho.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillaRutasDespachosService } from "../../../../pages/planillas-rutasdespachos/services/planillas_rutasdespachos.service";
import { of } from "rxjs";

@Injectable()
export class PlanillaRutaDespachoEffects {
  constructor(
    private actions$: Actions,
    private planillasrutasdespachosService: PlanillaRutasDespachosService
  ) {}

  cargarPlanillaRutaDespacho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillarutadespachoActions.cargarPlanillaRutaDespacho),
      mergeMap((action) =>
        this.planillasrutasdespachosService.getById(action.id).pipe(
          map((data) =>
            planillarutadespachoActions.cargarPlanillaRutaDespachoSuccess({
              planillarutadespacho: data,
            })
          ),
          catchError((err) =>
            of(
              planillarutadespachoActions.cargarPlanillaRutaDespachoError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  addPlanilla$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillarutadespachoActions.addPlanillaRutaDespacho),
      mergeMap((action) =>
        this.planillasrutasdespachosService
          .create(action.planillarutadespacho)
          .pipe(
            map((data) =>
              planillarutadespachoActions.addPlanillaRutaDespachoSuccess({
                planillarutadespacho: data,
              })
            ),
            catchError((err) =>
              of(
                planillarutadespachoActions.addPlanillaRutaDespachoError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );
}
