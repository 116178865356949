import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { PlanillaRutaDespacho } from "src/app/models/planillas_rutasdespachos.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlanillaRutasDespachosService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };
  private url = URL_API;

  constructor(private http: HttpClient) {}

  getListar(pagina: number, limit: number) {
    return this.http
      .get(
        `${this.url}/planillas_rutasdespachos/get_listar/${pagina}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getById(id: string) {
    return this.http
      .get(`${this.url}/planillas_rutasdespachos/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  getLiquidacionById(id: string) {
    return this.http
      .get(`${this.url}/planillas_rutasdespachos/get_liquidacionbyid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  getrutasByplanillaId(planilla_id: string) {
    return this.http
      .get(
        `${this.url}/planillas_rutasdespachos/get_rutasbyplanillaid/${planilla_id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  getrutasByRutaDespachoId(planillarutadespacho_id: string) {
    return this.http
      .get(
        `${this.url}/planillas_rutasdespachos/get_rutasbyrutadespachoid/${planillarutadespacho_id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  get_ultima_Llegada(id: string) {
    return this.http
      .get(
        `${this.url}/planillas_rutasdespachos/get_planilla_llegadabyid/${id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  get_ultima_ruta_asignada(id: string) {
    return this.http
      .get(
        `${this.url}/planillas_rutasdespachos/get_ultima_ruta_asignada/${id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  get_fechahora_salida(
    fechaHora: string,
    vehiculo_id: number
  ): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.url}/planillas_rutasdespachos/get_fechahora_salida/${fechaHora}/${vehiculo_id}`
    );
  }

  create(model: any) {
    return this.http.post(
      `${this.url}/planillas_rutasdespachos/nuevo`,
      model,
      this.httpOptions
    );
  }

  update(id: string, model: PlanillaRutaDespacho) {
    return this.http.put(
      `${this.url}/planillas_rutasdespachos/update/${id}`,
      model,
      this.httpOptions
    );
  }

  updateliquidacion(id: string, model: PlanillaRutaDespacho) {
    return this.http.put(
      `${this.url}/planillas_rutasdespachos/updateliquidacion/${id}`,
      model,
      this.httpOptions
    );
  }

  getdelete(id: string) {
    return this.http.delete(
      `${this.url}/planillas_rutasdespachos/delete/${id}`,
      this.httpOptions
    );
  }

  get_delete_ruta_asignada(id: string) {
    return this.http.delete(
      `${this.url}/planillas_rutasdespachos/get_delete_ruta_asignada/${id}`,
      this.httpOptions
    );
  }
}
