import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { URL_API_NESTJS } from "src/app/config/config";
import { EstadoCreditoModel } from "../models/estado-credito-model";

@Injectable({
  providedIn: "root",
})
export class EstadosCreditoService {
  private url = URL_API_NESTJS;

  constructor(private http: HttpClient) {}

  get_lista() {
    return this.http.get(`${this.url}/cop-estados-credito`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getById(id: number) {
    return this.http.get(`${this.url}/cop-estados-credito/${id}`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  create(model: any) {
    return this.http.post(`${this.url}/cop-estados-credito`, model);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/cop-estados-credito/${id}`);
  }

  update(id: number, model: EstadoCreditoModel) {
    return this.http.put(`${this.url}/cop-estados-credito/${id}`, model);
  }
}
