import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as sancionesActions from "../../store/actions/sanciones.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { SancionesService } from "../../../../pages/sanciones/services/sanciones.service";
import { of } from "rxjs";

@Injectable()
export class SancionesEffects {
  constructor(
    private actions$: Actions,
    private sancionesService: SancionesService
  ) {}

  cargarSanciones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sancionesActions.cargarSanciones),
      mergeMap((action) =>
        this.sancionesService.getListar(action.pagina, action.limit).pipe(
          map((resp) =>
            sancionesActions.cargarSancionesSuccess({
              sanciones: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              sancionesActions.cargarSancionesError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  cargarSancionesByQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sancionesActions.cargarSancionesByQuery),
      mergeMap((action) =>
        this.sancionesService
          .get_buscar(action.pagina, action.limit, action.query)
          .pipe(
            map((resp) =>
              sancionesActions.cargarSancionesByQuerySuccess({
                sanciones: resp["data"],
                registros: resp["registros"],
                pagecount: resp["pageCount"],
              })
            ),
            catchError((err) =>
              of(
                sancionesActions.cargarSancionesByQueryError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );
}
