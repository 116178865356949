import { Injectable } from "@angular/core";
import { error } from "console";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import Swal from "sweetalert2";
import { ItCloudSmsService } from "../sms/services/it-cloud-sms.service";

@Injectable({
  providedIn: "root",
})
export class FileuploadService {
  private apiUrl = "http://151.106.109.234:5000/file/";
  urlQrCode: string;

  constructor(private icloudService: ItCloudSmsService) {}

  uploadFile(file: File, pid: string): Promise<string> {
    const formData = new FormData();
    formData.append("file", file);
    return fetch(`${this.apiUrl}${pid}`, {
      method: "POST",
      body: formData,
    })
      .then((resp: any) => {
        console.log("Respuesta: ", resp);
        if (resp.statusText.toLowerCase() == "ok") {
          return resp.url.replace("file", "uploads");
        } else {
          return "Error";
        }
      })
      .catch((error) => {
        console.log("Error => ", error);
        return "Error";
      });
  }

  takeScreenshot(
    element: HTMLElement,
    cellphone: string,
    pid: number,
    veh: string
  ) {
    html2canvas(element).then((canvas) => {
      canvas.toBlob((blob) => {
        const file = new File([blob], pid.toString() + ".png", {
          type: "image/png",
        });

        this.uploadFile(file, pid.toString()).then((resp) => {
          if (resp !== "Error") {
            this.urlQrCode = `${resp}.png`;
            const msg = `Se ha Generado Planilla No. ${pid.toString()} para el Vehículo ${veh}. Pulse sobre el link ${
              this.urlQrCode
            }`;
            this.icloudService
              .sendSms_Planilla(cellphone, msg)
              .subscribe((resp: any) => {
                Swal.fire("Atención", resp.message, "success");
              });
          } else {
            Swal.fire(
              "Atención",
              "El Archivo QR no fue subido correctamente...",
              "warning"
            );
          }
        });
        // saveAs(blob, filename);
      });
    });
  }
}
