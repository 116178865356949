import { Component, OnInit } from "@angular/core";
import { LineasCreditoService } from "./services/lineas-credito.service";

@Component({
  selector: "app-lineas-credito",
  templateUrl: "./lineas-credito.component.html",
  styleUrls: ["./lineas-credito.component.css"],
})
export class LineasCreditoComponent implements OnInit {
  data_lineas = [];

  constructor(private _serviceLineasCredito: LineasCreditoService) {}

  ngOnInit() {
    this.get_lista();
  }

  get_lista() {
    this._serviceLineasCredito.get_lista().subscribe((resp: any) => {
      this.data_lineas = resp;
      console.log("Lineas Credito: ", resp);
    });
  }
}
