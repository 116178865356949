import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { PlanillaParametro } from "src/app/models/planilla-parametro.model";

@Injectable({
  providedIn: "root",
})
export class PlanillaParametrosService {
  httpOptions = {};
  private url = URL_API;

  constructor(private http: HttpClient) {}

  getListar(pagina: number, limit: number) {
    return this.http
      .get(`${this.url}/planilla_parametros/get_listar/${pagina}/${limit}`)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  /* getListar(pagina: number, limit: number) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planilla_parametros/get_listar/${pagina}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  } */

  getById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/planilla_parametros/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API +
      "/planilla_parametros/get_buscar/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  getByAnio(anio: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/planilla_parametros/get_byanio/${anio}`)
      .pipe(map((resp) => resp["data"]));
  }

  create(model: any) {
    this.loadAuth();
    return this.http.post(
      `${this.url}/planilla_parametros/nuevo`,
      model,
      this.httpOptions
    );
  }

  update(id: string, model: PlanillaParametro) {
    this.loadAuth();
    return this.http.put(
      `${this.url}/planilla_parametros/update/${id}`,
      model,
      this.httpOptions
    );
  }

  getdelete(id: string) {
    this.loadAuth();
    return this.http.delete(
      `${this.url}/planilla_parametros/delete/${id}`,
      this.httpOptions
    );
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
