import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import Swal from "sweetalert2";
import { URL_API } from "../config/config";

@Injectable({
  providedIn: "root",
})
export class ReportesService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  EventDatosRecaudos$ = new EventEmitter<any>();
  EventDatosTotalesRecaudos$ = new EventEmitter<any>();
  EventDatosDescuentos$ = new EventEmitter<any>();
  EventDatosArqueo$ = new EventEmitter<any>();
  EventDatosArqueoRetiros$ = new EventEmitter<any>();
  mes$ = new EventEmitter<String>();
  bus$ = new EventEmitter<String>();
  saldoviene$ = new EventEmitter<number>();
  saldoquepasa$ = new EventEmitter<number>();
  FechaDesde$ = new EventEmitter<any>();
  FechaHasta$ = new EventEmitter<any>();
  blob: any = undefined;
  loading$ = new EventEmitter<boolean>();
  _mes = "";

  constructor(private http: HttpClient) {
    this.mes$.subscribe((resp) => {
      this._mes = resp;
    });
  }

  getrecaudos(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    condicion: string
  ) {
    if (condicion.length === 0) {
      condicion = "-1";
    }
    let url =
      URL_API +
      "/reportes/get_v_recaudos/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta +
      "/" +
      condicion;
    return this.http.get(url, this.httpOptions);
  }

  getrecaudosByBus(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    bus: string
  ) {
    let url =
      URL_API +
      "/reportes/get_v_recaudosByBus/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta +
      "/" +
      bus;
    return this.http.get(url, this.httpOptions);
  }

  getrecaudosByRuta(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    ruta: string
  ) {
    let url =
      URL_API +
      "/reportes/get_v_recaudosByRuta/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta +
      "/" +
      ruta;
    return this.http.get(url, this.httpOptions);
  }

  getrecaudosByEmpresa(
    page: string,
    limit: string,
    desde: string,
    hasta: string
  ) {
    let url =
      URL_API +
      "/reportes/get_v_recaudosByEmpresa/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta;
    return this.http.get(url, this.httpOptions);
  }

  gettotalesrecaudos(
    page: string,
    limit: string,
    desde: string,
    hasta: string
  ) {
    let url =
      URL_API +
      "/reportes/get_totales_recaudos/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta;
    return this.http.get(url, this.httpOptions);
  }

  getretiros(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    condicion: string
  ) {
    if (condicion.length === 0) {
      condicion = "-1";
    }
    let url =
      URL_API +
      "/reportes/get_v_retiros/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta +
      "/" +
      condicion;
    return this.http.get(url, this.httpOptions);
  }

  getretirosByBus(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    bus: string
  ) {
    let url =
      URL_API +
      "/reportes/get_v_retirosByBus/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta +
      "/" +
      bus;
    return this.http.get(url, this.httpOptions);
  }

  get_arqueo(page: string, limit: string, desde: string, hasta: string) {
    let url =
      URL_API +
      "/reportes/get_v_arqueo/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta;
    return this.http.get(url, this.httpOptions);
  }

  get_arqueo_retiros(
    page: string,
    limit: string,
    desde: string,
    hasta: string
  ) {
    let url =
      URL_API +
      "/reportes/get_v_arqueo_retiros/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta;
    return this.http.get(url, this.httpOptions);
  }

  get_movimientos(page: string, limit: string, desde: string, hasta: string) {
    let url =
      URL_API +
      "/reportes/get_v_movimientos/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta;
    return this.http.get(url, this.httpOptions);
  }

  get_movimientosbycuenta(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    cuentaid: number
  ) {
    let url =
      URL_API +
      "/reportes/get_v_movimientosbycuenta/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta +
      "/" +
      cuentaid;

    return this.http.get(url, this.httpOptions);
  }

  get_movimientosSaldoViene(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    cuentaid: number
  ) {
    let url =
      URL_API +
      "/reportes/get_v_movimientossaldoviene/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta +
      "/" +
      cuentaid;
    return this.http.get(url, this.httpOptions);
  }

  get_movimientos_groupby(
    page: string,
    limit: string,
    desde: string,
    hasta: string
  ) {
    let url =
      URL_API +
      "/reportes/get_v_movimientos_groupby/" +
      page +
      "/" +
      limit +
      "/" +
      desde +
      "/" +
      hasta;
    return this.http.get(url, this.httpOptions);
  }

  get_totalesbyvehiculo(id: number) {
    let url = URL_API + "/reportes/get_totalesByVehiculo/" + id;
    return this.http.get(url, this.httpOptions);
  }

  get_totalesbyvehiculoPivot(id: number) {
    let url = URL_API + "/reportes/get_totalesByVehiculoPivot/" + id;
    return this.http.get(url, this.httpOptions);
  }

  downloadFile(
    data: any,
    fileName: string,
    nmes: string,
    nbus: string,
    periodo: string
  ) {
    if (nbus.length === 0) {
      nbus = "-1";
    }
    this.mes$.emit(nmes);
    this.bus$.emit(nbus);
    let url =
      URL_API +
      "/reportes/exportarproducidos/" +
      nmes +
      "/" +
      nbus +
      "/" +
      periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_Producidos(data: any, fileName: string, periodo: string) {
    let url = URL_API + "/reportes/exportarproducidos/" + periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_Retiros(data: any, fileName: string, periodo: string) {
    let url = URL_API + "/reportes/exportarretiros/" + periodo;
    console.log(url);
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );
  }

  downloadFile_Movimientos(
    data: any,
    fileName: string,
    periodo: string,
    cuentabanco: string
  ) {
    console.log(data);
    let url =
      URL_API + "/reportes/exportarmovimientos/" + periodo + "/" + cuentabanco;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );

    // return this.http.post(url, data);
  }

  downloadFile_TotalesRecaudos(data: any, fileName: string, periodo: string) {
    console.log(data);
    let url = URL_API + "/reportes/exportartotalesrecaudos/" + periodo;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.http.post(url, data, httpOptions).subscribe(
      (data: any) => {
        this.blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        this.loading$.emit(false);
      },
      (error: any) =>
        Swal.fire("Atención", "Error. " + error.statusText, "error")
    );

    // return this.http.post(url, data);
  }
}
