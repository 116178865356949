import { createAction, props } from "@ngrx/store";
import { PlanillaConsecutivo } from "../../../../../models/planillas-consecutivos.model";

export const cargarPlanillaConsecutivo = createAction(
  "[Planilla Consecutivo] Cargar Consecutivo",
  props<{ id: string }>()
);

export const cargarListaConsecutivos = createAction(
  "[Lista Planilla Consecutivo] Cargar Lista Consecutivo"
);

export const cargarPlanillaConsecutivoSuccess = createAction(
  "[Planilla Consecutivo] Cargar Consecutivo Success",
  props<{ planillaconsecutivo: PlanillaConsecutivo }>()
);

export const cargarPlanillaConsecutivoError = createAction(
  "[Planilla Consecutivo] Cargar Consecutivo Error",
  props<{ payload: any }>()
);

export const addPlanillaConsecutivo = createAction(
  "[Planilla Consecutivo] Nuevo Planilla Consecutivo",
  props<{ planillaconsecutivo: PlanillaConsecutivo }>()
);

export const addPlanillaConsecutivoSuccess = createAction(
  "[Planilla Consecutivo] Nuevo Planilla Consecutivo Success",
  props<{ planillaconsecutivo: any }>()
);

export const addPlanillaConsecutivoError = createAction(
  "[Planilla Consecutivo] Nuevo Planilla Consecutivo Error",
  props<{ payload: any }>()
);

export const updPlanillaConsecutivo = createAction(
  "[Planilla Consecutivo] Update Planilla Consecutivo",
  props<{ planillaconsecutivo: any }>()
);

export const updPlanillaConsecutivoSuccess = createAction(
  "[Planilla Consecutivo] Update Planilla Consecutivo Success",
  props<{ planillaconsecutivo: any }>()
);

export const updPlanillaConsecutivoError = createAction(
  "[Planilla Consecutivo] Update Planilla Consecutivo Error",
  props<{ payload: any }>()
);

export const cargarConsecutivoNuevo = createAction(
  "[Consecutivo Nuevo] Cargar Consecutivo Nuevo"
);

export const cargarConsecutivoNuevoSuccess = createAction(
  "[Consecutivo Nuevo] Cargar Consecutivo Nuevo Success",
  props<{ planillaconsecutivo: PlanillaConsecutivo }>()
);

export const cargarConsecutivoNuevoError = createAction(
  "[Consecutivo Nuevo] Cargar Consecutivo Nuevo Error",
  props<{ payload: any }>()
);
