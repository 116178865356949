import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillaconsecutivoActions from "../../store/actions/planilla-consecutivo.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillasConsecutivosService } from "../../../../pages/planillas-consecutivos/services/planillas-consecutivos.service";
import { of } from "rxjs";

@Injectable()
export class PlanillaConsecutivoEffects {
  constructor(
    private actions$: Actions,
    private planillaconsecutivosService: PlanillasConsecutivosService
  ) {}

  cargarPlanillaConsecutivo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaconsecutivoActions.cargarPlanillaConsecutivo),
      mergeMap((action) =>
        this.planillaconsecutivosService.getById(action.id).pipe(
          map((data) =>
            planillaconsecutivoActions.cargarPlanillaConsecutivoSuccess({
              planillaconsecutivo: data,
            })
          ),
          catchError((err) =>
            of(
              planillaconsecutivoActions.cargarPlanillaConsecutivoError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  addPlanillaConsecutivo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaconsecutivoActions.addPlanillaConsecutivo),
      mergeMap((action) =>
        this.planillaconsecutivosService
          .create(action.planillaconsecutivo)
          .pipe(
            map((data) =>
              planillaconsecutivoActions.addPlanillaConsecutivoSuccess({
                planillaconsecutivo: data,
              })
            ),
            catchError((err) =>
              of(
                planillaconsecutivoActions.addPlanillaConsecutivoError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );

  cargarConsecutivoNuevo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaconsecutivoActions.cargarConsecutivoNuevo),
      mergeMap(() =>
        this.planillaconsecutivosService.get_consecutivo_nuevo().pipe(
          map((data) =>
            planillaconsecutivoActions.cargarConsecutivoNuevoSuccess({
              planillaconsecutivo: data,
            })
          ),
          catchError((err) =>
            of(
              planillaconsecutivoActions.cargarConsecutivoNuevoError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
