import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as rutadespachoActions from "../../store/actions/ruta-despacho.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { RutasDespachosService } from "../../../../pages/rutas-despachos/services/rutas-despachos.service";
import { of } from "rxjs";

@Injectable()
export class RutaDespachoEffects {
  constructor(
    private actions$: Actions,
    private rutadespachosService: RutasDespachosService
  ) {}

  cargarRutaDespacho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rutadespachoActions.cargarRutaDespacho),
      mergeMap((action) =>
        this.rutadespachosService.getById(action.id).pipe(
          map((data) =>
            rutadespachoActions.cargarRutaDespachoSuccess({
              rutadespacho: data,
            })
          ),
          catchError((err) =>
            of(
              rutadespachoActions.cargarRutaDespachoError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  addRutaDespacho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rutadespachoActions.addRutaDespacho),
      mergeMap((action) =>
        this.rutadespachosService.create(action.rutadespacho).pipe(
          map((data) =>
            rutadespachoActions.addRutaDespachoSuccess({
              rutadespacho: data,
            })
          ),
          catchError((err) =>
            of(
              rutadespachoActions.addRutaDespachoError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
