import { Component } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { map } from "rxjs/operators";
import { PropietariosService } from "../../services/propietarios.service";

@Component({
  selector: "app-propietarios-validator",
  templateUrl: "./propietarios-validator.component.html",
  styles: [],
})
export class PropietariosValidatorComponent {
  constructor(public _serviceModel: PropietariosService) {}

  static existingDocumentoValidator(
    _serviceModel: PropietariosService,
    modo?: boolean
  ) {
    if (modo === false) {
      return (control: AbstractControl): Promise<null> => Promise.resolve(null);
    } else {
      return (control: AbstractControl) =>
        _serviceModel.documentoExiste(control.value).pipe(
          map((data: any) => {
            return data.count > 0 ? { existingDocumentoValidator: true } : null;
          })
        );
    }
  }
}
