import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { URL_API_NESTJS } from "src/app/config/config";
import { CreditoModel } from "../models/creditos-model";

@Injectable({
  providedIn: "root",
})
export class CreditosService {
  private url = URL_API_NESTJS;

  constructor(private http: HttpClient) {}

  get_lista(pagina: number, regxpagina: number) {
    return this.http
      .get(`${this.url}/cop-creditos/${pagina}/${regxpagina}`)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getById(id: number) {
    return this.http.get(`${this.url}/cop-creditos/${id}`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  get_asociados() {
    return this.http.get(`${this.url}/cop-creditos/asociados`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  get_codeudores() {
    return this.http.get(`${this.url}/cop-creditos/codeudores`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  get_codeudoresexceptAsociado(asociado_id: number) {
    return this.http
      .get(`${this.url}/cop-creditos/codeudores/${asociado_id}`)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  get_simulador_credito(
    monto: number,
    tasa: number,
    cuotas: number,
    isround_cuota: number,
    fechaprimerpago: string,
    credito_id: number,
    is_simular: number
  ) {
    return this.http
      .get(
        `${this.url}/creditos/get_simulador_credito/${monto}/${tasa}/${cuotas}/${isround_cuota}/${fechaprimerpago}/${credito_id}/${is_simular}`
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  create(model: any) {
    return this.http.post(`${this.url}/cop-creditos`, model);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/cop-creditos/${id}`);
  }

  update(id: number, model: CreditoModel) {
    return this.http.put(`${this.url}/cop-creditos/${id}`, model);
  }
}
