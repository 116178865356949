import { createReducer, on } from "@ngrx/store";
import * as actions from "../actions";
import { PlanillaParametro } from "../../../../../models/planilla-parametro.model";

export interface PlanillaParametroState {
  id: string;
  item: PlanillaParametro;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const PlanillaParametroStateInitialState: PlanillaParametroState = {
  id: null,
  item: null,
  loaded: false,
  loading: false,
  error: null,
};

const _planillaparametroReducer = createReducer(
  PlanillaParametroStateInitialState,

  on(actions.cargarParametroAnioActual, (state, { id }) => ({
    ...state,
    loading: true,
    id: id,
    error: null,
  })),

  on(
    actions.cargarParametroAnioActualSuccess,
    (state, { planillaparametro }) => ({
      ...state,
      loading: false,
      loaded: true,
      item: { ...planillaparametro },
    })
  ),

  on(actions.cargarParametroAnioActualError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: payload.message,
  })),

  on(actions.addPlanillaParametro, (state, { planillaparametro }) => ({
    ...state,
    item: { ...planillaparametro },
    loaded: false,
    loading: true,
  })),
  on(actions.addPlanillaParametroSuccess, (state, { planillaparametro }) => ({
    ...state,
    item: { ...planillaparametro },
    loaded: true,
    loading: false,
    error: planillaparametro.message,
  })),
  on(actions.addPlanillaParametroError, (state, { payload }) => ({
    ...state,
    loaded: false,
    loading: false,
    error: payload.message,
  })),
  on(actions.updPlanillaParametro, (state, { planillaparametro }) => ({
    ...state,
    item: { ...planillaparametro },
    loaded: false,
    loading: true,
  })),
  on(actions.updPlanillaParametroSuccess, (state, { planillaparametro }) => ({
    ...state,
    item: { ...planillaparametro },
    loaded: true,
    loading: false,
    error: planillaparametro.mmessage,
  })),
  on(actions.updPlanillaParametroError, (state, { payload }) => ({
    ...state,
    loaded: true,
    loading: false,
    error: payload.message,
  }))
);

export function planillaparametroReducer(state, action) {
  return _planillaparametroReducer(state, action);
}
