import { createReducer, on } from "@ngrx/store";
import {
  cargarPlanillaParametros,
  cargarPlanillaParametrosByQuery,
  cargarPlanillaParametrosByQueryError,
  cargarPlanillaParametrosByQuerySuccess,
  cargarPlanillaParametrosError,
  cargarPlanillaParametrosSuccess,
} from "../actions";
import { PlanillaParametro } from "../../../../../models/planilla-parametro.model";

export interface PlanillaParametrosState {
  items: PlanillaParametro[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const planillaparametrosInitialState: PlanillaParametrosState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _planillaparametrosReducer = createReducer(
  planillaparametrosInitialState,

  on(cargarPlanillaParametros, (state) => ({ ...state, loading: true })),

  on(
    cargarPlanillaParametrosSuccess,
    (state, { planillaparametros, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillaparametros],
      registros: registros,
      pagecount: pagecount,
    })
  ),

  on(cargarPlanillaParametrosError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(cargarPlanillaParametrosByQuery, (state) => ({ ...state, loading: true })),
  on(
    cargarPlanillaParametrosByQuerySuccess,
    (state, { planillaparametros, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillaparametros],
      registros: registros,
      pagecount: pagecount,
    })
  ),
  on(cargarPlanillaParametrosByQueryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function planillaparametrosReducer(state, action) {
  return _planillaparametrosReducer(state, action);
}
