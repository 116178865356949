import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillaActions from "../../store/actions/planilla.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillasService } from "../../../../pages/planillas/services/planillas.service";
import { of } from "rxjs";

@Injectable()
export class PlanillaEffects {
  constructor(
    private actions$: Actions,
    private planillasService: PlanillasService
  ) {}

  cargarPlanilla$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaActions.cargarPlanilla),
      mergeMap((action) =>
        this.planillasService.getById(action.id).pipe(
          map((data) =>
            planillaActions.cargarPlanillaSuccess({
              planilla: data,
            })
          ),
          catchError((err) =>
            of(
              planillaActions.cargarPlanillaError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  addPlanilla$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaActions.addPlanilla),
      mergeMap((action) =>
        this.planillasService.create(action.planilla).pipe(
          map((data) =>
            planillaActions.addPlanillaSuccess({
              planilla: data,
            })
          ),
          catchError((err) =>
            of(
              planillaActions.addPlanillaError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  //Para Formulario de Liquidación de Planillas
  cargarPlanillasMasterByPlanilla_id$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaActions.cargarPlanillasMasterByPlanilla_id),
      mergeMap((action) =>
        this.planillasService.getById(action.planilla_id.toString()).pipe(
          map((data) =>
            planillaActions.cargarPlanillasMasterByPlanilla_idSuccess({
              planilla: data,
            })
          ),
          catchError((err) =>
            of(
              planillaActions.cargarPlanillasMasterByPlanilla_idError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
