import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { PlanillaLiquidacion } from "src/app/models/planillas-liquidacion.model";

@Injectable({
  providedIn: "root",
})
export class PlanillasLiquidacionService {
  httpOptions = {};
  private url = URL_API;

  constructor(private http: HttpClient) {}

  getListar(pagina: number, limit: number) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas_liquidacion/get_listar/${pagina}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/planillas_liquidacion/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  getLiquidarPlanilla(planilla_id: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas_liquidacion/get_liquidar_planilla/${planilla_id}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp["data"];
        })
      );
  }

  get_Resumen_Liquidacion_Planilla(planilla_id: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas_liquidacion/get_resumen_liquidacion/${planilla_id}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp["data"];
        })
      );
  }

  create(model: any) {
    this.loadAuth();
    return this.http.post(
      `${this.url}/planillas_liquidacion/nuevo`,
      model,
      this.httpOptions
    );
  }

  insert_recorrido(model: any) {
    // console.log(model);
    this.loadAuth();
    return this.http.post(
      `${this.url}/planillas_liquidacion/insert_recorrido`,
      { model },
      this.httpOptions
    );
  }

  create2(model: any) {
    this.loadAuth();
    return this.http.post(
      `${this.url}/planillas_liquidacion/nuevo2`,
      model,
      this.httpOptions
    );
  }

  create_recorrido(planilla_id: number, prd_id: number, fechahora_salida: any) {
    this.loadAuth();
    return this.http.get(
      `${this.url}/planillas_liquidacion/get_create_recorrido/${planilla_id}/${prd_id}/${fechahora_salida}`,
      this.httpOptions
    );
  }

  update(id: string, model: PlanillaLiquidacion) {
    this.loadAuth();
    return this.http.put(
      `${this.url}/planillas_liquidacion/update/${id}`,
      model,
      this.httpOptions
    );
  }

  getdelete(id: string) {
    this.loadAuth();
    return this.http.delete(
      `${this.url}/planillas_liquidacion/delete/${id}`,
      this.httpOptions
    );
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
