import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as usersActions from "../actions/users.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { UsersService } from "../../services/users.service";
import { of } from "rxjs";

@Injectable()
export class usersEffects {
  constructor(private actions$: Actions, private usersService: UsersService) {}

  cargarUsuarios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.cargarUsers),
      mergeMap(() =>
        this.usersService.getUsersRedux().pipe(
          map((users) => usersActions.cargarUsersSuccess({ users: users })),
          catchError((err) =>
            of(usersActions.cargarUsersError({ payload: err }))
          )
        )
      )
    )
  );
}
