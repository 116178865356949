import { createReducer, on } from "@ngrx/store";
import {
  cargarPlanillasSanciones,
  cargarPlanillasSancionesError,
  cargarPlanillasSancionesSuccess,
} from "../actions";
import { PlanillaSancion } from "../../../../../models/planillas-sanciones.model";

export interface PlanillasSancionesState {
  items: PlanillaSancion[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const planillassancionesInitialState: PlanillasSancionesState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _planillassancionesReducer = createReducer(
  planillassancionesInitialState,

  on(cargarPlanillasSanciones, (state) => ({ ...state, loading: true })),

  on(
    cargarPlanillasSancionesSuccess,
    (state, { planillassanciones, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...planillassanciones],
      registros: registros,
      pagecount: pagecount,
    })
  ),

  on(cargarPlanillasSancionesError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function planillassancionesReducer(state, action) {
  return _planillassancionesReducer(state, action);
}
