import { createReducer, on } from "@ngrx/store";
import * as actions from "../actions";
import { PlanillaConsecutivo } from "../../../../../models/planillas-consecutivos.model";
import { AppState } from "../../../../../app.reducers";
/* 
export interface State {
  id: string;
  planillaconsecutivo: PlanillaConsecutivo;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export interface AppStateWithPlanillaConsecutivo extends AppState {
  planillaconsecutivo: State;
}
export const PlanillaConsecutivoStateInitialState: State = {
  id: null,
  planillaconsecutivo: null,
  loaded: false,
  loading: false,
  error: null,
}; */

export interface PlanillaConsecutivoState {
  id: string;
  planillaconsecutivo: PlanillaConsecutivo;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const PlanillaConsecutivoStateInitialState: PlanillaConsecutivoState = {
  id: null,
  planillaconsecutivo: null,
  loaded: false,
  loading: false,
  error: null,
};

const _planillaconsecutivoReducer = createReducer(
  PlanillaConsecutivoStateInitialState,

  on(actions.cargarListaConsecutivos, (state) => ({ ...state, loading: true })),

  on(actions.cargarPlanillaConsecutivo, (state, { id }) => ({
    ...state,
    loading: true,
    id: id,
    error: null,
  })),

  on(
    actions.cargarPlanillaConsecutivoSuccess,
    (state, { planillaconsecutivo }) => ({
      ...state,
      loading: false,
      loaded: true,
      planillaconsecutivo: { ...planillaconsecutivo },
    })
  ),

  on(actions.cargarPlanillaConsecutivoError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: payload.message,
  })),

  on(actions.addPlanillaConsecutivo, (state, { planillaconsecutivo }) => ({
    ...state,
    planillaconsecutivo: { ...planillaconsecutivo },
    loaded: false,
    loading: true,
  })),
  on(
    actions.addPlanillaConsecutivoSuccess,
    (state, { planillaconsecutivo }) => ({
      ...state,
      planillaconsecutivo: { ...planillaconsecutivo },
      loaded: true,
      loading: false,
      error: planillaconsecutivo.message,
    })
  ),
  on(actions.addPlanillaConsecutivoError, (state, { payload }) => ({
    ...state,
    loaded: false,
    loading: false,
    error: payload.message,
  })),
  on(actions.updPlanillaConsecutivo, (state, { planillaconsecutivo }) => ({
    ...state,
    planillaconsecutivo: { ...planillaconsecutivo },
    loaded: false,
    loading: true,
  })),
  on(
    actions.updPlanillaConsecutivoSuccess,
    (state, { planillaconsecutivo }) => ({
      ...state,
      planillaconsecutivo: { ...planillaconsecutivo },
      loaded: true,
      loading: false,
      error: planillaconsecutivo.mmessage,
    })
  ),
  on(actions.updPlanillaConsecutivoError, (state, { payload }) => ({
    ...state,
    loaded: true,
    loading: false,
    error: payload.message,
  })),

  on(actions.cargarConsecutivoNuevo, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(
    actions.cargarConsecutivoNuevoSuccess,
    (state, { planillaconsecutivo }) => ({
      ...state,
      loading: false,
      loaded: true,
      planillaconsecutivo: { ...planillaconsecutivo },
    })
  ),

  on(actions.cargarConsecutivoNuevoError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: payload.message,
  }))
);

export function planillaconsecutivoReducer(state, action) {
  return _planillaconsecutivoReducer(state, action);
}
