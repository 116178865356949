import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillasliquidacionActions from "../../store/actions/planillas-liquidacion.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillasLiquidacionService } from "../../../../pages/planillas-liquidacion/services/planillas-liquidacion.service";
import { of } from "rxjs";

@Injectable()
export class PlanillasLiquidacionEffects {
  constructor(
    private actions$: Actions,
    private planillasliquidacionService: PlanillasLiquidacionService
  ) {}

  cargarPlanillasLiquidacion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillasliquidacionActions.cargarPlanillasLiquidacion),
      mergeMap(() =>
        this.planillasliquidacionService.getListar(1, 10).pipe(
          map((resp) =>
            planillasliquidacionActions.cargarPlanillasLiquidacionSuccess({
              planillasliquidacion: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              planillasliquidacionActions.cargarPlanillasLiquidacionError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
