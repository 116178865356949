import { Component, OnInit } from "@angular/core";
import { EstadosCreditoService } from "./services/estados-credito.service";
import { URL_API_NESTJS } from "src/app/config/config";
import { EstadoCreditoModel } from "./models/estado-credito-model";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EstadosCreditoNewEditComponent } from "./new-edit/estados-credito-new-edit.component";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-estados-credito",
  templateUrl: "./estados-credito.component.html",
  styleUrls: ["./estados-credito.component.css"],
})
export class EstadosCreditoComponent implements OnInit {
  private url = URL_API_NESTJS;
  data_estados = [];
  frmBuscar: FormGroup;

  constructor(
    private _serviceEstadosCredito: EstadosCreditoService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    this.get_lista();
  }

  get_lista() {
    this._serviceEstadosCredito.get_lista().subscribe((resp: any) => {
      this.data_estados = resp;
      console.log("Estados Credito: ", resp);
    });
  }

  getBuscar() {}

  new(model: EstadoCreditoModel) {
    const ref = this.modalService.open(EstadosCreditoNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceEstadosCredito
            .create(data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.get_lista();
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  edit(model: EstadoCreditoModel) {
    const ref = this.modalService.open(EstadosCreditoNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._planillaparametro_id = model.estadocredito_id;
    ref.result.then(
      (data) => {
        if (data.valido) {
          console.log("eeeee", data.valido);
          this._serviceEstadosCredito
            .update(model.estadocredito_id, data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.get_lista();
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: EstadoCreditoModel) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de Eliminar el registro?</span>',
      html: "<p><b>Estado:</b> " + model.descripcion + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceEstadosCredito
          .delete(model.estadocredito_id)
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.get_lista();
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }
}
