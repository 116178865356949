import { createReducer, on } from "@ngrx/store";
import {
  cargarLugaresControl,
  cargarLugaresControlByQuery,
  cargarLugaresControlByQueryError,
  cargarLugaresControlByQuerySuccess,
  cargarLugaresControlError,
  cargarLugaresControlSuccess,
} from "../actions";
import { LugarControl } from "../../../../../models/lugares-control.model";

export interface LugaresControlState {
  items: LugarControl[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const lugarescontrolInitialState: LugaresControlState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _lugarescontrolReducer = createReducer(
  lugarescontrolInitialState,

  on(cargarLugaresControl, (state) => ({ ...state, loading: true })),

  on(
    cargarLugaresControlSuccess,
    (state, { lugarescontrol, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...lugarescontrol],
      registros: registros,
      pagecount: pagecount,
    })
  ),

  on(cargarLugaresControlError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(cargarLugaresControlByQuery, (state) => ({ ...state, loading: true })),
  on(
    cargarLugaresControlByQuerySuccess,
    (state, { lugarescontrol, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...lugarescontrol],
      registros: registros,
      pagecount: pagecount,
    })
  ),
  on(cargarLugaresControlByQueryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function lugarescontrolReducer(state, action) {
  return _lugarescontrolReducer(state, action);
}
