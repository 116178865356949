import { AppState } from "src/app/app.reducers";
import { PlanillasConsecutivosState } from "../reducers/planillas-consecutivos.reducer";
import { PlanillaConsecutivoState } from "../reducers/planilla-consecutivo.reducer";
import { createSelector } from "@ngrx/store";

export const selectPlanillasConsecutivos = (state: AppState) =>
  state.planillasconsecutivos;

export const selectListPlanillasConsecutivos = createSelector(
  selectPlanillasConsecutivos,
  (state: PlanillasConsecutivosState) => state.items
);
export const selectLoadingPlanillasConsecutivos = createSelector(
  selectPlanillasConsecutivos,
  (state: PlanillasConsecutivosState) => state.loading
);
export const selectFailPlanillasConsecutivos = createSelector(
  selectPlanillasConsecutivos,
  (state: PlanillasConsecutivosState) => state.error
);

export const selectPlanillaConsecutivo = (state: AppState) =>
  state.planillaconsecutivo;

export const selectConsecutivoNuevo = createSelector(
  selectPlanillaConsecutivo,
  (state: PlanillaConsecutivoState) => state.planillaconsecutivo
);
