import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { LugarControl } from "src/app/models/lugares-control.model";
import { AppState } from "../../../app.reducers";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  cargarLugaresControl,
  cargarLugaresControlByQuery,
} from "../lugares-control/store/actions";
import { FormBuilder, FormGroup } from "@angular/forms";
import pageSettings from "src/app/config/page-settings";
import { LugaresControlNewEditComponent } from "./new-edit/new-edit.component";
import { LugaresControlService } from "./services/lugares-control.service";
import Swal from "sweetalert2";
import { tap } from "rxjs/operators";
import { PaginadorxService } from "../paginadorx/paginadorx.service";
import { selectLugaresControl } from "./store/selectors/lugares-control.selectors";

@Component({
  selector: "app-lugares-control",
  templateUrl: "./lugares-control.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
      .tooltip {
        opacity: 1 !important;
      }
    `,
  ],
})
export class LugaresControlComponent implements OnInit, AfterViewChecked {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  pageSettings = pageSettings;
  buscando: boolean = false;

  arrayModel: LugarControl[] = [];
  loading: boolean = false;
  error: any;
  ppSubs: Subscription;
  frmBuscar: FormGroup;
  _lugarcontrol_id: number;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _serviceModel: LugaresControlService,
    private _servicePaginador: PaginadorxService,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.listar("1", "10");
    // this.receiveMessage({ pagina: 1, recxpag: 10 });
  }

  async listar(pagina: string, limit: string) {
    this.store.dispatch(cargarLugaresControl({ pagina, limit }));
    this.store
      .select(selectLugaresControl)
      .pipe(
        tap((e: any) => {
          this.arrayModel = e.items;
          this.loading = e.loading;
          this._servicePaginador.pagex$.emit(pagina);
          this._servicePaginador.registrosx$.emit(e.registros);
          this.blockUI.stop();
        })
      )
      .subscribe();
  }

  new(model: LugarControl) {
    const ref = this.modalService.open(LugaresControlNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceModel.create(data.frm).subscribe((resp: any) => {
            console.log(resp);
            if (resp.status === "ok") {
              this.listar("1", "10");
            } else {
              Swal.fire("Atención", resp.msg, "error");
            }
          });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  receiveMessage($event: any) {
    // this.listar($event.pagina, $event.recxpag);
    if (this.frmBuscar.controls._txtBuscar.value.length === 0) {
      this.listar($event.pagina, $event.recxpag);
    } else {
      const value = this.frmBuscar.controls._txtBuscar.value;
      this.getBuscar($event.pagina, $event.recxpag, value);
    }
  }

  async getBuscar(pagina: string, limit: string, buscar: string) {
    if (buscar.trim().length > 0) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      this.buscando = true;

      this.store.dispatch(
        cargarLugaresControlByQuery({
          pagina,
          limit,
          query: this.frmBuscar.controls["_txtBuscar"].value,
        })
      );
    } else {
      this.listar("1", "10");
    }
  }

  edit(model: LugarControl) {
    const ref = this.modalService.open(LugaresControlNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._lugarcontrol_id = model.lugarcontrol_id;
    ref.result.then(
      (data) => {
        console.log(data.frm);
        if (data.valido) {
          this._serviceModel
            .update(model.lugarcontrol_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.listar("1", "10");
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: any) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: "<p><b>Lugar:</b> " + model.nombre + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceModel
          .getdelete(model.lugarcontrol_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.listar("1", "10");
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
