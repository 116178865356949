import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as userActions from "../actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { UsersService } from "../../services/users.service";
import { of } from "rxjs";

@Injectable()
export class userEffects {
  constructor(private actions$: Actions, private usersService: UsersService) {}

  cargarUsuario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.cargarUser),
      mergeMap((action) =>
        this.usersService.getUserById(action.id).pipe(
          map((user) => userActions.cargarUserSuccess({ user: user })),
          catchError((err) => of(userActions.cargarUserError({ payload: err })))
        )
      )
    )
  );
}
