import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { SociosService } from "../../services/socios.service";
import { PaginadorService } from "../../paginador/paginador.service";
import pageSettings from "../../config/page-settings";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Socio } from "../../models/socios.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SociosNewComponent } from "./socios-new.component";
import Swal from "sweetalert2";

@Component({
  selector: "app-socios",
  templateUrl: "./socios.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./socios.component.css"],
})
export class SociosComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  pageSettings = pageSettings;
  sortedData = [];
  data_list = [];
  items = [1, 2, 3, 4];
  buscando: boolean = false;
  frmBuscar: FormGroup;
  tipos_documento = [];

  constructor(
    private _serviceSocios: SociosService,
    private _servicePaginador: PaginadorService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {
    this.pageSettings.pageWithoutSidebar = false;
    this.pageSettings.pageTopMenu = false;
  }

  ngOnInit() {
    this.buscando = false;
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    this.getLista("1", "10");
  }

  getLista(pagina: string, limit: string) {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this._serviceSocios.get_listar(pagina, limit).subscribe((resp: any) => {
      this.data_list = resp.data;
      this._servicePaginador.page$.emit(pagina);
      this._servicePaginador.registros$.emit(resp.registros);
      this.blockUI.stop();
    });
  }

  getBuscar(pagina: string, limit: string, buscar: string) {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.buscando = true;
    this._serviceSocios
      .get_buscar(pagina, limit, buscar)
      .subscribe((resp: any) => {
        this.data_list = resp.data;
        this._servicePaginador.page$.emit(pagina);
        this._servicePaginador.registros$.emit(resp.registros);
        this.blockUI.stop();
      });
  }

  receiveMessage($event: any) {
    if (this.frmBuscar.controls._txtBuscar.value === "") {
      this.buscando = false;
    }

    if (!this.buscando) {
      this.getLista($event.pagina, $event.recxpag);
    }

    if (this.buscando) {
      const value = this.frmBuscar.controls._txtBuscar.value;
      this.getBuscar($event.pagina, $event.recxpag, value);
    }
  }

  new(model: Socio) {
    const ref = this.modalService.open(SociosNewComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });

    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceSocios.create(data.frm).subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.getLista("1", "10");
              Swal.fire("Atención", resp.msg, "success");
            } else {
              Swal.fire("Atención", resp.msg, "error");
            }
          });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  Buscar() {
    const value = this.frmBuscar.controls._txtBuscar.value;
    if (value.length > 0) {
      this.buscando = true;
      this.getBuscar("1", "10", value);
    } else {
      this.getLista("1", "10");
    }
  }

  edit(model: Socio) {
    const ref = this.modalService.open(SociosNewComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceSocios
            .update(model.socio_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.getLista("1", "10");
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: Socio) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: "<p><b>Socio:</b> " + model.nombres + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceSocios
          .getdelete(model.socio_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.getLista("1", "10");
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }
}
