import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as rutasdespachosActions from "../../store/actions/rutas-despachos.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { RutasDespachosService } from "../../../../pages/rutas-despachos/services/rutas-despachos.service";
import { of } from "rxjs";

@Injectable()
export class RutasDespachosEffects {
  constructor(
    private actions$: Actions,
    private rutasdespachosService: RutasDespachosService
  ) {}

  cargarRutasDespachos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rutasdespachosActions.cargarRutasDespachos),
      mergeMap((action) =>
        this.rutasdespachosService.getListar(action.pagina, action.limit).pipe(
          map((resp) =>
            rutasdespachosActions.cargarRutasDespachosSuccess({
              rutasdespachos: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              rutasdespachosActions.cargarRutasDespachosError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  cargarRutasDespachosByQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rutasdespachosActions.cargarRutasDespachosByQuery),
      mergeMap((action) =>
        this.rutasdespachosService
          .get_buscar(action.pagina, action.limit, action.query)
          .pipe(
            map((resp) =>
              rutasdespachosActions.cargarRutasDespachosByQuerySuccess({
                rutasdespachos: resp["data"],
                registros: resp["registros"],
                pagecount: resp["pageCount"],
              })
            ),
            catchError((err) =>
              of(
                rutasdespachosActions.cargarRutasDespachosByQueryError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );
}
