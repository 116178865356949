import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanillasService } from "../services/planillas.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducers";
import { Empresa } from "src/app/models/empresa.model";
import { selectEmpresa } from "src/app/pages/empresa/store/selectors/empresa.selector";
import { filter } from "rxjs/operators";
import { Planilla } from "src/app/models/planillas.model";
import { EmpresaService } from "src/app/services/empresa.service";

@Component({
  selector: "app-planilla-conceptos-pagar",
  templateUrl: "./planilla-conceptos-pagar.component.html",
  styleUrls: ["./planilla-conceptos-pagar.component.css"],
})
export class PlanillaConceptosPagarComponent implements OnInit {
  data_empresa: Empresa;
  _model: Planilla;
  _bus: string = "";
  _conductor: string = "";
  _propietario: string = "";
  _despacho: string = "";
  _placa: string = "";
  _fechaplanilla: Date;
  _consecutivo: number;
  datos_pago: [] = [];

  constructor(
    public modal: NgbActiveModal,
    private _servicePlanilla: PlanillasService,
    private _serviceEmpresa: EmpresaService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    /*     this.store
      .select(selectEmpresa)
      .pipe(filter((x) => x.item !== null))
      .subscribe((resp) => {
        this.data_empresa = resp.item[0];
      }); */
    this._serviceEmpresa.getById("1").subscribe((resp) => {
      this.data_empresa = resp[0];
    });
    const id = this._model.planilla_id;
    this._bus = this._model.numero;
    this._conductor = this._model.conductor_nombre;
    this._propietario = this._model.propietario_nombres;
    this._fechaplanilla = this._model.fecha_planilla;
    this._consecutivo = this._model.planilla_id;
    this._despacho = this._model.despacho_nombre;
    this._placa = this._model.placa;

    this._servicePlanilla
      .get_conceptos_pagarById(id.toString())
      .subscribe((resp) => {
        this.datos_pago = resp["data"];
      });
  }

  get_Total_Planilla() {
    let total: number = this.datos_pago.reduce(
      (acum: number, items: { valor: number }) => {
        return acum + +items.valor;
      },
      0
    );
    return total;
  }
}
