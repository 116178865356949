import { Injectable } from "@angular/core";
import * as QRCode from "qrcode";

@Injectable({
  providedIn: "root",
})
export class QrcodeService {
  constructor() {}

  generateQRCode(text: string): Promise<string> {
    return QRCode.toDataURL(text);
  }

  downloadQRCode(dataURL: string, fileName: string): void {
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  base64ToBlob(base64: string, mime: string): File {
    base64 = base64.replace("data:image/png;base64,", "");
    const byteArray = new Uint8Array(
      atob(base64)
        .split("")
        .map((char) => char.charCodeAt(0))
    );

    const fileblob = new Blob([byteArray], { type: mime });
    const imageFile = new File([fileblob], "png", { type: mime });
    return imageFile;
  }
}
