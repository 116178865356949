import { Component, OnInit } from "@angular/core";
import pageSettings from "./config/page-settings";
import { User } from "./models/auth/users.model";
import { Router } from "@angular/router";
import { AuthorizatedGuard } from "./auth/authorizated-guard";
import { AuthService } from "./services/auth.service";
import { Store } from "@ngrx/store";
import { AppState } from "./app.reducers";
import {
  cargarParametroAnioActual,
  cargarPlanillaParametros,
} from "./despachos/pages/planilla-parametros/store/actions";
import { cargarEmpresa } from "./pages/empresa/store/actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [AuthorizatedGuard],
})
export class AppComponent implements OnInit {
  pageSettings;
  pageHasScroll;
  currentUser: User;

  title = "Colectivos Ciudad de Ipiales";

  constructor(
    private _serviceAuth: AuthService,
    private router: Router,
    private store: Store<AppState>
  ) {
    this._serviceAuth.currentUser.subscribe((x) => {
      this.currentUser = x;
      if (x) {
        this._serviceAuth.isLogueado$.emit(true);
      }
    });
  }

  ngOnInit() {
    this.pageSettings = pageSettings;
    /* this.store.dispatch(
      cargarPlanillaParametros({ pagina: "1", limit: "100" })
    ); */

    this.store.dispatch(cargarParametroAnioActual({ id: "2024" }));
    this.store.dispatch(cargarEmpresa({ id: "1" }));
  }

  onToggleSidebarRight(val: boolean): void {}

  onToggleMobileSidebar(val: boolean): void {}

  onToggleMobileRightSidebar(val: boolean): void {}

  onToggleSidebarMinified(val: boolean): void {
    console.log("val: ", val);
  }

  onHideMobileSidebar(val: boolean): void {}

  onHideMobileRightSidebar(val: boolean): void {}

  logOut(msg: string) {
    this._serviceAuth.logout();
    this.router.navigate(["/login"]);
  }
}
