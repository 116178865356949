import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as rutadespacholugarActions from "../../store/actions/rutadespacho-lugar.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { RutasDespachosLugaresService } from "../../../../pages/rutasdespachos-lugares/services/rutasdespachos-lugares.service";
import { of } from "rxjs";

@Injectable()
export class RutaDespachoLugarEffects {
  constructor(
    private actions$: Actions,
    private rutasdespachoslugaresService: RutasDespachosLugaresService
  ) {}

  cargarRutaDespachoLugar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rutadespacholugarActions.cargarRutaDespachoLugar),
      mergeMap((action) =>
        this.rutasdespachoslugaresService.getById(action.id).pipe(
          map((data) =>
            rutadespacholugarActions.cargarRutaDespachoLugarSuccess({
              rutadespacholugar: data,
            })
          ),
          catchError((err) =>
            of(
              rutadespacholugarActions.cargarRutaDespachoLugarError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  addRutaDespachoLugar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rutadespacholugarActions.addRutaDespachoLugar),
      mergeMap((action) =>
        this.rutasdespachoslugaresService.create(action.rutadespacholugar).pipe(
          map((data) =>
            rutadespacholugarActions.addRutaDespachoLugarSuccess({
              rutadespacholugar: data,
            })
          ),
          catchError((err) =>
            of(
              rutadespacholugarActions.addRutaDespachoLugarError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
