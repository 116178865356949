import { AppState } from "src/app/app.reducers";
import { createSelector } from "@ngrx/store";
import { EmpresaState } from "../reducers/empresa.reducer";

//Registro
export const selectEmpresa = (state: AppState) => state.empresa;

export const selectListEmpresa = createSelector(
  selectEmpresa,
  (state: EmpresaState) => state.item
);

export const selectLoadingEmpresa = createSelector(
  selectEmpresa,
  (state: EmpresaState) => state.loading
);
export const selectLoadedEmpresa = createSelector(
  selectEmpresa,
  (state: EmpresaState) => state.loaded
);
