import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";
import { Propietario } from "../models/propietarios.model";
import { catchError, map } from "rxjs/operators";
import Swal from "sweetalert2";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PropietariosService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(private http: HttpClient) {}

  get_listar(page: string, limit: string) {
    const url = URL_API + "/propietarios/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_vpropietarios(page: string, limit: string) {
    const url =
      URL_API + "/propietarios/get_listar_vpropietarios/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url =
      URL_API +
      "/propietarios/get_buscar/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    const url = URL_API + "/propietarios/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + "/propietarios/get_byid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  documentoExiste(value: string) {
    let url = URL_API + `/propietarios/get_documentoexiste/${value}`;
    return this.http.get(url).pipe(
      map((resp: any) => resp),
      catchError((err) => {
        Swal.fire("Error", err, "error");
        return of(false);
      })
    );
  }

  update(id: string, model: Propietario) {
    const url = URL_API + "/propietarios/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    const url = URL_API + "/propietarios/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }
}
