import { Injectable } from "@angular/core";
import { URL_API } from "../config/config";
import { User } from "../models/auth/users.model";
import { Observable, of } from "rxjs";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  httpOptions = {};
  constructor(private http: HttpClient) {}

  get_listar(page: string, limit: string) {
    this.loadAuth();
    const url = URL_API + "/users/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API + "/users/get_buscar/" + pagina + "/" + limit + "/" + buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    this.loadAuth();
    const url = URL_API + "/users/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  update(id: string, model: User) {
    this.loadAuth();
    const url = URL_API + "/users/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  change_password(id: string, model: User) {
    this.loadAuth();
    const url = URL_API + "/users/change_password/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    this.loadAuth();
    const url = URL_API + "/users/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }

  emailExiste(email: string) {
    this.loadAuth();
    let url = URL_API + `/users/get_emailexiste/${email}`;
    return this.http.get(url).pipe(
      map((resp: any) => resp),
      catchError((err) => {
        Swal.fire("Error", err, "error");
        return of(false);
      })
    );
  }

  getUsers(): Observable<User[]> {
    this.loadAuth();
    let url = URL_API + "/users/listar";
    return this.http.get<User[]>(url, this.httpOptions);
  }

  getbyid(id: string): Observable<User> {
    this.loadAuth();
    let url = URL_API + "/users/get_byid/" + id;
    return this.http.get<User>(url, this.httpOptions);
  }

  getUsersRedux() {
    this.loadAuth();
    let url = URL_API + "/users/listar";
    return this.http.get(url).pipe(map((resp) => resp["data"]));
  }

  getUserById(id: string) {
    this.loadAuth();
    let url = URL_API + "/users/" + id;
    return this.http.get(url).pipe(map((resp) => resp["data"]));
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
