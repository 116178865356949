import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { Retiro } from "../../models/retiros.model";
import { VehiculosService } from "../../services/vehiculos.service";
import { MatDialog } from "@angular/material";
import { DlgRetirosComponent } from "../../busquedas/dlg-retiros.component";
import { RetirosService } from "../../services/retiros.service";
import Swal from "sweetalert2";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-retiros-new",
  templateUrl: "./retiros-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RetirosNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  isLoading: boolean = false;
  frm: FormGroup;
  productos = [];
  vehiculos = [];
  data_search_retiros = [];
  _model: Retiro;
  _nombres = "";
  isEdit: boolean = false;
  _vehiid: number = 0;
  _vehinum: string = "";
  _saldo_pasa: number = 0;
  _saldo_anterior: number = 0;
  btnActivo: boolean = true;
  _userSession: any = "0";

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceRetiros: RetirosService,
    private _serviceVehiculos: VehiculosService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      retiro_id: [0],
      vehiculo_id: ["", Validators.required],
      propietario_id: ["", Validators.required],
      user_id: [this._userSession.user_id, Validators.required],
      fecha_retiro: ["", Validators.required],
      valor_retiro: [0, Validators.required],
      administracion: [0, Validators.required],
      cooperativa: [0, Validators.required],
      aportes: [0, Validators.required],
      otros: [0, Validators.required],
      observacion: [""],
      _nombres: [""],
      _numero: [""],
      _saldo_anterior: [0],
      _saldo_pasa: [0],
      _total_descuentos: [0],
      _total_propietario: [0],
    });

    this._serviceVehiculos.get_listar("1", "10").subscribe((resp: any) => {
      this.vehiculos = resp.data;
      this.blockUI.stop();
    });

    if (this.isEdit) {
      const id = this._model.retiro_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceRetiros.getbyretiroid(id.toString()).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp.data[0];
      this.frm = this.fb.group({
        retiro_id: [this._model.retiro_id],
        vehiculo_id: [this._model.vehiculo_id, Validators.required],
        propietario_id: [this._model.propietario_id, Validators.required],
        user_id: [this._userSession.user_id, Validators.required],
        fecha_retiro: [this._model.fecha_retiro, Validators.required],
        valor_retiro: [Number(this._model.valor_retiro), Validators.required],
        administracion: [
          Number(this._model.administracion),
          Validators.required,
        ],
        cooperativa: [Number(this._model.cooperativa), Validators.required],
        aportes: [Number(this._model.aportes), Validators.required],
        otros: [Number(this._model.otros), Validators.required],
        observacion: [this._model.observacion],
        _nombres: [this._nombres],
        _numero: [this._model.numero],
        _saldo_pasa: [
          Number(this._model.total_retiro) - Number(this._model.valor_retiro),
        ],
        _saldo_anterior: [
          Number(this._model.saldo) + Number(this._model.valor_retiro),
        ],
        _total_descuentos: [0],
        _total_propietario: [0],
      });
      this.calculos();
      this.blockUI.stop();
    });
  }

  openDialog_Retiros(): void {
    const dialogRef = this.dialog.open(DlgRetirosComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_retiros = result;
        console.log("x.x.x.x", this.data_search_retiros);
        this.frm.controls.vehiculo_id.patchValue(result[0].vehiculo_id);
        this.frm.controls.propietario_id.patchValue(result[0].propietario_id);
        this.frm.controls._saldo_anterior.patchValue(
          parseFloat(result[0].saldo).toFixed(1)
        );
        this.frm.controls._numero.patchValue(result[0]._numero);
        this.frm.controls.valor_retiro.patchValue(
          parseFloat(result[0].saldo).toFixed(1)
        );
        this.frm.controls._nombres.patchValue(result[0].nombres);
        this._vehinum = result[0].numero;
        dialogSubmitSubscription.unsubscribe();
        this.calculos();
      });
  }

  calculos() {
    if (parseFloat(this.frm.controls.valor_retiro.value) > 0) {
      if (
        parseFloat(this.frm.controls.valor_retiro.value) >
        parseFloat(this.frm.controls._saldo_anterior.value)
      ) {
        this.btnActivo = false;
        Swal.fire(
          "Atención",
          "El valor del retiro es mayor que el saldo..",
          "error"
        );
        return;
      }
      this.btnActivo = true;
      let saldo_pasa =
        parseFloat(this.frm.controls._saldo_anterior.value) -
        parseFloat(this.frm.controls.valor_retiro.value);

      let tdescuentos = 0;
      let tpropietario = 0;
      tdescuentos =
        parseFloat(this.frm.controls["administracion"].value) +
        parseFloat(this.frm.controls["cooperativa"].value) +
        parseFloat(this.frm.controls["aportes"].value) +
        parseFloat(this.frm.controls["otros"].value);

      if (tdescuentos > parseFloat(this.frm.controls.valor_retiro.value)) {
        this.btnActivo = false;
        Swal.fire(
          "Atención",
          "El valor del descuento es mayor que el valor del retiro..",
          "error"
        );
        return;
      }

      tpropietario =
        parseFloat(this.frm.controls.valor_retiro.value) - tdescuentos;
      this.frm.controls._saldo_pasa.patchValue(saldo_pasa.toFixed(0));
      this.frm.controls._total_propietario.patchValue(tpropietario.toFixed(0));
      this.frm.controls._total_descuentos.patchValue(tdescuentos.toFixed(0));
    }
  }
}
