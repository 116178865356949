import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { CreditoModel } from "../models/creditos-model";
import { CreditosService } from "../services/creditos.service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { LineasCreditoService } from "../../lineas-credito/services/lineas-credito.service";
import { LineaCreditoModel } from "../../lineas-credito/models/lineas-credito-model";
import { AsociadoModel } from "../../asociados/models/asociados-model";
import { CodeudorModel } from "../../codeudores/models/codeudores-model";
import Swal from "sweetalert2";
import * as moment from "moment";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-estados-credito-new-edit",
  templateUrl: "./creditos-new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreditosNewEditComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  _userSession: any = "0";
  frm: FormGroup;
  isEdit: boolean = false;
  _model: CreditoModel;
  lineas_credito: LineaCreditoModel[] = [];
  list_asociados: AsociadoModel[] = [];
  list_codeudores: CodeudorModel[] = [];
  btnInactivo: boolean = false;
  montoIsNotValid: boolean = false;
  plazoIsNotValid: boolean = false;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private _serviceModel: CreditosService,
    private _serviceLineasCredito: LineasCreditoService
  ) {}

  ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));

    this._serviceLineasCredito.get_lista().subscribe((resp: any) => {
      this.lineas_credito = resp.data;
    });

    this._serviceModel.get_asociados().subscribe((resp: any) => {
      this.list_asociados = resp.data;
      if (resp.status == "error") {
        Swal.fire("Atención: ", resp.msg, "warning");
      }
    });

    this._serviceModel.get_codeudores().subscribe((resp: any) => {
      this.list_codeudores = resp.data;
    });

    this.frm = this.fb.group({
      credito_id: [0],
      asociado_id: [0, Validators.required],
      codeudor_id: [0, Validators.required],
      lineacredito_id: [0, Validators.required],
      estadocredito_id: [0, Validators.required],
      fecha_solicitud: ["", Validators.required],
      fecha_inicio: ["", Validators.required],
      monto: [0, Validators.required],
      plazo_meses: [0, Validators.required],
      tasa_interes: [0, Validators.required],
    });
    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.credito_id;
      this.setForm(id);
    }
  }

  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceModel.getById(id).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp.data[0];
      this.frm = this.fb.group({
        credito_id: [this._model.credito_id],
        asociado_id: [this._model.asociado_id, Validators.required],
        codeudor_id: [this._model.codeudor_id, Validators.required],
        lineacredito_id: [this._model.lineacredito_id, Validators.required],
        estadocredito_id: [this._model.estadocredito_id, Validators.required],
        fecha_solicitud: [
          moment(this._model.fecha_solicitud).format("YYYY-MM-DD"),
          Validators.required,
        ],
        fecha_inicio: [
          moment(this._model.fecha_inicio).format("YYYY-MM-DD"),
          Validators.required,
        ],
        monto: [this._model.monto, Validators.required],
        plazo_meses: [this._model.plazo_meses, Validators.required],
        tasa_interes: [this._model.tasa_interes, Validators.required],
      });
      setTimeout(() => {
        this.validar_datos_credito();
      }, 200);
      this.blockUI.stop();
    });
  }

  solo_numeros(value: string, ctrl: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls[ctrl].setValue(value);
    }
  }

  cambio_linea(value: number) {
    let linea = this.lineas_credito.filter((m) => m.lineacredito_id === value);
    this.frm.controls["tasa_interes"].patchValue(linea[0].tasa_interes);
    this.validar_datos_credito();
  }

  get_codeudores(asociado_id: number) {}

  validar_datos_credito() {
    let linea = this.lineas_credito.filter(
      (m) => m.lineacredito_id === this.frm.controls["lineacredito_id"].value
    );

    let vrmonto = this.frm.controls["monto"].value;
    let vrplazo = this.frm.controls["plazo_meses"].value;

    if (vrmonto > linea[0].monto_maximo) {
      this.btnInactivo = true;
      this.montoIsNotValid = true;
    } else {
      this.btnInactivo = false;
      this.montoIsNotValid = false;
    }

    if (vrplazo > linea[0].plazo_maximo) {
      this.btnInactivo = true;
      this.plazoIsNotValid = true;
    } else {
      this.btnInactivo = false;
      this.plazoIsNotValid = false;
    }
  }
}
