import { Component, Input, OnInit } from "@angular/core";
import { ReportesDespachosService } from "../services/reportes-despachos.service";
import * as moment from "moment";

@Component({
  selector: "app-rpt-informe-cooperativa",
  templateUrl: "./rpt-informe-cooperativa.component.html",
  styleUrls: [],
})
export class RptInformeCooperativaComponent implements OnInit {
  @Input() data_rpt = [];
  @Input() tituloReporte = "";
  @Input() chkBus = false;
  @Input() despacho_liquidacion = "";
  @Input() _bus = "";
  @Input() _cerrada;
  FechaDesde = "";
  FechaHasta = "";
  loading: boolean = false;
  total_abonos: number = 0;
  total_aportes: number = 0;

  constructor(private _serviceReportesDespachos: ReportesDespachosService) {
    this._serviceReportesDespachos.EventDatosInformeCooperativa$.subscribe(
      async (resp: any) => {
        this.data_rpt = resp;
        this.get_Total_Abonos();
        this.get_Total_Aportes();
      }
    );

    this._serviceReportesDespachos.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportesDespachos.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });
  }

  ngOnInit() {}

  async get_Total_Abonos() {
    this.total_abonos = this.data_rpt
      .map((m) => m.abono_credito_cooperativa)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Aportes() {
    this.total_aportes = this.data_rpt
      .map((m) => m.valor_aporte_cooperativa)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  exportAsXLSX() {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `INFORME_COOPERATIVA_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY");

    this.data_rpt = this.data_rpt.map((item, index) => ({
      item: index + 1,
      despacho_nombre: item.despacho_nombre,
      numero: parseInt(item.numero),
      fecha_planilla: item.fecha_planilla,
      fecha_liquidacion: item.fecha_liquidacion,
      planilla_id: item.planilla_id,
      abono_credito_cooperativa: parseFloat(item.abono_credito_cooperativa),
      valor_aporte_cooperativa: parseFloat(item.valor_aporte_cooperativa),
    }));

    this._serviceReportesDespachos.downloadFile_InformeCooperativa(
      this.data_rpt,
      filename,
      periodo
    );
  }
}
