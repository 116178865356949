import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TarifasDescuentosService } from "../../services/tarifas-descuentos.service";
import { TarifaDescuento } from "../../models/tarifas-descuentos.model";

@Component({
  selector: "app-tarifas-descuentos-new",
  templateUrl: "./tarifas-descuentos-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
})
export class TarifasDescuentosNewComponent implements OnInit {
  isLoading: boolean = false;
  frm: FormGroup;
  _model: TarifaDescuento;
  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceTarifasDescuentos: TarifasDescuentosService
  ) {}

  ngOnInit() {
    this.frm = this.fb.group({
      tarifadescuento_id: [0],
      valor: ["", Validators.required],
    });

    if (this.isEdit) {
      const id = this._model.tarifadescuento_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceTarifasDescuentos
      .getbyid(id.toString())
      .subscribe((resp: any) => {
        this._model = resp.data[0];
        this.frm = this.fb.group({
          tarifadescuento_id: [this._model.tarifadescuento_id],
          valor: [this._model.valor, Validators.required],
        });
      });
  }

  change(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls["valor"].setValue(value);
    }
  }
}
