import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { LugarControl } from "src/app/models/lugares-control.model";

@Injectable({
  providedIn: "root",
})
export class LugaresControlService {
  // httpOptions = {};
  private url = URL_API;

  constructor(private http: HttpClient) {}

  getListar(pagina: string, limit: string) {
    // this.loadAuth();
    return this.http
      .get(
        `${this.url}/lugares_control/get_listar/${pagina}/${limit}`
        // this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  get_listar(page: string, limit: string) {
    // this.loadAuth();
    const url = URL_API + "/lugares_control/get_listar/" + page + "/" + limit;
    return this.http.get(url /* this.httpOptions */);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    // this.loadAuth();
    const url =
      URL_API +
      "/lugares_control/get_buscar/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url /* this.httpOptions */);
  }

  getById(id: string) {
    // this.loadAuth();
    return this.http
      .get(`${this.url}/lugares_control/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  getLugaresbyRuta(rutadespacho_id: string) {
    // this.loadAuth();
    return this.http
      .get(
        `${this.url}/lugares_control/get_lugaresbyruta/${rutadespacho_id}`
        // this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  create(model: any) {
    // this.loadAuth();
    return this.http.post(
      `${this.url}/lugares_control/nuevo`,
      model
      // this.httpOptions
    );
  }

  update(id: string, model: LugarControl) {
    // this.loadAuth();
    return this.http.put(
      `${this.url}/lugares_control/update/${id}`,
      model
      // this.httpOptions
    );
  }

  getdelete(id: string) {
    // this.loadAuth();
    return this.http.delete(
      `${this.url}/lugares_control/delete/${id}`
      // this.httpOptions
    );
  }

  /*  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  } */
}
