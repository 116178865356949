import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-list-reportes-despachos",
  templateUrl: "./list-reportes-despachos.component.html",
  styles: [],
})
export class ListReportesDespachosComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
