import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { PlanillaAbonoSS } from "../../../models/planillas-abonos-ss.model";
import { VehiculosService } from "../../../services/vehiculos.service";
import { MatDialog } from "@angular/material";
import { PlanillasAbonosSSService } from "./services/planillas-abonos-ss.service";
import { greaterThanZeroValidator } from "src/app/validators/form-validators";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-planillas-abonos-ss-new",
  templateUrl: "./planillas-abonos-ss-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PlanillasAbonosSSNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  isLoading: boolean = false;
  frm: FormGroup;
  productos = [];
  vehiculos = [];
  data_search_planillas_abonos_ss = [];
  _model: PlanillaAbonoSS;
  _nombres = "";
  isEdit: boolean = false;
  _vehiid: number = 0;
  _vehinum: string = "";
  _saldo_pasa: number = 0;
  _saldo_anterior: number = 0;
  btnActivo: boolean = true;
  _userSession: any = "0";

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _servicePlanillasAbonosSS: PlanillasAbonosSSService,
    private _serviceVehiculos: VehiculosService
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      planillaabonoss_id: [0],
      vehiculo_id: [this._model.vehiculo_id, Validators.required],
      propietario_id: [this._model.propietario_id, Validators.required],
      user_id: [this._userSession.user_id, Validators.required],
      fecha_abono: ["", Validators.required],
      valor_abono: ["", [Validators.required, greaterThanZeroValidator()]],
      observacion: [""],
      _nombres: [this._model.propietario_nombres],
      _numero: [this._model.numero],
      saldo: [this._model.total_saldo_aportes],
    });

    this._serviceVehiculos.get_listar("1", "10").subscribe((resp: any) => {
      this.vehiculos = resp.data;
      this.blockUI.stop();
    });

    if (this.isEdit) {
      const id = this._model.planillaabonoss_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._servicePlanillasAbonosSS
      .getbyabonoid(id.toString())
      .subscribe((resp: any) => {
        this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
        this._model = resp.data[0];
        this.frm = this.fb.group({
          planillaabonoss_id: [this._model.planillaabonoss_id],
          vehiculo_id: [this._model.vehiculo_id, Validators.required],
          propietario_id: [this._model.propietario_id, Validators.required],
          user_id: [this._userSession.user_id, Validators.required],
          fecha_abono: [this._model.fecha_abono, Validators.required],
          valor_abono: [
            Number(this._model.valor_abono),
            [Validators.required, greaterThanZeroValidator()],
          ],
          observacion: [this._model.observacion],
          _nombres: [this._nombres],
          _numero: [this._model.numero],
        });
        this.blockUI.stop();
      });
  }
  change(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls["valor_abono"].setValue(value);
    }
  }
}
