import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";

@Injectable({
  providedIn: "root",
})
export class GraphService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(private http: HttpClient) {}

  get_recaudo_bydia() {
    const url = URL_API + "/graph_recaudos/get_recaudo_bydia/";
    return this.http.get(url, this.httpOptions);
  }

  get_recaudo_bymes() {
    const url = URL_API + "/graph_recaudos/get_recaudo_bymes/";
    return this.http.get(url, this.httpOptions);
  }

  get_recaudobybus() {
    const url = URL_API + "/graph_recaudos/get_graph_recaudobybus/";
    return this.http.get(url, this.httpOptions);
  }

  get_recaudobybusultimosdias() {
    const url = URL_API + "/graph_recaudos/get_recaudobybusultimosdias/";
    return this.http.get(url, this.httpOptions);
  }

  get_recaudo_mes_actual() {
    const url = URL_API + "/graph_recaudos/get_graph_recaudomesactual/";
    return this.http.get(url, this.httpOptions);
  }

  get_graph_saldo_recaudo() {
    const url = URL_API + "/graph_recaudos/get_graph_saldo_recaudo/";
    return this.http.get(url, this.httpOptions);
  }
}
