import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { PlanillasAbonosSSService } from "./services/planillas-abonos-ss.service";
import pageSettings from "../../../config/page-settings";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PlanillaAbonoSS } from "../../../models/planillas-abonos-ss.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanillasAbonosSSNewComponent } from "../planillas-abonos-ss/planillas-abonos-ss-new.component";
import Swal from "sweetalert2";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "app-planillas-abonos-ss",
  templateUrl: "./planillas-abonos-ss.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [],
})
export class PlanillasAbonosSSComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  @ViewChild("bus", { static: false }) numeroElement: ElementRef;
  form: FormGroup;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  pageSettings = pageSettings;
  desde: string = "";
  hasta: string = "";
  data_list = [];
  items = [1, 2, 3, 4];
  buscando: boolean = false;
  buscandolista: boolean = false;
  frmBuscar: FormGroup;
  _totalAportesPeriodo: number = 0;
  _TotalDiasPeriodo: number = 0;
  _cantVehiculos: number = 0;
  _vrAporteSS: number = 0;
  xbus: string = "";
  _chkVehiculoEnabled: boolean = true;

  constructor(
    private _servicePlanillasAbonosSS: PlanillasAbonosSSService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.pageSettings.pageWithoutSidebar = false;
    this.pageSettings.pageTopMenu = false;
    this.form = this.fb.group({
      fecha_desde: [new Date(), Validators.required],
      fecha_hasta: [new Date(), Validators.required],
      tiporeportedespacho_id: [1, Validators.required],
      numero: [""],
      chkvehiculo: [false],
      chkempresa: [false],
      vehiculo_id: [0],
    });
  }

  ngOnInit() {
    console.log("ngOnInit");
    this.buscando = false;
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    // this.getLista();
  }

  /* getLista() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this._servicePlanillasAbonosSS
      .get_abonos_vehiculos_with_saldo()
      .subscribe((resp: any) => {
        this.data_list = resp["data"];
        this._cantVehiculos = this.data_list.length;
        this._TotalDiasPeriodo = resp["count_dias"][0].totalDias;
        this._vrAporteSS = resp["valorssconductor"][0].valor_ss_conductor;
        this._totalAportesPeriodo =
          resp["total_aportes_periodo"][0].total_aportes_fijo;
        this.blockUI.stop();
        this.buscandolista = false;
      });
  } */

  getBuscar(pagina: string, limit: string, buscar: string) {
    console.log("getBuscar");
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.buscando = true;
    this._servicePlanillasAbonosSS
      .get_buscar_with_saldo(pagina, limit, buscar)
      .subscribe((resp: any) => {
        this.data_list = resp.data;
        this.blockUI.stop();
        this.buscando = false;
      });
  }

  new(model: PlanillaAbonoSS) {
    /* console.log("receinewveMessage");
    const ref = this.modalService.open(PlanillasAbonosSSNewComponent, {
      centered: true,
     size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        console.log("Guardar: ", data);
        if (data.valido) {
          this._servicePlanillasAbonosSS
            .create(data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.getLista();
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    ); */
  }

  new_periodo() {
    console.log("new_periodo");
    this.router.navigateByUrl("planillas-abonos-ss-periodo");
  }

  Buscar() {
    console.log("Buscar");
    const value = this.frmBuscar.controls._txtBuscar.value;
    if (value.length > 0) {
      this.buscando = true;
      // this.getBuscar("1", "10", value);
    } else {
      // this.getLista();
    }
  }

  edit(model: PlanillaAbonoSS) {
    console.log("edit");
    const ref = this.modalService.open(PlanillasAbonosSSNewComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._nombres = model.propietario_nombres;
    ref.result.then(
      (data) => {
        if (data.valido) {
          const fechaabono = moment(data.frm.fecha_abono);
          data.frm.fecha_abono = fechaabono;
          this._servicePlanillasAbonosSS
            .update(model.planillaabonoss_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                // this.getLista();
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: PlanillaAbonoSS) {
    console.log("delete");
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: "<p><b>Valor Abono:</b> " + model.valor_abono + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._servicePlanillasAbonosSS
          .getdelete(model.planillaabonoss_id.toString())
          .subscribe((resp: any) => {
            console.log("resp: ", resp);
            if (resp.status === "ok") {
              // this.getLista();
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  get_Total_Aportes_Fijos() {
    let total: number = this.data_list.reduce(
      (acum: number, items: { total_aporte_fijo: number }) => {
        return acum + +items.total_aporte_fijo;
      },
      0
    );
    return total;
  }

  get_Total_Planillas() {
    let total: number = this.data_list.reduce(
      (acum: number, items: { total_aportes_planilla: number }) => {
        return acum + +items.total_aportes_planilla;
      },
      0
    );
    return total;
  }

  get_Total_Abonos() {
    let total: number = this.data_list.reduce(
      (acum: number, items: { total_abonos_ss: number }) => {
        return acum + +items.total_abonos_ss;
      },
      0
    );
    return total;
  }

  get_Total_Saldo() {
    let total: number = this.data_list.reduce(
      (acum: number, items: { total_saldo_aportes: number }) => {
        return acum + +items.total_saldo_aportes;
      },
      0
    );
    return total;
  }

  get_Total_Dias_No_Trabajados() {
    let total: number = this.data_list.reduce(
      (acum: number, items: { total_dias_no_trabajados: number }) => {
        return acum + +items.total_dias_no_trabajados;
      },
      0
    );
    return total;
  }

  change_bus(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.form.controls["numero"].setValue(value);
    }
  }

  change_chk() {
    if (this.form.controls["chkvehiculo"].value) {
      this.numeroElement.nativeElement.focus();
    }
  }

  consultar() {
    this.desde = moment(this.form.controls.fecha_desde.value).format(
      "YYYY-MM-DD 00:00"
    );
    this.hasta = moment(this.form.controls.fecha_hasta.value).format(
      "YYYY-MM-DD 23:59"
    );

    this._TotalDiasPeriodo = calcularDiasEntreFechas(this.desde, this.hasta);
    this.data_list = [];

    this.blockUI.start("Consultando datos. Espere un momento...");

    if (this.form.controls.chkvehiculo.value === true) {
      if (this.form.controls.numero.value === "") {
        Swal.fire("Precaución", "Número de Vehículo no válido...", "warning");
        this.blockUI.stop();
        return false;
      } else {
        this._chkVehiculoEnabled = true;
        this._servicePlanillasAbonosSS
          .get_planillas_pagos_abonos_ssbybus(
            this.desde,
            this.hasta,
            this.form.controls.numero.value
          )
          .subscribe((resp: any) => {
            this.data_list = resp["data"];
            this.data_list.forEach((x) => {
              x.total_saldo_aportes =
                x.total_aporte_fijo -
                x.total_aportes_planilla -
                x.total_abonos_ss;
            });
            this.blockUI.stop();
            this._cantVehiculos = this.data_list.length;
            if (resp.data.length === 0) {
              Swal.fire(
                "Atención",
                "No se encontraron datos para esta consulta...",
                "info"
              );
            }
          });
      }
    } else {
      this._servicePlanillasAbonosSS
        .get_planillas_pagos_abonos_ss(this.desde, this.hasta)
        .subscribe((resp: any) => {
          this.data_list = resp["data"];
          this.data_list.forEach((x) => {
            x.total_saldo_aportes =
              x.total_aporte_fijo -
              x.total_aportes_planilla -
              x.total_abonos_ss;
          });
          this._cantVehiculos = this.data_list.length;
          this.blockUI.stop();
          this.buscandolista = false;
        });
    }
  }

  registrar_abono(model: any) {
    console.log("model", model);
    const ref = this.modalService.open(PlanillasAbonosSSNewComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        console.log("Guardar: ", data);
        if (data.valido) {
          this._servicePlanillasAbonosSS
            .create(data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.consultar();
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  listar_abonos(model: any) {}
}

function calcularDiasEntreFechas(
  fechaInicio: string,
  fechaFin: string
): number {
  const inicio = new Date(fechaInicio);
  const fin = new Date(fechaFin);
  const diferenciaMilisegundos = fin.getTime() - inicio.getTime();
  const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

  return Math.floor(diferenciaDias);
}
