import { createAction, props } from "@ngrx/store";
import { PlanillaConsecutivo } from "../../../../../models/planillas-consecutivos.model";

export const cargarPlanillasConsecutivos = createAction(
  "[Planillas Consecutivos] Cargar Planillas Consecutivos",
  props<{ pagina: string; limit: string }>()
);

export const cargarPlanillasConsecutivosSuccess = createAction(
  "[Planillas Consecutivos] Cargar Planillas Consecutivos Success",
  props<{
    planillasconsecutivos: PlanillaConsecutivo[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarPlanillasConsecutivosError = createAction(
  "[Planillas Consecutivos] Cargar Planillas Consecutivos Error",
  props<{ payload: any }>()
);

export const cargarPlanillasConsecutivosByQuery = createAction(
  "[Planillas Consecutivos] Cargar Planillas Consecutivos Query",
  props<{ pagina: string; limit: string; query: string }>()
);

export const cargarPlanillasConsecutivosByQuerySuccess = createAction(
  "[Planillas Consecutivos] Cargar Planillas Consecutivos Query Success",
  props<{
    planillasconsecutivos: PlanillaConsecutivo[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarPlanillasConsecutivosByQueryError = createAction(
  "[Planillas Consecutivos] Cargar Planillas Consecutivos Query Error",
  props<{ payload: any }>()
);
