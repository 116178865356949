import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Sancion } from "src/app/models/sanciones.model";

@Injectable({
  providedIn: "root",
})
export class SancionesService {
  httpOptions = {};
  private url = URL_API;

  constructor(private http: HttpClient) {}

  getListar(pagina: string, limit: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/sanciones/get_listar/${pagina}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API + "/sanciones/get_buscar/" + pagina + "/" + limit + "/" + buscar;
    return this.http.get(url, this.httpOptions);
  }

  getById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/sanciones/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  getSancion(planilla_id: string, planillarutadespacho_id: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/rutasdespachos_lugares/get_rutadespacho/${planilla_id}/${planillarutadespacho_id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  create(model: any) {
    this.loadAuth();
    return this.http.post(
      `${this.url}/sanciones/nuevo`,
      model,
      this.httpOptions
    );
  }

  update(id: string, model: Sancion) {
    this.loadAuth();
    return this.http.put(
      `${this.url}/sanciones/update/${id}`,
      model,
      this.httpOptions
    );
  }

  getdelete(id: string) {
    this.loadAuth();
    return this.http.delete(
      `${this.url}/sanciones/delete/${id}`,
      this.httpOptions
    );
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
