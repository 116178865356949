import { createAction, props } from "@ngrx/store";
import { Planilla } from "../../../../../models/planillas.model";

export const cargarPlanillas = createAction("[Planillas] Cargar Planillas");

export const cargarPlanillasSuccess = createAction(
  "[Planillas] Cargar Planillas Success",
  props<{
    planillas: Planilla[];
    registros: number;
    pagecount: number;
  }>()
);
export const cargarPlanillasError = createAction(
  "[Planillas] Cargar Planillas Error",
  props<{ payload: any }>()
);

export const cargarPlanillasRegistradas = createAction(
  "[Planillas] Cargar Planillas Registradas",
  props<{ pagina: string; limit: string }>()
);
export const cargarPlanillasRegistradasSuccess = createAction(
  "[Planillas] Cargar Planillas Registradas Success",
  props<{
    planillas: Planilla[];
    registros: number;
    pagecount: number;
  }>()
);
export const cargarPlanillasRegistradasError = createAction(
  "[Planillas] Cargar Planillas Registradas Error",
  props<{ payload: any }>()
);

export const cargarPlanillasRegistradasByPeriodo = createAction(
  "[Planillas] Cargar Planillas Registradas por Periodo",
  props<{ desde: string; hasta: string; limit: number }>()
);
export const cargarPlanillasRegistradasByPeriodoSuccess = createAction(
  "[Planillas] Cargar Planillas Registradas por Periodo Success",
  props<{
    planillas: Planilla[];
    registros: number;
  }>()
);
export const cargarPlanillasRegistradasByPeriodoError = createAction(
  "[Planillas] Cargar Planillas Registradas por Periodo Error",
  props<{ payload: any }>()
);

export const cargarPlanillasByQuery = createAction(
  "[Planillas] Cargar Planillas Query",
  props<{ pagina: string; limit: string; query: string }>()
);

export const cargarPlanillasByQuerySuccess = createAction(
  "[Planillas] Cargar Planillas Query Success",
  props<{
    planillas: Planilla[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarPlanillasByQueryError = createAction(
  "[Planillas] Cargar Planillas Query Error",
  props<{ payload: any }>()
);

export const cargarPlanillasByQueryPeriodo = createAction(
  "[Planillas] Cargar Planillas Query Periodo",
  props<{ desde: string; hasta: string; limit: string; termino: string }>()
);

export const cargarPlanillasByQueryPeriodoSuccess = createAction(
  "[Planillas] Cargar Planillas Query Periodo Success",
  props<{
    planillas: Planilla[];
    registros: number;
  }>()
);

export const cargarPlanillasByQueryPeriodoError = createAction(
  "[Planillas] Cargar Planillas Query Periodo Error",
  props<{ payload: any }>()
);

export const cargarPlanillasRegistradasByUser_id = createAction(
  "[Planillas] Cargar Planillas Registradas por Usuario",
  props<{ pagina: string; limit: string; user_id: number }>()
);

export const cargarPlanillasRegistradasByUser_idSuccess = createAction(
  "[Planillas] Cargar Planillas Registradas por Usuario Success",
  props<{
    planillas: Planilla[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarPlanillasRegistradasByUser_idError = createAction(
  "[Planillas] Cargar Planillas Registradas por Usuario Error",
  props<{ payload: any }>()
);

export const cargarPlanillasRegistradasByUserPeriodo = createAction(
  "[Planillas] Cargar Planillas Registradas por Usuario Periodo",
  props<{ desde: string; hasta: string; limit: string; user_id: number }>()
);

export const cargarPlanillasRegistradasByUserPeriodoSuccess = createAction(
  "[Planillas] Cargar Planillas Registradas por Usuario Periodo Success",
  props<{
    planillas: Planilla[];
    registros: number;
  }>()
);

export const cargarPlanillasRegistradasByUserPeriodoError = createAction(
  "[Planillas] Cargar Planillas Registradas por Usuario Periodo Error",
  props<{ payload: any }>()
);
