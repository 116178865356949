import { usersEffects } from "./users.effects";
import { userEffects } from "./user.effects";
import { PlanillaParametrosEffects } from "../../despachos/pages/planilla-parametros/store/effects/planilla-parametros.effects";
import { PlanillaParametroEffects } from "../../despachos/pages/planilla-parametros/store/effects/planilla-parametro.effects";
import { AniosEffects } from "src/app/despachos/pages/anios/store/effects/anios.effects";
import { PlanillasConsecutivosEffects } from "src/app/despachos/pages/planillas-consecutivos/store/effects/planillas-consecutivos.effects";
import { PlanillaConsecutivoEffects } from "src/app/despachos/pages/planillas-consecutivos/store/effects/planilla-consecutivo.effects";
import { RutasDespachosEffects } from "src/app/despachos/pages/rutas-despachos/store/effects/rutas-despachos.effects";
import { RutaDespachoEffects } from "src/app/despachos/pages/rutas-despachos/store/effects/ruta-despacho.effects";
import { PlanillaLiquidacionEffects } from "src/app/despachos/pages/planillas-liquidacion/store/effects/planilla-liquidacion.effects";
import { PlanillasLiquidacionEffects } from "src/app/despachos/pages/planillas-liquidacion/store/effects/planillas-liquidacion.effects";
import { PlanillaEffects } from "src/app/despachos/pages/planillas/store/effects/planilla.effects";
import { PlanillasEffects } from "src/app/despachos/pages/planillas/store/effects/planillas.effects";
import { LugarControlEffects } from "src/app/despachos/pages/lugares-control/store/effects/lugar-control.effects";
import { LugaresControlEffects } from "src/app/despachos/pages/lugares-control/store/effects/lugares-control.effects";
import { RutasDespachosLugaresEffects } from "src/app/despachos/pages/rutasdespachos-lugares/store/effects/rutasdespachos-lugares.effects";
import { RutaDespachoLugarEffects } from "src/app/despachos/pages/rutasdespachos-lugares/store/effects/rutadespacho-lugar.effects";
import { PlanillaRutaDespachoEffects } from "src/app/despachos/pages/planillas-rutasdespachos/store/effects/planilla_rutadespacho.effects";
import { PlanillasRutasDespachosEffects } from "src/app/despachos/pages/planillas-rutasdespachos/store/effects/planillas_rutasdespachos.effects";
import { SancionesEffects } from "src/app/despachos/pages/sanciones/store/effects/sanciones.effects";
import { SancionEffects } from "src/app/despachos/pages/sanciones/store/effects/sancion.effects";
import { PlanillasSancionesEffects } from "src/app/despachos/pages/planillas-sanciones/store/effects/planillas_sanciones.effects";
import { PlanillaSancionEffects } from "src/app/despachos/pages/planillas-sanciones/store/effects/planilla_sancion.effects";
import { EmpresaEffects } from "src/app/pages/empresa/store/effects/empresa.effects";

export const EffectsArray: any[] = [
  AniosEffects,
  usersEffects,
  userEffects,
  PlanillaParametrosEffects,
  PlanillaParametroEffects,
  PlanillasConsecutivosEffects,
  PlanillaConsecutivoEffects,
  PlanillaLiquidacionEffects,
  PlanillasLiquidacionEffects,
  PlanillaEffects,
  PlanillasEffects,
  RutasDespachosEffects,
  RutaDespachoEffects,
  LugarControlEffects,
  LugaresControlEffects,
  RutasDespachosLugaresEffects,
  RutaDespachoLugarEffects,
  PlanillaRutaDespachoEffects,
  PlanillasRutasDespachosEffects,
  SancionesEffects,
  SancionEffects,
  PlanillasSancionesEffects,
  PlanillaSancionEffects,
  EmpresaEffects,
];
