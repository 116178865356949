import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { UserDespacho } from "../../models/users_despachos.model";
import { UsersDespachosService } from "../../services/users-despachos.service";
import { MatDialog } from "@angular/material";
import { DlgDespachosComponent } from "../../busquedas/dlg-despachos.component";
import { DespachosService } from "src/app/services/despachos.service";
import { DlgUsersComponent } from "src/app/busquedas/dlg-users.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-users-despachos-new",
  templateUrl: "./users-despachos-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class UsersDespachosNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 380;
  isLoading: boolean = false;
  frm: FormGroup;
  despachos = [];
  data_search_despachos = [];
  data_search_users = [];
  _model: UserDespacho;
  isEdit: boolean = false;
  _condid: number = 0;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceDespachos: DespachosService,
    private _serviceUsersDespachos: UsersDespachosService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.frm = this.fb.group({
      user_id: [0],
      despacho_id: [0, Validators.required],
      user_nombres: [""],
      _despacho_nombres: ["", Validators.required],
      _user_nombres: ["", Validators.required],
    });

    this._serviceDespachos.get_listar("1", "10").subscribe((resp: any) => {
      this.despachos = resp.data;
      this.blockUI.stop();
    });

    if (this.isEdit) {
      const id = this._model.userdespacho_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceUsersDespachos
      .getbyid(id.toString())
      .subscribe((resp: any) => {
        this._model = resp.data[0];
        console.log("Datos=====> ", resp.data[0]);
        this.frm = this.fb.group({
          user_id: [this._model.user_id],
          despacho_id: [resp.data[0].despacho_id, Validators.required],
          _user_nombres: [this._model.user_nombres],
          _despacho_nombres: [
            resp.data[0].despacho_nombres,
            Validators.required,
          ],
        });
        this.blockUI.stop();
      });
  }

  openDialog_Users(): void {
    const dialogRef = this.dialog.open(DlgUsersComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_users = result;
        console.log(".x.x.x.", this.data_search_users);
        this.frm.controls.user_id.patchValue(result[0].user_id);
        this.frm.controls._user_nombres.patchValue(result[0].user_nombres);
        dialogSubmitSubscription.unsubscribe();
      });
  }

  openDialog_Despachos(): void {
    const dialogRef = this.dialog.open(DlgDespachosComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit,
        _user_id: -1,
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_despachos = result;
        this.frm.controls.despacho_id.patchValue(result[0].despacho_id);
        this.frm.controls._despacho_nombres.patchValue(
          result[0].despacho_nombres
        );
        this._condid = result[0].despacho_id;
        dialogSubmitSubscription.unsubscribe();
      });
  }
}
