import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as lugarescontrolActions from "../../store/actions/lugares-control.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { LugaresControlService } from "../../../../pages/lugares-control/services/lugares-control.service";
import { of } from "rxjs";

@Injectable()
export class LugaresControlEffects {
  constructor(
    private actions$: Actions,
    private lugarescontrolService: LugaresControlService
  ) {}

  cargarLugaresControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lugarescontrolActions.cargarLugaresControl),
      mergeMap((action) =>
        this.lugarescontrolService.getListar(action.pagina, action.limit).pipe(
          map((resp) =>
            lugarescontrolActions.cargarLugaresControlSuccess({
              lugarescontrol: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              lugarescontrolActions.cargarLugaresControlError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  cargarLugaresControlByQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lugarescontrolActions.cargarLugaresControlByQuery),
      mergeMap((action) =>
        this.lugarescontrolService
          .get_buscar(action.pagina, action.limit, action.query)
          .pipe(
            map((resp) =>
              lugarescontrolActions.cargarLugaresControlByQuerySuccess({
                lugarescontrol: resp["data"],
                registros: resp["registros"],
                pagecount: resp["pageCount"],
              })
            ),
            catchError((err) =>
              of(
                lugarescontrolActions.cargarLugaresControlByQueryError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );
}
