import {
  Component,
  Inject,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RetiroDescuentosService } from "../services/retiro-descuentos.service";
import { RetiroDescuento } from "../models/retiro-descuentos.model";
import { PaginadorService } from "../paginador/paginador.service";
import { FormBuilder, FormGroup } from "@angular/forms";

export interface DialogData {
  vehiculo_id: number;
  numero: number;
  total_descuento: number;
  saldo: number;
}

@Component({
  selector: "app-dlg-descuentos",
  templateUrl: "./dlg-descuentos.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      mat-form-field {
        width: 100%;
        font-size: 10px;
      }
      .btndlg.btn.btn-link {
        padding-bottom: 0px;
        padding-top: 4px;
      }
      mat-dialog {
        width: 200%;
      }
    `,
  ],
})
export class DlgDescuentosComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<any>();
  data_model: RetiroDescuento[] = [];
  Titulo: string = "Descuentos";
  buscando: boolean = false;
  frmBuscar: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DlgDescuentosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _serviceModel: RetiroDescuentosService,
    private _servicePaginador: PaginadorService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.data_model = [];
    this.buscando = false;
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    this.getLista("1", "10");
    console.log("isEdit => ", this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  BuscarRegistro(pagina: string, recxpag: string, buscar: string) {
    this.buscando = true;
    this._serviceModel
      .get_buscar_descuento_vehiculos(pagina, recxpag, buscar)
      .subscribe((resp: any) => {
        this.data_model = resp.data;
        this._servicePaginador.page$.emit(pagina);
        this._servicePaginador.registros$.emit(resp.registros);
      });
  }

  onSelect(id: number) {
    this.submitClicked.emit(
      this.data_model
        .filter((x) => x.vehiculo_id === id)
        .map((m) => ({
          vehiculo_id: m.vehiculo_id,
          vehiculo_numero: m.vehiculo_numero,
          cliente_nombre: m.cliente_nombre,
          total_descuento: m.total_descuento,
          saldo: m.saldo,
        }))
    );
    this.dialogRef.close();
  }

  getLista(pagina: string, recxpag: string) {
    this._serviceModel
      .get_listar_vehiculos_descuentos(pagina, recxpag)
      .subscribe((resp: any) => {
        this.data_model = resp.data;
        this._servicePaginador.page$.emit(pagina);
        this._servicePaginador.registros$.emit(resp.registros);
      });
  }

  receiveMessage($event: any) {
    this.getLista($event.pagina, $event.recxpag);

    if (this.frmBuscar.controls._txtBuscar.value === "") {
      this.buscando = false;
    }

    if (!this.buscando) {
      this.getLista($event.pagina, $event.recxpag);
    }

    if (this.buscando) {
      const value = this.frmBuscar.controls._txtBuscar.value;
      this.BuscarRegistro($event.pagina, $event.recxpag, value);
    }
  }

  Buscar() {
    const value = this.frmBuscar.controls._txtBuscar.value;
    if (value.length > 0) {
      this.buscando = true;
      this.BuscarRegistro("1", "10", value);
    } else {
      this.getLista("1", "10");
    }
  }
}
