import {
  Component,
  Inject,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RutasDespachosService } from "../despachos/pages/rutas-despachos/services/rutas-despachos.service";
import { RutaDespacho } from "../models/rutas-despachos.model";
import { PaginadorService } from "../paginador/paginador.service";

export interface DialogData {
  ruta_id: number;
  nombre: string;
}

@Component({
  selector: "app-dlg-rutas-despachos",
  templateUrl: "./dlg-rutas-despachos.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      mat-form-field {
        width: 100%;
        font-size: 10px;
      }

      .btndlg.btn.btn-link {
        padding-bottom: 0px;
        padding-top: 4px;
      }
      mat-dialog {
        width: 200%;
      }

      /* .autocomplete-container .suggestions-container ul li a {
        padding: 4px 15px !important;
        display: block !important;
        text-decoration: none !important;
        cursor: pointer !important;
        color: rgba(0, 0, 0, 0.87) !important;
        font-size: 12px !important;
      } */
    `,
  ],
})
export class DlgRutasDespachosComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<any>();
  data_model: RutaDespacho[] = [];
  Titulo: string = "Rutas Despachos";
  keyword = "nombre";

  constructor(
    public dialogRef: MatDialogRef<DlgRutasDespachosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _serviceModel: RutasDespachosService,
    private _servicePaginador: PaginadorService
  ) {}

  ngOnInit() {
    this.data_model = [];
    this.getLista(1, 100);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  BuscarRegistro(buscar: string) {
    if (buscar.length > 0) {
      this._serviceModel
        .get_buscar("1", "10", buscar)
        .subscribe((resp: any) => {
          this.data_model = resp.data;
        });
    } else {
      this.getLista(1, 10);
    }
  }

  onSelect(id: number) {
    this.submitClicked.emit(
      this.data_model
        .filter((x) => x.rutadespacho_id === id)
        .map((m) => ({
          rutadespacho_id: m.rutadespacho_id,
          nombre: m.nombre,
        }))
    );
    this.dialogRef.close();
  }

  getLista(pagina: number, recxpag: number) {
    this._serviceModel
      .get_listar(pagina.toString(), recxpag.toString())
      .subscribe((resp: any) => {
        this.data_model = resp.data;
        this._servicePaginador.page$.emit(pagina);
        this._servicePaginador.registros$.emit(resp.registros);
      });
  }

  receiveMessage($event: any) {
    this.getLista($event.pagina, $event.recxpag);
  }

  selectEvent(item) {
    this.submitClicked.emit(
      this.data_model
        .filter((x) => x.rutadespacho_id === item.rutadespacho_id)
        .map((m) => ({
          rutadespacho_id: m.rutadespacho_id,
          nombre: m.nombre,
        }))
    );
    this.dialogRef.close();
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.customFilter(val);
  }

  onFocused(e) {
    // do something when input is focused
  }

  customFilter(query: string) {
    /*  return this.data_model.filter((item: any) => {
      console.log(item.nombre);
      return item.nombre.toLowerCase().indexOf(query) > -1;
    }); */
  }
}
