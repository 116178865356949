import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AniosNewEditComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;

  frm: FormGroup;
  _userSession: any = "0";
  _numero = "";
  isEdit: boolean = false;

  constructor(private fb: FormBuilder, public modal: NgbActiveModal) {}

  ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      anio_id: [0],
      anio: [0, Validators.required],
    });
  }
  get fdata() {
    return this.frm.controls;
  }
  solo_numeros(value: string, ctrl: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls[ctrl].setValue(value);
    }
  }
}
