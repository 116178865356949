import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../../../../config/config";
import { PlanillaAbonoSS } from "../../../../models/planillas-abonos-ss.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PlanillasAbonosSSService {
  httpOptions = {};
  private url = URL_API;
  FechaDesde$ = new EventEmitter<any>();
  FechaHasta$ = new EventEmitter<any>();
  mes$ = new EventEmitter<String>();
  bus$ = new EventEmitter<String>();

  constructor(private http: HttpClient) {}

  get_listar(page: string, limit: string) {
    this.loadAuth();
    const url =
      URL_API + "/planillas_abonos_ss/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  /* get_abonos_vehiculos_with_saldo() {
 this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas_abonos_ss/get_abonos_vehiculos_with_saldo`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp["data"];
        })
      ); 
    this.loadAuth();
    const url =
      URL_API + "/planillas_abonos_ss/get_abonos_vehiculos_with_saldo";
    return this.http.get(url, this.httpOptions);
  }*/

  get_abonos_ssByBusPaginado(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    bus: string
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/planillas_abonos_ss/get_abonos_ssbybus/${page}/${limit}/${desde}/${hasta}/${bus}`;
    return this.http.get(url, this.httpOptions);
  }

  get_planillas_pagos_abonos_ss(desde: string, hasta: string) {
    this.loadAuth();
    let url =
      URL_API +
      `/planillas_abonos_ss/get_planillas_pagos_abonos_ss/${desde}/${hasta}`;
    return this.http.get(url, this.httpOptions);
  }

  get_planillas_pagos_abonos_ssbybus(
    desde: string,
    hasta: string,
    bus: string
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/planillas_abonos_ss/get_planillas_pagos_abonos_ssbybus/${desde}/${hasta}/${bus}`;
    return this.http.get(url, this.httpOptions);
  }

  get_list_abonosbybus(
    page: string,
    limit: string,
    desde: string,
    hasta: string,
    bus: string
  ) {
    this.loadAuth();
    let url =
      URL_API +
      `/planillas_abonos_ss/get_list_abonosbybus/${page}/${limit}/${desde}/${hasta}/${bus}`;
    return this.http.get(url, this.httpOptions);
  }

  /* get_listar_vehiculos_abonos(page: string, limit: string) {
    this.loadAuth();
    const url =
      URL_API +
      "/planillas_abonos_ss/get_listar_vehiculos_abonos/" +
      page +
      "/" +
      limit;
    return this.http.get(url, this.httpOptions);
  } */

  /* get_buscar_abonos_vehiculos(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API +
      "/planillas_abonos_ss/get_buscar_abonos_vehiculos/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }
 */
  /* get_buscar_abonos_vehiculos_with_saldo(
    pagina: string,
    limit: string,
    buscar: string
  ) {
    this.loadAuth();
    const url =
      URL_API +
      "/planillas_abonos_ss/get_buscar_abonos_vehiculos_with_saldo/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    console.log(url);
    return this.http.get(url, this.httpOptions);
  } */

  get_buscar(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API +
      "/planillas_abonos_ss/get_buscar/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar_with_saldo(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API +
      "/planillas_abonos_ss/get_buscar_with_saldo/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    this.loadAuth();
    const url = URL_API + "/planillas_abonos_ss/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    this.loadAuth();
    const url = URL_API + "/planillas_abonos_ss/get_byid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  getbyabonoid(id: string) {
    this.loadAuth();
    const url = URL_API + "/planillas_abonos_ss/get_byabonoid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  update(id: string, model: PlanillaAbonoSS) {
    this.loadAuth();
    const url = URL_API + "/planillas_abonos_ss/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    this.loadAuth();
    const url = URL_API + "/planillas_abonos_ss/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
