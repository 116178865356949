import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PaginadorxService {
  registrosx$ = new EventEmitter<any>();
  pagex$ = new EventEmitter<any>();
  recxpagx$ = new EventEmitter<any>();

  constructor() {}
}
