import { createAction, props } from "@ngrx/store";
import { Sancion } from "../../../../../models/sanciones.model";

export const cargarSanciones = createAction(
  "[Sanciones] Cargar Sanciones",
  props<{ pagina: string; limit: string }>()
);

export const cargarSancionesSuccess = createAction(
  "[Sanciones] Cargar Sanciones Success",
  props<{
    sanciones: Sancion[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarSancionesError = createAction(
  "[Sanciones] Cargar Sanciones Error",
  props<{ payload: any }>()
);

export const cargarSancionesByQuery = createAction(
  "[Sanciones] Cargar Sanciones Query",
  props<{ pagina: string; limit: string; query: string }>()
);

export const cargarSancionesByQuerySuccess = createAction(
  "[Sanciones] Cargar Sanciones Query Success",
  props<{
    sanciones: Sancion[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarSancionesByQueryError = createAction(
  "[Sanciones] Cargar Sanciones Query Error",
  props<{ payload: any }>()
);
