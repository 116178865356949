import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";
import { Retiro } from "../models/retiros.model";

@Injectable({
  providedIn: "root",
})
export class RetirosService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(private http: HttpClient) {}

  get_listar(page: string, limit: string) {
    const url = URL_API + "/retiros/get_listar/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_with_saldo(page: string, limit: string) {
    // console.log("get_listar_with_saldo");
    const url =
      URL_API + "/retiros/get_listar_with_saldo/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_retiros(page: string, limit: string) {
    const url = URL_API + "/retiros/get_listar_retiros/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_listar_vehiculos_retiros(page: string, limit: string) {
    const url =
      URL_API + "/retiros/get_listar_vehiculos_retiros/" + page + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar_retiros_vehiculos(pagina: string, limit: string, buscar: string) {
    const url =
      URL_API +
      "/retiros/get_buscar_retiros_vehiculos/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar_retiros_vehiculos_with_saldo(
    pagina: string,
    limit: string,
    buscar: string
  ) {
    const url =
      URL_API +
      "/retiros/get_buscar_retiros_vehiculos_with_saldo/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    console.log(url);
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url =
      URL_API + "/retiros/get_buscar/" + pagina + "/" + limit + "/" + buscar;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar_with_saldo(pagina: string, limit: string, buscar: string) {
    const url =
      URL_API +
      "/retiros/get_buscar_with_saldo/" +
      pagina +
      "/" +
      limit +
      "/" +
      buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    const url = URL_API + "/retiros/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + "/retiros/get_byid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  getbyretiroid(id: string) {
    const url = URL_API + "/retiros/get_byretiroid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  update(id: string, model: Retiro) {
    const url = URL_API + "/retiros/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    const url = URL_API + "/retiros/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }
}
