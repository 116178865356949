import { AuthValidatorComponent } from './auth-validator.component';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from './../services/users.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent implements OnInit {

  form: FormGroup;
  test: Date = new Date();
  isEdit: boolean = false;
  messageLogin = '';

  constructor(
    private fb: FormBuilder,
    private _serviceUsers: UsersService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      user_id: [0],
      // nombre: ['', Validators.required, AuthValidatorComponent.gte],
      nombre: ['', Validators.required],
      // email: ['', Validators.compose([Validators.required, Validators.email])],
      email: ['', Validators.compose([Validators.required, Validators.email]), AuthValidatorComponent.existingEmailValidator(this._serviceUsers)],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      activo: 1
    }, {
        validator: AuthValidatorComponent.MatchPassword
      });
  }

  get fdata() { return this.form.controls; }

  onRegister() {
    console.log("frm", this.form.controls);
    if (this.form.valid) {
      this._serviceUsers.create(this.form.value).subscribe((resp: any) => {
          this.router.navigate(['/usuarios']);
      });
      
    }
  }

}
