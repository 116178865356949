import { createAction, props } from "@ngrx/store";
import { PlanillaSancion } from "../../../../../models/planillas-sanciones.model";

export const cargarPlanillasSanciones = createAction(
  "[Planillas Sanciones] Cargar Planillas Sanciones"
);

export const cargarPlanillasSancionesSuccess = createAction(
  "[Planillas Sanciones] Cargar Planillas Sanciones Success",
  props<{
    planillassanciones: PlanillaSancion[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarPlanillasSancionesError = createAction(
  "[Planillas Sanciones] Cargar Planillas Sanciones Error",
  props<{ payload: any }>()
);
