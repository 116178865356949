import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { TiposDocumentoService } from '../../services/tipos-documento.service';
import { ClientesService } from '../../services/clientes.service';
import { TarifasDescuentosService } from '../../services/tarifas-descuentos.service';
import { TiposClienteService } from '../../services/tipos-cliente.service';
import { Cliente } from '../../models/clientes.model';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-clientes-new',
  templateUrl: './clientes-new.component.html',
  styles: [
    `
    input.form_control{ margin-top: 4px;}
    `
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ClientesNewComponent implements OnInit {

  isLoading: boolean = false;
  frm: FormGroup;
  tipos_documento = [];
  tipos_cliente = [];
  tarifas_descuento = [];
  _model: Cliente;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceTiposDocumento: TiposDocumentoService,
    private _serviceClientes: ClientesService,
    private _serviceTarifas: TarifasDescuentosService,
    private _serviceTiposCliente: TiposClienteService
    ) { }

  ngOnInit() {
        this.frm = this.fb.group({
        cliente_id: [0],
        tipodocumento_id: [3, Validators.required],
        tarifadescuento_id: [1, Validators.required],
        tipocliente_id: [1, Validators.required],
        documento: ['', Validators.required],
        papellido: ['', Validators.required],
        sapellido: [''],
        pnombre: ['', Validators.required],
        snombre: [''],
        direccion: [''],
        celular: [''],
        email: ['', Validators.email],
        fecha_ingreso: ['', Validators.required],
        fecha_sistema: [Date()]
      });

    this._serviceTiposDocumento.getLista().subscribe((resp: any) => {
      this.tipos_documento = resp.data;
    });

    this._serviceTiposCliente.getLista().subscribe((resp: any) => {
      this.tipos_cliente = resp.data;
    });

    this._serviceTarifas.get_listar().subscribe((resp: any) => {
      this.tarifas_descuento = resp.data;
    });

    if (this.isEdit) {
      const id = this._model.cliente_id;
      this.setForm(id);
    }
    
  }
  get fdata() { return this.frm.controls; }

  private setForm (id: number) {
    this._serviceClientes.getbyid(id.toString()).subscribe((resp: any) => {
        this._model = resp.data[0];
        this.frm = this.fb.group({
          cliente_id: [this._model.cliente_id],
          tipocliente_id: [this._model.tipocliente_id, Validators.required],
          tarifadescuento_id: [this._model.tarifadescuento_id, Validators.required],
          tipodocumento_id: [this._model.tipodocumento_id, Validators.required],
          documento: [this._model.documento, Validators.required],
          papellido: [this._model.papellido, Validators.required],
          sapellido: [this._model.sapellido],
          pnombre: [this._model.pnombre, Validators.required],
          snombre: [this._model.snombre],
          direccion: [this._model.direccion],
          celular: [this._model.celular],
          email: [this._model.email, Validators.email],
          fecha_ingreso: [this._model.fecha_ingreso, Validators.required],
          fecha_sistema: [moment(Date()).format('YYYY-MM-DD')]
        });
    });

  }
}
