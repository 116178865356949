import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ReportesService } from "../../../services/reportes.service";
import * as moment from "moment";
@Component({
  selector: "app-rpt-totales-recaudos",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./rpt-totales-recaudos.component.html",
  styles: [],
})
export class RptTotalesRecaudosComponent implements OnInit {
  @Input() datax = [];
  data_rpt = [];
  tituloReporte = "INFORME TOTALES POR PERIODO";
  fileName = "";
  FechaDesde = "";
  FechaHasta = "";
  Empresa = "";
  loading: boolean = false;
  _mes = "";
  _bus = "";

  constructor(private _serviceReportes: ReportesService) {
    this._serviceReportes.EventDatosTotalesRecaudos$.subscribe(
      async (resp: any) => {
        this.data_rpt = resp;
        let t_producido = 0;
        let t_ahorro = 0;
        let t_cooperativa = 0;
        let t_planilla = 0;
        let t_acpm = 0;
        let t_conductor = 0;
        this.data_rpt.forEach((item) => {
          item.numero = parseInt(item.numero);
          item.producido = parseFloat(item.producido);
          item.ahorro = parseFloat(item.ahorro);
          item.cooperativa = parseFloat(item.cooperativa);
          item.planilla = parseFloat(item.planilla);
          item.acpm = parseFloat(item.acpm);
          item.pago_conductor = parseFloat(item.pago_conductor);

          t_producido += parseFloat(item.producido);
          t_ahorro += parseFloat(item.ahorro);
          t_cooperativa += parseFloat(item.cooperativa);
          t_planilla += parseFloat(item.planilla);
          t_acpm += parseFloat(item.acpm);
          t_conductor += parseFloat(item.pago_conductor);
        });
      }
    );

    this._serviceReportes.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportes.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });

    this._serviceReportes.mes$.subscribe((resp: any) => {
      this._mes = resp;
    });

    this._serviceReportes.bus$.subscribe((resp: any) => {
      this._bus = resp;
    });
  }

  ngOnInit() {}

  exportAsXLSX(): void {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `TOTALES_RECAUDOS_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY");

    this._serviceReportes.downloadFile_TotalesRecaudos(
      this.datax,
      filename,
      periodo
    );
  }

  get_Total_Producido() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.producido);
    });
    return total;
  }

  get_Total_Ahorro() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.ahorro);
    });
    return total;
  }

  get_Total_Cooperativa() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.cooperativa);
    });
    return total;
  }

  get_Total_Planilla() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.planilla);
    });
    return total;
  }

  get_Total_ACPM() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.acpm);
    });
    return total;
  }

  get_Total_PagoConductor() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.pago_conductor);
    });
    return total;
  }
}
