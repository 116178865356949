import { Component, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MarcasService } from "../../services/marcas.service";
import { Marca } from "../../models/marcas.model";

@Component({
  selector: "app-marcas-new",
  templateUrl: "./marcas-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
})
export class MarcasNewComponent implements OnInit {
  @BlockUI("data-sectionx") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  isLoading: boolean = false;
  frm: FormGroup;
  _model: Marca;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceMarcas: MarcasService
  ) {}

  ngOnInit() {
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.frm = this.fb.group({
      marca_id: [0],
      nombre: ["", Validators.required],
    });

    if (this.isEdit) {
      const id = this._model.marca_id;
      this.setForm(id);
    }
    this.blockUI.stop();
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceMarcas.getbyid(id.toString()).subscribe((resp: any) => {
      this._model = resp.data[0];
      this.frm = this.fb.group({
        marca_id: [this._model.marca_id],
        nombre: [this._model.nombre, Validators.required],
      });
      this.blockUI.stop();
    });
  }
}
