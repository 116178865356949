import { title } from "process";

const pageMenus = [
  {
    icon: "fa fa-th-large",
    title: "Dashboard",
    url: "",
    caret: "true",
    state: "expand",
    hide: true,
    expand: "true",
    submenu: [
      {
        url: "dashboard/v1",
        title: "Dashboard v1",
      },
      {
        url: "dashboard/v2",
        title: "Dashboard v2",
      },
      {
        url: "dashboard/v3",
        title: "Dashboard v3",
      },
    ],
  },
  {
    icon: "fa fa-home",
    title: "Inicio",
    url: "/home",
  },
  {
    icon: "fa fa-cogs",
    title: "Configuración",
    url: "",
    caret: "true",
    submenu: [
      {
        url: "/empresa",
        title: "Empresa",
      },
      {
        url: "/propietarios",
        title: "Propietarios",
      },
      {
        url: "/conductores",
        title: "Conductores",
      },
      {
        url: "/socios",
        title: "Socios",
      },
      {
        url: "/bancos",
        title: "Bancos",
      },
      {
        url: "/tipos_cuentas_bancarias",
        title: "Tipos Cuentas Bancarias",
      },
      {
        url: "/marcas",
        title: "Marcas",
      },
      {
        url: "/rutas",
        title: "Rutas",
      },
      {
        url: "/grupos",
        title: "Grupos",
      },
      {
        url: "/vehiculos",
        title: "Vehículos",
      },
      {
        url: "/vehiculos-conductores",
        title: "Vehículos y Conductores",
      },
      {
        url: "/users-despachos",
        title: "Usuarios y Despachos",
      },
      {
        icon: "fa fa-search",
        title: "Usuarios",
        url: "",
        caret: "true",
        submenu: [
          {
            url: "/usuarios",
            title: "Usuarios del Sistema",
          },
          {
            url: "/roles",
            title: "Roles de Usuarios",
          },
        ],
      },
    ],
  },
  {
    icon: "fas fa-print",
    title: "Reportes",
    url: "/reportes-recaudos",
  },
  {
    icon: "fas fa-dollar",
    title: "Recaudos",
    url: "/recaudos",
  },
  {
    icon: "fas fa-hand-holding-usd",
    title: "Retiros",
    url: "/retiros",
  },
  {
    icon: "fas fa-hospital",
    title: "Movimientos",
    url: "/movimientos",
  },

  {
    icon: "fa fa-search",
    title: "Consultas",
    url: "",
    caret: "true",
    submenu: [
      {
        url: "/consulta-retiros",
        title: "Retiros",
      },
      {
        url: "/consulta-movimientos",
        title: "Movimientos",
      },
    ],
  },

  {
    icon: "fas fa-chart-line",
    title: "Dashboard",
    url: "/dashboard",
  },
  {
    icon: "fa fa-bus",
    title: "Despachos",
    url: "",
    caret: "true",
    submenu: [
      {
        url: "/despachos/planilla-parametros",
        title: "Parámetros de Planilla",
      },
      {
        url: "/despachos/planillas",
        title: "Planillas",
      },
      {
        url: "/despachos/lugares-control",
        title: "Lugares de Control",
      },
      {
        url: "/despachos/rutas-despachos",
        title: "Rutas Despachos",
      },
      {
        url: "/despachos/sanciones",
        title: "Sanciones",
      },
      {
        url: "/despachos/abonos-ss",
        title: "Abonos SS",
      },
      {
        icon: "fas fa-print",
        title: "Reportes",
        url: "/despachos/reportes-despachos",
      },
      {
        icon: "fa fa-search",
        title: "Consultas",
        url: "",
        caret: "true",
        submenu: [
          {
            url: "/despachos/consulta-abonos",
            title: "Abonos",
          },
          {
            url: "/despachos/monitor-planillas",
            title: "Monitor",
          },
          {
            url: "/despachos/resumen-liquidacion-planilla",
            title: "Control de Tiempos",
          },
        ],
      },
      {
        icon: "fa fa-search",
        title: "Utilidades",
        url: "",
        caret: "true",
        submenu: [
          {
            url: "/despachos/cards-rutas",
            title: "Tarjetas de Rutas",
          },
        ],
      },

      {
        url: "/despachos/geolocalizacion",
        title: "Geolocalización",
      },
      /* {
        url: "/despachos/impresion",
        title: "Impresión",
      }, */
    ],
  },

  /* {
    icon: "fa fa-home",
    title: "Cooperativa",
    url: "",
    caret: "true",
    submenu: [
      {
        url: "/cooperativa/simulador-credito",
        title: "Simulador de Crédito",
      },
      {
        url: "/cooperativa/lineas-credito",
        title: "Lineas de Crédito",
      },
      {
        url: "/cooperativa/estados-credito",
        title: "Estados de Crédito",
      },
      {
        url: "/cooperativa/parametros",
        title: "Parámetros",
      },
      {
        url: "/cooperativa/parametros-credito",
        title: "Parámetros de Crédito",
      },
      {
        url: "/cooperativa/creditos",
        title: "Créditos",
      },
    ],
  }, */
  /* {
    icon: "fa fa-phone",
    title: "SMS",
    url: "",
    caret: "true",
    submenu: [
      {
        url: "/enviar-mensaje",
        title: "Enviar mensaje SMS",
      },
    ],
  }, */

  /* {
    icon: "fas fa-chart-line",
    title: "Admin Dashboard",
    url: "/admin",
  },
  {
    icon: "fas fa-chart-line",
    title: "User Dashboard",
    url: "/user",
  }, */
  /*   {
    icon: "fa fa-key",
    title: "Login & Registro",
    url: "",
    caret: "true",
    submenu: [
      {
        url: "login",
        title: "Login",
      },
      {
        url: "/register",
        title: "Registrar",
      },
    ],
  }, */
];

export default pageMenus;
