import { Component, OnInit } from "@angular/core";
import pageSettings from "../../config/page-settings";
import { User } from "src/app/models/auth/users.model";
import { EmpresaService } from "src/app/services/empresa.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { AppState } from "src/app/app.reducers";
// import { InactivityService } from "src/app/auth/inactivity/inactivity.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styles: [],
})
export class HomeComponent implements OnInit {
  empresa: string = "";
  linea2: string = "";

  pageSettings;
  pageHasScroll;
  currentUser: User;
  ppSubs: Subscription;
  isLoadParametros: boolean = false;
  inactivitySubscription: Subscription;

  constructor(
    private _serviceEmpresa: EmpresaService,
    private _serviceAuth: AuthService,
    // private inactivityService: InactivityService,
    private router: Router,
    private store: Store<AppState>
  ) {
    this._serviceAuth.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
  }

  ngOnInit() {
    this.pageSettings = pageSettings;
    this._serviceEmpresa.get_listar("1", "10").subscribe((resp: any) => {
      this.empresa = resp.data[0].razon_social;
      this.linea2 = resp.data[0].razon_social2;
      this._serviceAuth.empresa$.emit(this.empresa);
      this._serviceAuth.empresa2$.emit(this.linea2);
    });

    /* this.inactivitySubscription = this.inactivityService.onInactivity.subscribe(
      () => {
        this._serviceAuth.logout();
        this.router.navigate(["/login"]);
      }
    ); */

    /* this.store.dispatch(cargarParametroAnioActual({ id: "2024" }));
    this.ppSubs = this.store
      .select("planillaparametro")
      .subscribe(({ planillaparametro, loading }) => {
        this.isLoadParametros = loading;
      }); */
  }

  ngOnDestroy() {
    /* if (this.inactivitySubscription) {
      this.inactivitySubscription.unsubscribe();
    } */
  }

  onToggleSidebarRight(val: boolean): void {}

  onToggleMobileSidebar(val: boolean): void {}

  onToggleMobileRightSidebar(val: boolean): void {}

  onToggleSidebarMinified(val: boolean): void {}

  onHideMobileSidebar(val: boolean): void {}

  onHideMobileRightSidebar(val: boolean): void {}

  logOut(msg: string) {
    this._serviceAuth.logout();
    this.router.navigate(["/login"]);
  }
}
