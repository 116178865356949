import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { RutaDespachoLugar } from "src/app/models/rutasdespachos-lugares.model";
import { AppState } from "../../../app.reducers";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { cargarRutasDespachosLugares } from "../rutasdespachos-lugares/store/actions";
import { FormBuilder, FormGroup } from "@angular/forms";
import pageSettings from "src/app/config/page-settings";
import { RutasDespachosLugaresNewEditComponent } from "./new-edit/new-edit.component";
import { RutasDespachosLugaresService } from "./services/rutasdespachos-lugares.service";
import Swal from "sweetalert2";
import { LugaresControlService } from "../lugares-control/services/lugares-control.service";

@Component({
  selector: "app-rutasdespachos-lugares",
  templateUrl: "./rutasdespachos-lugares.component.html",
  styleUrls: ["./rutasdespachos-lugares.component.css"],
})
export class RutasDespachosLugaresComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;
  heightSection: number = 475;
  pageSettings = pageSettings;
  buscando: boolean = false;

  arrayModel: RutaDespachoLugar[] = [];
  loading: boolean = false;
  error: any;
  ppSubs: Subscription;
  frmBuscar: FormGroup;
  _rutadespacholugar_id: number;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _serviceModel: RutasDespachosLugaresService,
    private cdRef: ChangeDetectorRef,
    private _serviceLugaresControl: LugaresControlService
  ) {}

  ngOnInit() {
    this.frmBuscar = this.fb.group({
      _txtBuscar: [""],
    });
    this.blockUI.start("Consultando datos. Espere un momento...");
    this.listar();
  }

  ngOnDestroy(): void {
    this.ppSubs.unsubscribe();
  }

  receiveMessage($event: any) {
    if (this.frmBuscar.controls._txtBuscar.value === "") {
      this.buscando = false;
    }

    if (!this.buscando) {
      // this.getLista($event.pagina, $event.recxpag);
    }

    if (this.buscando) {
      const value = this.frmBuscar.controls._txtBuscar.value;
      // this.getBuscar($event.pagina, $event.recxpag, value);
    }
  }

  listar() {
    this.store.dispatch(cargarRutasDespachosLugares());
    this.ppSubs = this.store
      .select("rutasdespachoslugares")
      .subscribe(({ items, loading }) => {
        this.arrayModel = items;
        this.loading = loading;
        this.blockUI.stop();
      });
  }

  update(item: any) {
    console.log(item);
  }

  new(model: RutaDespachoLugar) {
    const ref = this.modalService.open(RutasDespachosLugaresNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceModel.create(data.frm).subscribe((resp: any) => {
            console.log(resp);
            if (resp.status === "ok") {
              this.listar();
            } else {
              Swal.fire("Atención", resp.msg, "error");
            }
          });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  Buscar() {}

  getLugares(row: any) {
    this._serviceLugaresControl
      .getLugaresbyRuta(row.rutadespacho_id)
      .subscribe((resp) => {
        this.arrayModel = resp["data"];
        console.log(resp["data"]);
      });
  }

  addLugar(model: any) {
    console.log("Agregar lugar: ");
    const ref = this.modalService.open(RutasDespachosLugaresNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceModel.create(data.frm).subscribe((resp: any) => {
            console.log(resp);
            if (resp.status === "ok") {
              this.listar();
            } else {
              Swal.fire("Atención", resp.msg, "error");
            }
          });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  edit(model: RutaDespachoLugar) {
    const ref = this.modalService.open(RutasDespachosLugaresNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._rutadespacholugar_id = model.rutadespacho_id;
    ref.result.then(
      (data) => {
        console.log(data.frm);
        if (data.valido) {
          this._serviceModel
            .update(model.rutadespacho_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.listar();
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  delete(model: any) {
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: "<p><b>Ruta:</b> " + model.nombre + "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceModel
          .getdelete(model.rutadespacho_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.listar();
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
