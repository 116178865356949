import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { PaginadorxService } from "../paginadorx/paginadorx.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-paginadorx",
  templateUrl: "./paginadorx.component.html",
  styleUrls: ["./paginadorx.component.css"],
})
export class PaginadorxComponent implements OnInit {
  registros: number = 0;
  page: number = 0;
  recxpag: number = 10;
  componente: string = "";
  pageSizeOptions: number[] = [10, 25, 100];
  ppSubs: Subscription;
  pcSubs: Subscription;

  message: string = "Hello!";

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private _servicePaginadorx: PaginadorxService) {}

  ngOnInit(): void {
    this._servicePaginadorx.pagex$.subscribe((resp: any) => {
      this.page = resp;
    });

    this._servicePaginadorx.registrosx$.subscribe((resp: any) => {
      this.registros = resp;
    });
  }

  loadPage(e: any) {
    this.messageEvent.emit({ pagina: e, recxpag: this.recxpag });
  }

  changeRegxPagina(pagina: number) {
    this.messageEvent.emit({ pagina: 1, recxpag: this.recxpag });
  }
  sendMessage() {}
}
