import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as planillaparametrosActions from "../../store/actions/planilla-parametros.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PlanillaParametrosService } from "../../../../pages/planilla-parametros/services/planilla-parametros.service";
import { of } from "rxjs";

@Injectable()
export class PlanillaParametrosEffects {
  constructor(
    private actions$: Actions,
    private planillaparametrosService: PlanillaParametrosService
  ) {}

  cargarPlanillaParametros$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaparametrosActions.cargarPlanillaParametros),
      mergeMap(() =>
        this.planillaparametrosService.getListar(1, 10).pipe(
          map((resp) =>
            planillaparametrosActions.cargarPlanillaParametrosSuccess({
              planillaparametros: resp["data"],
              registros: resp["registros"],
              pagecount: resp["pageCount"],
            })
          ),
          catchError((err) =>
            of(
              planillaparametrosActions.cargarPlanillaParametrosError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );

  cargarPlanillaParametrosByQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planillaparametrosActions.cargarPlanillaParametrosByQuery),
      mergeMap((action) =>
        this.planillaparametrosService
          .get_buscar(action.pagina, action.limit, action.query)
          .pipe(
            map((resp) =>
              planillaparametrosActions.cargarPlanillaParametrosByQuerySuccess({
                planillaparametros: resp["data"],
                registros: resp["registros"],
                pagecount: resp["pageCount"],
              })
            ),
            catchError((err) =>
              of(
                planillaparametrosActions.cargarPlanillaParametrosByQueryError({
                  payload: err,
                })
              )
            )
          )
      )
    )
  );
}
