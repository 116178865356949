import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import { PlanillaSancion } from "src/app/models/planillas-sanciones.model";
import { PlanillasSancionesService } from "../services/planillas_sanciones.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PlanillaSancionNewEditComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  blockTemplate!: BlockTemplateComponent;

  frm: FormGroup;
  loading: boolean = false;
  _userSession: any = "0";
  isEdit: boolean = false;
  _model: PlanillaSancion;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private _serviceModel: PlanillasSancionesService
  ) {}

  ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
    this.frm = this.fb.group({
      planillasancion_id: [0],
      planilla_id: [this._model.planilla_id],
      sancion_id: [0, Validators.required],
    });

    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.planillasancion_id;
      this.setForm(id);
    }
  }

  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceModel.getById(id.toString()).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp;
      this.frm = this.fb.group({
        planillasancion_id: [this._model.planillasancion_id],
        planilla_id: [this._model.planilla_id],
        sancion_id: [this._model.sancion_id],
        _valor_sancion: [this._model.valor_sancion, Validators.required],
        _sancion_descripcion: [
          this._model.sancion_descripcion,
          Validators.required,
        ],
      });
      this.blockUI.stop();
    });
  }
}
