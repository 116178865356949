import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ReportesService } from "../../../services/reportes.service";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
@Component({
  selector: "app-rpt-recaudos",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./rpt-recaudos.component.html",
  styles: [],
})
export class RptRecaudosComponent implements OnInit {
  data_rpt = [];
  tituloReporte = "RELACION DE PRODUCIDOS";
  fileName = "";
  FechaDesde = "";
  FechaHasta = "";
  Empresa = "";
  loading: boolean = false;
  _mes = "";
  _bus = "";

  constructor(
    private _serviceReportes: ReportesService,
    private datePipe: DatePipe
  ) {
    this._serviceReportes.EventDatosRecaudos$.subscribe(async (resp: any) => {
      this.data_rpt = resp;
      let t_producido = 0;
      let t_ahorro = 0;
      let t_cooperativa = 0;
      let t_planilla = 0;
      let t_acpm = 0;
      let t_conductor = 0;
      this.data_rpt.forEach((item) => {
        // let parsedDate = moment(item.fecha_recaudo, "YYYY-MM-DD");
        // let outputDate = parsedDate.format("YYYY-MM-DD");
        // item.fecha_recaudo = parseInt(outputDate);
        item.numero = parseInt(item.numero);
        item.producido = parseFloat(item.producido);
        item.ahorro = parseFloat(item.ahorro);
        item.cooperativa = parseFloat(item.cooperativa);
        item.planilla = parseFloat(item.planilla);
        item.acpm = parseFloat(item.acpm);
        item.pago_conductor = parseFloat(item.pago_conductor);

        t_producido += parseFloat(item.producido);
        t_ahorro += parseFloat(item.ahorro);
        t_cooperativa += parseFloat(item.cooperativa);
        t_planilla += parseFloat(item.planilla);
        t_acpm += parseFloat(item.acpm);
        t_conductor += parseFloat(item.pago_conductor);
      });
    });

    this._serviceReportes.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportes.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });

    this._serviceReportes.mes$.subscribe((resp: any) => {
      this._mes = resp;
    });

    this._serviceReportes.bus$.subscribe((resp: any) => {
      this._bus = resp;
    });
  }

  ngOnInit() {}

  exportAsXLSX(): void {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `PRODUCIDOS_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY");

    this._serviceReportes.downloadFile_Producidos(
      this.data_rpt,
      filename,
      periodo
    );

    /* const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let titulos = {
      A: "Dia",
      B: "Ruta",
      C: "Bus",
      D: "Propietario",
      E: "Producido",
      F: "Ahorro",
      G: "Cooperativa",
      H: "Planilla",
      I: "ACPM",
      J: "Pago Conductor",
      K: "Observación",
    };

    if (this.data_rpt.length === 0) {
      Swal.fire("Advertencia", "No hay datos para exportar...", "warning");
    } else {
      let itemsDisplay: any[] = [];
      itemsDisplay.push(titulos);
      this.data_rpt.map((c) => {
        itemsDisplay.push({
          A: this.datePipe.transform(c.fecha_recaudo, "dd/MM/yyyy"),
          B: c.ruta_nombre,
          C: c.numero,
          D: c.nombres,
          E: parseFloat(c.producido),
          F: parseFloat(c.ahorro),
          G: parseFloat(c.cooperativa),
          H: parseFloat(c.planilla),
          I: parseFloat(c.acpm),
          J: parseFloat(c.pago_conductor),
          K: c.observacion,
        });
      });

      let Heading = [["COLECTIVOS CIUDAD DE IPIALES"]];
      let subtitulo = [["RELACION DE PRODUCIDOS"]];
      let fecha = [{ a: "MES:", b: strDate }];

      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, Heading);

      XLSX.utils.sheet_add_json(ws, subtitulo, {
        origin: "A2",
        skipHeader: true,
      });

      XLSX.utils.sheet_add_json(ws, fecha, {
        origin: "A3",
        skipHeader: true,
      });
      XLSX.utils.sheet_add_json(ws, itemsDisplay, {
        origin: "A5",
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(wb, ws, "Producidos");
      this.fileName = `Producidos${this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd"
      )}.xlsx`;
      XLSX.writeFile(wb, this.fileName);
    } */
  }

  get_Total_Producido() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.producido);
    });
    return total;
  }

  get_Total_Ahorro() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.ahorro);
    });
    return total;
  }

  get_Total_Cooperativa() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.cooperativa);
    });
    return total;
  }

  get_Total_Planilla() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.planilla);
    });
    return total;
  }

  get_Total_ACPM() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.acpm);
    });
    return total;
  }

  get_Total_PagoConductor() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.pago_conductor);
    });
    return total;
  }
}
