import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  UrlSegment,
  UrlTree,
  CanLoad,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { map } from "rxjs/operators";
import { MensajesToastrService } from "../mensajes/toastr-service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccessDeniedComponent } from "../components/access-denied/access-denied.component";
declare const $: any;

@Injectable()
export class AuthorizatedGuard implements CanActivate, CanLoad {
  constructor(
    private _serviceAuth: AuthService,
    private router: Router,
    private _serviceToastr: MensajesToastrService,
    private modalService: NgbModal
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRoles = route.data.role;
    if (!this._serviceAuth.isLoggedIn()) {
      this.router.navigate(["/login"]);
      return false;
    }
    const session_roles = sessionStorage.getItem("roles");
    let arrayRoles = JSON.parse(session_roles);
    let list_roles = [];
    arrayRoles.map((r) => {
      list_roles.push(r.role_name);
    });

    if (!list_roles.some((r) => expectedRoles.includes(r))) {
      console.log("x.x.x.x.x.x.x.");
      this.access_denied();
      return false;
    }
    return true;
  }

  /* canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  } */

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }

  canLoad(): boolean {
    if (!this._serviceAuth.isLoggedIn()) {
      this.router.navigate(["/login"]);
      return false;
    }
    return true;
  }

  access_denied() {
    const ref = this.modalService.open(AccessDeniedComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.result.then(
      (data) => {},
      (cancel) => {}
    );
  }
}
