import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductosService } from "../../services/productos.service";
import { Producto } from "../../models/productos.model";

@Component({
  selector: "app-productos-new",
  templateUrl: "./productos-new.component.html",
  styles: [
    `
      input.form_control {
        margin-top: 4px;
      }
    `,
  ],
})
export class ProductosNewComponent implements OnInit {
  isLoading: boolean = false;
  frm: FormGroup;
  _model: Producto;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceProductos: ProductosService
  ) {}

  ngOnInit() {
    this.frm = this.fb.group({
      producto_id: [0],
      nombre: ["", Validators.required],
      precio_venta: ["", [Validators.required]],
    });

    if (this.isEdit) {
      const id = this._model.producto_id;
      this.setForm(id);
    }
  }
  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceProductos.getbyid(id.toString()).subscribe((resp: any) => {
      this._model = resp.data[0];
      this.frm = this.fb.group({
        producto_id: [this._model.producto_id],
        nombre: [this._model.nombre, Validators.required],
        precio_venta: [this._model.precio_venta, Validators.required],
      });
    });
  }

  change(value: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls["precio_venta"].setValue(value);
    }
  }
}
