import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";
import { Empresa } from "../models/empresa.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EmpresaService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  private url = URL_API;

  constructor(private http: HttpClient) {}

  get_listar(pagina: string, limit: string) {
    const url = URL_API + "/empresa/get_listar/" + pagina + "/" + limit;
    return this.http.get(url, this.httpOptions);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url =
      URL_API + "/empresa/get_buscar/" + pagina + "/" + limit + "/" + buscar;
    return this.http.get(url, this.httpOptions);
  }

  create(model: any) {
    const url = URL_API + "/empresa/nuevo";
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };

    const url = URL_API + "/empresa/get_byid/" + id;
    return this.http.get(url, this.httpOptions);
  }

  getById(id: string) {
    return this.http
      .get(`${this.url}/empresa/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  update(id: string, model: Empresa) {
    const url = URL_API + "/empresa/update/" + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete(id: string) {
    const url = URL_API + "/empresa/delete/" + id;
    return this.http.delete(url, this.httpOptions);
  }

  get_https() {
    const url = "https://151.106.109.234:5400/test";
    return this.http.get(url);
  }
}
