import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";

import { PlanillasService } from "../despachos/pages/planillas/services/planillas.service";
import { map } from "rxjs/operators";
/* export function matchValidator(
  matchTo: string,
  reverse?: boolean
): ValidatorFn {
  console.log(matchTo);
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent && reverse) {
      const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
      if (c) {
        c.updateValueAndValidity();
      }
      return null;
    }
    return !!control.parent &&
      !!control.parent.value &&
      control.value === (control.parent?.controls as any)[matchTo].value
      ? null
      : { matching: true };
  };
} */

export function ConfirmedValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];

    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function greaterThanZeroValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value !== null && value <= 0) {
      return { greaterThanZero: true };
    }
    return null;
  };
}

export function checkConsecPlanillaValidator(api: any): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return api.get_consecutivoexiste(control.value).pipe(
      map((result: any) => {
        return result.count > 0 ? { consecExists: true } : null;
      })
    );
  };
}

export function ultimaLlegadaPlanillaValidator(api: any): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return api.get_ultima_Llegada(control.value).pipe(
      map((result: any) => {
        return result.count > 0 ? { llegadaExists: true } : null;
      })
    );
  };
}

export function FechaHoraSalidaValidator(api: any): AsyncValidatorFn {
  return (control: AbstractControl) => {
    console.log("control.value", control.value);
    return api.get_fecha_hora_salida(control.value).pipe(
      map((result: any) => {
        return result.count > 0 ? { fechahoraExists: true } : null;
      })
    );
  };
}
