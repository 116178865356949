import { createReducer, on } from "@ngrx/store";
import {
  cargarRutasDespachos,
  cargarRutasDespachosByQuery,
  cargarRutasDespachosByQueryError,
  cargarRutasDespachosByQuerySuccess,
  cargarRutasDespachosError,
  cargarRutasDespachosSuccess,
} from "../actions";
import { RutaDespacho } from "../../../../../models/rutas-despachos.model";

export interface RutasDespachosState {
  items: RutaDespacho[];
  loaded: boolean;
  loading: boolean;
  error: any;
  registros: number;
  pagecount: number;
}

export const rutasdespachosInitialState: RutasDespachosState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  registros: 0,
  pagecount: 0,
};

const _rutasdespachosReducer = createReducer(
  rutasdespachosInitialState,

  on(cargarRutasDespachos, (state) => ({ ...state, loading: true })),

  on(
    cargarRutasDespachosSuccess,
    (state, { rutasdespachos, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...rutasdespachos],
      registros: registros,
      pagecount: pagecount,
    })
  ),

  on(cargarRutasDespachosError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),

  on(cargarRutasDespachosByQuery, (state) => ({ ...state, loading: true })),
  on(
    cargarRutasDespachosByQuerySuccess,
    (state, { rutasdespachos, registros, pagecount }) => ({
      ...state,
      loading: false,
      loaded: true,
      items: [...rutasdespachos],
      registros: registros,
      pagecount: pagecount,
    })
  ),
  on(cargarRutasDespachosByQueryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

export function rutasdespachosReducer(state, action) {
  return _rutasdespachosReducer(state, action);
}
