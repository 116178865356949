import { createAction, props } from "@ngrx/store";
import { RutaDespachoLugar } from "../../../../../models/rutasdespachos-lugares.model";

export const cargarRutasDespachosLugares = createAction(
  "[Rutas Despachos Lugares] Cargar Rutas Lugares"
);

export const cargarRutasDespachosLugaresSuccess = createAction(
  "[Rutas Despachos Lugares] Cargar Rutas Lugares Success",
  props<{
    rutasdespachoslugares: RutaDespachoLugar[];
    registros: number;
    pagecount: number;
  }>()
);

export const cargarRutasDespachosLugaresError = createAction(
  "[Rutas Despachos Lugares] Cargar Rutas Lugares Error",
  props<{ payload: any }>()
);
